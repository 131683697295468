import Typography from "@mui/material/Typography";
import { makeStyles, createStyles } from "@mui/styles";
import { Container, Grid, Paper, SxProps } from "@mui/material";
import theme from "../../components/theme/theme";
import {
  NumericParameterStyle,
  Parameter,
  ParameterGroup,
} from "../../common/types/parameters";
import { Column, Condition } from "./types";
import ParameterInput from "../../components/parameters/parameter-input";
import Table from "../../components/data-presentation/table";
import MultiParameterInput, {
  canHaveCommonSelectionInput,
} from "../../components/parameters/multi-parameter-input";
import { ReactNode } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: "15px",
    },
  })
);
const paperElevation = 3;

export default function ParameterSection(params: {
  description?: string;
  printTitle?: string;
  parameters: Parameter[];
  columns: Column[];
  includeVat: boolean;
  toPrint?: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
  children?: ReactNode;
}) {
  const classes = useStyles(theme);

  return (
    <Container
      className={classes.card}
      elevation={paperElevation}
      sx={{
        marginBottom: "40px",
        minHeight: "400px",
        paddingLeft: 4,
        paddingRight: 4,
        ...params.sx,
      }}
      component={params.toPrint ? "div" : Paper}>
      <Grid container columnSpacing={6} rowSpacing={1}>
        {params.printTitle && params.toPrint && (
          <Grid item xs={12}>
            <h2>{params.printTitle}</h2>
          </Grid>
        )}
        {params.description && (
          <Grid item xs={12}>
            <Typography
              paragraph
              marginTop={1}
              dangerouslySetInnerHTML={{ __html: params.description }}
            />
          </Grid>
        )}
        {params.columns.map((column, index) => (
          <Grid key={index} item xs={12 / params.columns.length}>
            {column.sections.map((section, index) => {
              let parametersToDisplay = filterParameters(
                section.parameters,
                params.parameters,
                section.conditions
              );
              return parametersToDisplay.length > 0 ? (
                <div key={index} className="parameter-section">
                  <Typography variant="h3" marginTop={4}>
                    {section.title}
                  </Typography>

                  {selectRepresentation(
                    parametersToDisplay,
                    params.includeVat,
                    !!column.readOnly,
                    params.toPrint,
                    params.onValidParameterChange
                  )}
                </div>
              ) : (
                <></>
              );
            })}
          </Grid>
        ))}
      </Grid>
      {params.children && params.children}
    </Container>
  );
}

function filterParameters(
  idsToInclude: string[],
  parameters: Parameter[],
  conditions: Condition[] | undefined
): Parameter[] {
  let paramtersToInclude: Parameter[] = [];
  if (
    !conditions ||
    conditions.every((condition) => isMet(condition, parameters))
  ) {
    idsToInclude.forEach((id) => {
      let paramter = parameters.find((x) => x.id === id);
      if (paramter) {
        paramtersToInclude.push(paramter);
      }
    });
  }
  return paramtersToInclude;
}

function isMet(condition: Condition, parameters: Parameter[]) {
  let parameter = parameters.find((p) => p.id === condition.parameter);
  return (
    parameter !== undefined &&
    (condition.value === undefined ||
      parameter.value === condition.value ||
      parameter.value === JSON.stringify(condition.value))
  );
}

function selectRepresentation(
  sectionParameters: Parameter[],
  includeVat: boolean,
  readOnly: boolean,
  toPrint?: boolean,
  onValidParameterChange?: (id: string, value: string) => void
): JSX.Element[] {
  if (
    sectionParameters.length > 0 &&
    (readOnly || sectionParameters.every((parameter) => !parameter.editable)) &&
    sectionParameters
      .map(
        (parameter) => (parameter.stylingConfig as NumericParameterStyle)?.unit
      )
      .every((unit, index, units) => unit === units[0])
  ) {
    return [
      <Table
        parameterGroups={[
          {
            displayName: (
              sectionParameters[0].stylingConfig as NumericParameterStyle
            ).unit,
            parameters: sectionParameters,
          } as ParameterGroup,
        ]}
        summaryRow={sectionParameters.length > 1 ? "Summa" : undefined}
        sx={{ marginBottom: 2 }}
        includeVat={includeVat}
      />,
    ];
  } else if (canHaveCommonSelectionInput(sectionParameters)) {
    return [
      <MultiParameterInput
        parameters={sectionParameters}
        disabled={readOnly}
        onValidChange={onValidParameterChange}
      />,
    ];
  } else {
    return sectionParameters.map((parameter) => (
      <div
        key={parameter.id}
        style={toPrint ? { paddingTop: "1em" } : { color: "black" }}>
        <ParameterInput
          parameter={parameter}
          disabled={readOnly}
          onValidChange={onValidParameterChange}
          includeVat={includeVat}
        />
      </div>
    ));
  }
}
