import { Switch, Tooltip, IconButton, FormControlLabel } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function ToggleSwitchInput(params: {
  inputId: string;
  defaultValue: boolean | string;
  title: string;
  description?: string;
  readonly?: boolean;
  onChange?: (id: string, val: string) => void;
}) {
  return (
    <div key={`toggle-${params.inputId}-container`}>
      <FormControlLabel
        control={
          <Switch
            id={params.inputId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (params.onChange !== undefined) {
                params.onChange(
                  params.inputId,
                  JSON.stringify(event.target.checked)
                );
              }
            }}
            color="primary"
          />
        }
        checked={params.defaultValue === "true" || params.defaultValue === true}
        label={params.title}
        labelPlacement="end"
        disabled={params.readonly}
      />

      {params.description && (
        <Tooltip arrow placement="bottom" title={params.description}>
          <IconButton size="small" color="primary" sx={{ margin: "16px 10px" }}>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
