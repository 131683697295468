import NumericInputField from "./numeric-field";
import {
  Alternatives,
  NumericParameterStyle,
  NumericRange,
  Parameter,
  ParameterType,
} from "../../common/types/parameters";
import { getType } from "../data-presentation/util";
import { Typography } from "@mui/material";
import ToggleSwitchInput from "./toggle-switch";
import Dropdown from "./dropdown";

export default function ParameterInput(params: {
  parameter: Parameter;
  disabled: boolean;
  onValidChange?: (id: string, value: string) => void;
  onErroneousChange?: (
    id: string,
    value: string,
    nearestValidVal: string
  ) => void;
  includeVat: boolean;
}) {
  return getType(params.parameter) === ParameterType.Numeric ? (
    <NumericInputField
      inputId={params.parameter.id}
      min={(params.parameter.constraints as NumericRange)?.min}
      max={(params.parameter.constraints as NumericRange)?.max}
      defaultValue={params.parameter.value}
      title={getTitle(params.parameter)}
      description={params.parameter.description}
      disabled={params.disabled || !params.parameter.editable}
      onValidChange={params.onValidChange}
      onErroneousChange={params.onErroneousChange}
      numDecimals={
        (params.parameter.stylingConfig as NumericParameterStyle)?.numDecimals
      }
      thousandsSeparator={
        (params.parameter.stylingConfig as NumericParameterStyle)
          ?.thousandsSeparator
      }
      decimalSeparator={
        (params.parameter.stylingConfig as NumericParameterStyle)
          ?.decimalSeparator
      }
      unit={(params.parameter.stylingConfig as NumericParameterStyle)?.unit}
      includeVat={params.includeVat}
    />
  ) : getType(params.parameter) === ParameterType.YesNo ? (
    <ToggleSwitchInput
      inputId={params.parameter.id}
      defaultValue={params.parameter.value}
      title={getTitle(params.parameter)}
      description={params.parameter.description}
      onChange={params.onValidChange}
    />
  ) : getType(params.parameter) === ParameterType.Selection ? (
    <Dropdown
      inputId={params.parameter.id}
      defaultValue={params.parameter.value}
      alternatives={params.parameter.constraints as Alternatives}
      title={params.parameter.displayName}
      description={params.parameter.description}
      disabled={!params.parameter.editable}
      onValidChange={params.onValidChange}
      numDecimals={
        (params.parameter.stylingConfig as NumericParameterStyle)?.numDecimals
      }
      thousandsSeparator={
        (params.parameter.stylingConfig as NumericParameterStyle)
          ?.thousandsSeparator
      }
      decimalSeparator={
        (params.parameter.stylingConfig as NumericParameterStyle)
          ?.decimalSeparator
      }
      unit={(params.parameter.stylingConfig as NumericParameterStyle)?.unit}
      includeVat={params.includeVat}
    />
  ) : (
    /* If format is not supported, display as text */
    <Typography paragraph>
      {getTitle(params.parameter)}: {JSON.stringify(params.parameter.value)}
    </Typography>
  );
}

function getTitle(parameter: Parameter): string {
  let unit = (parameter.stylingConfig as NumericParameterStyle)?.unit;

  if (unit !== undefined && unit !== null) {
    return `${parameter.displayName} (${unit})`;
  } else {
    return parameter.displayName;
  }
}
