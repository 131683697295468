import { Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../common/store/store";
import { getStepStates } from "../../../common/store/wizard-slice";
import {
  getRawUserInputForStepId,
  parseJsonToObject,
} from "../../../components/wizard/util";
import theme from "../../../components/theme/theme";
import { makeStyles, createStyles } from "@mui/styles";

import "../../../assets/angular-components/klimatmodulen";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: "15px",
    },
  })
);

export default function GridClimateInfoAngular() {
  const classes = useStyles(theme);

  let { customer } = useParams();
  let { economicProfile } = useParams();

  let stepStates = useAppSelector((state) =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  let district = parseJsonToObject(
    getRawUserInputForStepId("DistrictSelected", stepStates)
  )?.value;
  let building = parseJsonToObject(
    getRawUserInputForStepId("BuildingProfile", stepStates)
  )?.value;

  return (
    <div>
      <Typography variant="h1">Elsystemet</Typography>
      <Paper className={classes.card} elevation={3} sx={{ mb: 3 }}>
        <Typography paragraph>
          Elsystemet är ett sammankopplat system där ett ökat eller minskat
          behov av el på aktuell ort kan påverka elkraftsanläggningar i hela
          nordeuropa. Första diagrammet nedan visar hur elproduktionen i det
          nordeuropeiska elsystemet ser ut under ett helt år.
        </Typography>
        <Typography paragraph>
          Vid ett ökat elbehov, exempelvis för värmepumpar som
          uppvärmningsalternativ, beräknar klimatmodulen samtliga
          utsläppskonsekvenser som uppstår i elsystemet baserat på hur
          elsystemet kan se ut under uppvärmningsalternativets livslängd, dvs de
          kommande 20 åren. Läs mer om detta under "Om beräkningen". Vilka
          elkraftsanläggningar och därmed vilka bränslen som påverkas i
          elsystemet när elbehovet ökar framgår av det andra diagrammet nedan.
        </Typography>
      </Paper>
      <electrical-system-component
        customer={customer}
        district={district}
        building={building}
        economic={economicProfile}
      />
    </div>
  );
}
