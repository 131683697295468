import { httpClient } from "./http-client";
import { Parameter } from "../common/types/parameters";

export const getLabel = async (
  label: string,
  customer: string | undefined,
  district: string | undefined,
  buildingType: string | undefined,
  economicProfile: string | undefined
): Promise<Parameter | undefined> => {
  const response = await httpClient
    .get(
      `customers/${customer}/economic-profile/${economicProfile}/districts/${district}/buildings/${buildingType}/labels/${label}`
    )
    .catch(() => {
      /* No handling as price info text is not mandatory */
    });
  return response?.data;
};

export const getLabelsByCustomerId = async (
  customer: string | undefined,
  economicProfile: string | undefined
): Promise<Parameter | undefined> => {
  const response = await httpClient
    .get(`customers/${customer}/labels`)
    .catch(() => {
      /* No handling as info text is not mandatory */
    });
  return response?.data;
};

export const getLabelByCustomerId = async (
  label: string,
  customer: string | undefined,
  economicProfile: string | undefined
): Promise<Parameter | undefined> => {
  const response = await httpClient
    .get(`customers/${customer}/labels/${label}`)
    .catch(() => {
      /* No handling as info text is not mandatory */
    });
  return response?.data;
};
