import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParameterValue } from '../types/parameters';
import { RootState } from './store';

interface ModuleParameterState {
  meta: Profile;
  parameters: ParameterValue[];
}

export interface Profile {
  customer?: string;
  economicProfile?: string;
}

function loadStoredState(): ModuleParameterState {
  let storedValue = sessionStorage.getItem('moduleParameterState');
  if (storedValue !== null) {
    return JSON.parse(storedValue as string) as ModuleParameterState;
  } else {
    return { meta: {}, parameters: [] };
  }
}

function getOrCreateStepState(
  state: ModuleParameterState,
  id: string
): ParameterValue {
  var existingIndex = state.parameters.findIndex(
    parameter => parameter.id === id
  );

  if (existingIndex >= 0) {
    return state.parameters[existingIndex];
  } else {
    let parameterState = { id: id, value: '' } as ParameterValue;
    state.parameters.push(parameterState);
    return parameterState;
  }
}

function getParameterState(
  state: ModuleParameterState,
  id: string
): ParameterValue | undefined {
  return state.parameters.find(parameter => parameter.id === id);
}

function getStateForProfile(
  state: ModuleParameterState,
  meta: Profile
): ModuleParameterState {
  if (
    state.meta?.customer !== meta.customer ||
    state.meta?.economicProfile !== meta.economicProfile
  ) {
    var moduleState = { meta: meta, parameters: [] };
    sessionStorage.setItem('moduleParameterState', JSON.stringify(moduleState));
  }
  return state;
}

const initialState: ModuleParameterState = loadStoredState();

const moduleParameterSlice = createSlice({
  name: 'moduleParameters',
  initialState: initialState,
  reducers: {
    setUserInput: (
      state: ModuleParameterState,
      action: PayloadAction<{
        id: string;
        value: string;
        profile: Profile;
      }>
    ) => {
      let userInputState = getStateForProfile(state, action.payload.profile);
      let parameterState = getOrCreateStepState(
        userInputState,
        action.payload.id
      );
      parameterState.value = action.payload.value;
      sessionStorage.setItem(
        'moduleParameterState',
        JSON.stringify(userInputState)
      );
    },
    resetUserInputs: (
      state: ModuleParameterState,
      action: PayloadAction<{
        profile: Profile;
      }>
    ) => {
      let userInputState = getStateForProfile(state, action.payload.profile);
      userInputState.parameters = [];
      sessionStorage.setItem(
        'moduleParameterState',
        JSON.stringify(userInputState)
      );
    },
    resetUserInputsWithId: (
      state: ModuleParameterState,
      action: PayloadAction<{
        ids: string[];
        profile: Profile;
      }>
    ) => {
      let userInputState = getStateForProfile(state, action.payload.profile);
      userInputState.parameters = userInputState.parameters.filter(
        parameter => !action.payload.ids.includes(parameter.id)
      );
      sessionStorage.setItem(
        'moduleParameterState',
        JSON.stringify(userInputState)
      );
    },
  },
});

export const getModuleParameterInput = (
  state: RootState,
  id: string,
  profile: Profile
) => {
  let userInputState = getStateForProfile(state.moduleParameters, profile);
  return getParameterState(userInputState, id)?.value;
};
export const getParameterStates = (state: RootState, profile: Profile) => {
  let userInputState = getStateForProfile(state.moduleParameters, profile);
  return userInputState.parameters;
};
export const deviatesFromDefaults = (state: RootState, profile: Profile) => {
  return (
    getStateForProfile(state.moduleParameters, profile).parameters.length > 0
  );
};

export const { setUserInput, resetUserInputs, resetUserInputsWithId } =
  moduleParameterSlice.actions;

export default moduleParameterSlice.reducer;
