import { SxProps } from "@mui/material";
import "./hjalp.css";

import manual_steg_oversikt from "../../../assets/img/manual/manual_steg_oversikt.png";
import manual_oversiktliga_resultat_graf from "../../../assets/img/manual/manual_oversiktliga_resultat_graf.png";
import manual_detaljerad_kostnadskalkyl_graf from "../../../assets/img/manual/manual_detaljerad_kostnadskalkyl_graf.png";
import manual_ekomoniska_forutsattningar from "../../../assets/img/manual/manual_ekomoniska_forutsattningar.png";
import manual_fastighetsel from "../../../assets/img/manual/manual_fastighetsel.png";
import manual_fjarrvarme_indata from "../../../assets/img/manual/manual_fjarrvarme_indata.png";
import manual_fjarrvarme_resultat from "../../../assets/img/manual/manual_fjarrvarme_resultat.png";
import manual_bergvarmepump from "../../../assets/img/manual/manual_bergvarmepump.png";
import manual_bergvarme_och_luftvattenvarmepump_indata from "../../../assets/img/manual/manual_bergvarme_och_luftvattenvarmepump_indata.png";
import manual_bergvarme_och_luftvarmepump_resultat from "../../../assets/img/manual/manual_bergvarme_och_luftvarmepump_resultat.png";
import manual_pellets_indata from "../../../assets/img/manual/manual_pellets_indata.png";
import manual_pellets_resultat from "../../../assets/img/manual/manual_pellets_resultat.png";
import manual_livscykelmodul_indata1 from "../../../assets/img/manual/manual_livscykelmodul_indata1.png";
import manual_livscykelmodul_indata2 from "../../../assets/img/manual/manual_livscykelmodul_indata2.png";
import manual_livscykelmodul_indata3 from "../../../assets/img/manual/manual_livscykelmodul_indata3.png";
import manual_livscykelmodul_resultat from "../../../assets/img/manual/manual_livscykelmodul_resultat.png";
import manual_effektberakning_graf from "../../../assets/img/manual/manual_effektberakning_graf.png";

const expandableModule = (id: string) => (
  <div id={id} className="collapse indented-block">
    <p>Nedan bild illustrerar olika definitioner av effekt.</p>
    <img
      src={manual_effektberakning_graf}
      alt="Illustration av oika definitioner av effekt"
      className="float-center"
    />
    <p>
      Den gröna linjen illustrerar en timprofilen för värmebehovet (inklusive
      varmvatten) för ett flerbostadshus. Av figuren framgår att huset har sitt
      största effektbehov kring den 500:e timmen på året (januari). Notera att
      denna topp endast finns under mycket korta perioder, dvs enskilda timmar
      på året.
    </p>
    <p>
      Om man istället för det maximala effektbehovet tittar på det effektbehov
      som sker vid en temperatur som motsvarar ortens 3-dygns DVUT
      (dimensionerande vinterutetemperatur) så representeras denna nivå av det
      gula sträcket. Det är baserat på detta effektbehov som Fjärrkontrollen
      dimensionerar värmepumpar och pelletspannor. I praktiken innebär detta att
      man accepterar att effektbehovet inte kommer kunna täckas av
      uppvärmningssystemet under de få timmar på året när behovet är maximalt.
    </p>
    <p>
      Slutligen representerar den blåa linjen det effektbehov som används för
      debitering av fjärrvärmepriset för effekt. Denna kommer alltid vara lägre
      än den maximala (tim)effekten eftersom de allra flesta debiteringseffekter
      definieras baserat på en medeleffekt för ett helt dygn, så kallad
      dygnsmedeleffekt. I exemplet i bilden kan man se att debiteringseffekten
      för fjärrvärme finns på en nivå där (tim)effekten är hög under flera
      efterföljande timmar, exempelvis kring 500:e timmen.
    </p>
  </div>
);

const onExpand = (id: string) => {
  const elem = document.getElementById(id);
  if (elem) {
    if (elem.style.display === "block") {
      elem.style.display = "none";
    } else {
      elem.style.display = "block";
    }
  }
};

export default function Hjalp(params: { sx?: SxProps }) {
  return (
    <div id="main">
      <h1>Instruktioner för Fjärrkontrollen</h1>
      <div className="well card card-body">
        <p>
          Med Fjärrkontrollen kan man jämföra kostnaden för uppvärmning för ett
          hus med uppvärmningsformerna fjärrvärme, värmepumpar och pelletspanna.
          Med tillvalsmodulen Klimatmodul kan man även jämföra
          klimatgasutsläppen från dessa uppvärmningsformer.
        </p>
        <p>
          I Fjärrkontrollen kan man specificera husets förutsättningar vad
          gäller t.ex. energi- och effektbehov för att kunna få en så
          rättvisande kostnadsbild som möjligt. Kostnadskalkylen för fjärrvärme
          är framtagen i samarbete det lokala energibolaget för att bli korrekt.
          För övriga uppvärmningsalternativ är osäkerheterna större, då
          exempelvis investeringskostnaden och värmefaktorn för en bergvärmepump
          varierar från hus till hus beroende på förutsättningarna. Därför finns
          det möjlighet att justera parametrarna för dessa uppvärmningsformer så
          att man exempelvis kan lägga in värdena från en offert.
          Standardvärdena för alternativen representerar ett genomsnittsfall.
        </p>
      </div>
      <h2>Inledande förutsättningar</h2>
      <div className="well card card-body">
        <img
          src={manual_steg_oversikt}
          alt="Menyalternativ för fjärrkontrollen"
          className="float-right"
          style={{ width: "250px" }}
        />
        <p>
          Innan Fjärrkontrollen kan beräkna kostnaden för olika
          uppvärmningsalternativ behöver du ange några inledande
          förutsättningar.
        </p>
        <p>
          Under fliken ’Område’ ombeds du specificera vilket område kalkylen ska
          gälla för. Valet av område påverkar bland annat vilken prislista som
          används eftersom det kan finnas flera områden med olika prislistor för
          fjärrvärme och elnät. Om det endast finns ett område är detta
          alternativ förvalt.
        </p>
        <p>
          Under fliken ’Fjärrvärme idag’ ombeds du välja ifall du vill räkna med
          en investeringskostnad för fjärrvärme eller inte. Detta är användbart
          om man exempelvis redan har fjärrvärme och vill att resultaten endast
          visar en jämförelse utan investeringskostnader för fjärrvärmen.
        </p>
        <p>
          Under fliken ’Värmesystem’ ombeds du välja vilken typ av värmesystem
          huset har. Valet av värmesystem påverkar vilken prestanda som antas
          för värmepumparna där högre prestanda antas om golvvärme väljs medan
          lägre prestanda antas om radiatorer väljs. Radiatorer är vanligast i
          befintliga hus medan golvvärme kan väljas för att spegla nyare hus.
        </p>
        <p>
          Under fliken ’Värmebehov’ ombeds du ange vilket värmebehov ditt hus
          har, inklusive varmvatten. Ett mindre flerbostadshus med 15 lägenheter
          kan ha ett värmebehov på ca 200 MWh/år medan större flerbostadshus kan
          ha värmebehov på närmare 2000 MWh/år. En liten villa kan ha ett
          värmebehov på 10 MWh/år medan en större villa kan ha ett värmebehov
          närmare 50 MWh/år. Standardvärdet är i Fjärrkontrollen 193 MWh/år för
          flerbostadshus och 20 MWh/år för villa.
        </p>
      </div>
      <h2>Översiktliga resultat</h2>
      <div className="well card card-body">
        <img
          src={manual_oversiktliga_resultat_graf}
          alt="Grafisk översikt över resultat"
          className="float-right"
          style={{ width: "500px" }}
        />
        <p>
          Fjärrkontrollen visar översiktliga resultat för uppvärmningskostnader
          mellan olika alternativ baserat på de inledande förutsättningarna du
          specificerat.
        </p>
        <p>
          Resultaten visas som årliga uppvärmningskostnader där investeringar
          ingår som årliga avbetalningar. Översiktliga resultat kan ses som en
          snabbkalykl där rimliga antaganden görs avseende ekonomiska och
          tekniska parametrar.
        </p>
      </div>
      <h2>Detaljerad kostnadskalkyl</h2>
      <div className="well card card-body">
        <p>
          Den detaljerade kostnadskalkylen visar en jämförelse mellan samtliga
          uppvärmningsalternativ fördelat på olika delkostnader. Diagrammet
          visar resultaten givet standardförutsättningar som finns i
          Fjärrkontrollen men i flikarna under diagrammet kan man justera olika
          tekniska och ekonomiska förutsättningar.
        </p>
        <p>
          Man kan i diagrammet ’tända/släcka’ olika staplar genom att klicka på
          posterna under diagrammet för att visuellt jämföra olika delkostnader.
          Exempelvis kan man ’släcka’ alla poster förutom ’Energikostnad’ för
          att jämföra kostnaden för just energi mellan alternativen.
        </p>
        <img
          src={manual_detaljerad_kostnadskalkyl_graf}
          alt="Jämförelsegraf mellan samtliga uppvärmingsalternativ"
          className="float-center"
        />
      </div>
      <h3>Ekonomiska förutsättningar</h3>
      <div className="well card card-body">
        <img
          src={manual_ekomoniska_forutsattningar}
          alt="Fält för inmatning av kalkylränta och elhandelspris"
          className="float-right"
          style={{ width: "500px" }}
        />
        <p>
          Under ekonomiska förutsättningar kan man justera kalkylränta, elpris
          samt ifall beräkningen ska visa kostnader med eller utan moms.
        </p>
        <p>
          Kalkylräntan kan justeras för att utvärdera vilken påverkan den har på
          avbetalning av investeringskostnaderna. En högre kalkylränta speglar
          exempelvis högre låneränta, högre risker med investeringen eller högre
          förväntningar på alternativ avkastning. En alternativ placering kan
          exempelvis vara börsen, man vill eventuellt justera kalkylräntan för
          att matcha eller överträffa börsens förväntade avkastning för veta hur
          en investering i ett uppvärmningsalternativ står sig mot att istället
          placera investeringen på börsen. En lägre kalkylränta speglar det
          motsatta. Fjärrkontrollen utgår från vad som kan anses vara en rimlig
          kalkylränta, med olika antagande beroende på om kalkylen avser en
          villa eller ett flerbostadshus.
        </p>
        <p>
          Även elhandelspriset kan justeras vilket påverkar kostnaderna för
          värmepumpsalternativen. Det elpris som Fjärrkontrollen utgår från
          speglar ett troligt medelpris närmaste 15-20 åren och kan jämföras mot
          terminpriser som anges för långa terminskontrakt. Justering av
          elpriset kan användas för bland annat känslighetsanalyser.
        </p>
      </div>
      <h3>Fastighetsel</h3>
      <div className="well card card-body">
        <p>
          Under Fastighetsel kan man justera husets elparametrar innan
          installation av värmepump. Normalt påverkas elnätskostnaden av
          installationen och hur stor den påverkan blir beror av husets
          förutsättningar innan installation.
        </p>
        <p>
          Exempelvis kan man få betala högre fasta elnätskostnader eftersom man
          behöver en högre säkring efter installation av en värmepump.
          Fjärrkontrollen beräknar elparametrarna (elförbrukning, eleffektbehov
          samt säkringsstorlek) baserat på det värmebehov som anges givet ett
          ’typiskt’ hus. Dessa parametrar kan därefter justeras för att bättre
          spegla aktuella förutsättningar.
        </p>
        <img
          src={manual_fastighetsel}
          alt="Elparametrar och elnätskostader"
          className="float-center"
        />
      </div>
      <h3>Fjärrvärme</h3>
      <div className="well card card-body">
        <div className="indented-block">
          <p>
            Begreppet fjärrvärme kommer av att värmen produceras ’fjärran från’
            i relation där den slutligen används, exempelvis i en fastighet. Vad
            detta egentligen innebär är att det på olika orter i Sverige finns
            produktionsanläggningar där olika typer av bränslen används för att
            värma upp vatten som sedan distribueras till olika fastigheter som
            är anslutna till fjärrvärmenätet. Temperaturen på det utgående
            vattnet varierar under året och mellan olika orter från ca 70°C till
            120°C. Själva distributionen av det varma vattnet sker genom rör som
            grävts ner i marken.
          </p>
          <p>
            Väl framme i fastighetens fjärrvärmecentral, används värmeväxlare
            för att överföra värme från fjärrvärmenätet till fastighetens värme-
            respektive varmvattensystem. Värmeväxlingen sker utan att
            fjärrvärmevatten blandas med fastighetens system. Efter
            värmeväxlingen transporteras det nu avkylda fjärrvärmevattnet
            tillbaka till produktionsanläggningen för att åter värmas upp i en
            kontinuerlig cirkulär process.
          </p>
          <p>
            Det är viktigt att förstå att fjärrvärmesystem är lokala i den
            bemärkelsen att förutsättningarna är olika på olika orter. Generellt
            kan dock sägas att många produktionsanläggningar för fjärrvärme
            använder avfall, restvärme och olika sorters biobränslen i sin
            produktion. På många orter använder man också möjligheten att
            producera el samtidigt som man producerar värme i så kallade
            kraftvärmeanläggningar.
          </p>
          <p>
            Energiföretagen beskriver användningen av fjärrvärme enligt nedan:
            <br />
            <i>
              Fjärrvärme är den vanligaste uppvärmningsformen i Sverige. Mer än
              hälften av alla bostäder och lokaler i Sverige värms med
              fjärrvärme och bland flerfamiljshus är andelen omkring 90 procent.
            </i>
          </p>
        </div>
        <img
          src={manual_fjarrvarme_indata}
          alt="Fält för inmatning av fjärrvärmekostnader"
          className="float-right"
        />
        <p>
          Här kan man justera parametrar kopplade till fjärrvärme. Vilka
          parametrar som går att justera kan variera beroende på vilka
          komponenter som finns i prissättningen av fjärrvärme men normalt kan
          man justera debiteringseffekt och investeringskostnader.
        </p>
        <div className="pb-3">
          <p className="d-inline">
            Fjärrkontrollen beräknar en typisk debiteringseffekt (dvs. den
            effekt som används för debitering av effektkostnaden) baserat på
            fjärrvärmebolagets definition av hur effekten ska beräknas. Exakt
            hur debiteringseffekten ska beräknas varierar mellan olika orter men
            generellt kan man säga att den beror av fastighetens maximala
            (dygnsmedel)effektbehov för fjärrvärme vilket brukar uppstå under
            årets kallaste period. Fjärrkontrollen utgår från en typisk villa
            eller flerbostadshus vid beräkning av debiteringseffekt men
            möjligheten finns att justera denna för att anpassa beräkningen till
            andra förutsättningar eller för en känslighetsanalys.
          </p>
          <p
            className="expandLink d-inline"
            onClick={() => onExpand("manual_effektberakning_graf_1")}
          >
            {" "}
            Läs mer om effektdefinitioner.
          </p>
        </div>
        {expandableModule("manual_effektberakning_graf_1")}
        <p>
          I fjärrvärmefliken man man även justera investeringskostnaderna för
          fjärrvärmecentralen och för anslutningskostnaden (i de fall fjärrvärme
          inte finns framdraget till huset). Fjärrkontrollen utgår från
          investeringspriser som gäller för den aktuella orten och beräknar
          investeringskostnaderna för en typisk villa eller flerbostadshus. Det
          är möjligt att justera dessa investeringskostnader manuellt för att
          anpassa kalkylen till andra förutsättningar. Särskilt
          anslutningskostnaden kan variera beroende på tekniska förutsättningar.
        </p>
        <p>
          Det är också möjligt att justera vilken livslängd som ska användas för
          avbetalning av investering. Fjärrkontrollen utgår från en så kallad
          ekonomisk livslängd vilket innebär att man bestämmer en livslängd som
          ska gälla för den ekonomiska utvärderingen. Det är dock möjligt att
          justera livslängden för att exempelvis spegla den tekniska livslängden
          eller liknande. Det är då viktigt att beakta att livslängder bortom
          den tekniska livslängden gör att investeringskostnaden inte längre
          speglar hela kostnaden för en anläggning eftersom det krävs nya
          investeringar när utrustningen passerat sin tekniska livslängd.
        </p>
        <div className="row full-width">
          <div className="col-md-5">
            <img
              src={manual_fjarrvarme_resultat}
              alt="Resultatparametrar för fjärrvärme"
            />
          </div>
          <div className="col-md-7">
            <p>Resultaten för fjärrvärme beskrivs närmare nedan.</p>
            <div className="row">
              <div className="col-md-3 bold">Kapitalkostnad:</div>
              <div className="col-md-9">
                Årlig avbetalning för fjärrvärmecentral och anslutningskostnad
                beräknad utifrån antagen livslängd och ränta.
                Investeringskostnaden avser en helt färdig anläggning,
                installerad och driftsatt. Om man i de inledande
                förutsättningarna valt att man redan har fjärrvärme kommer denna
                kostnad vara noll.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Drift och underhåll:</div>
              <div className="col-md-9">
                Tillsyn/serviceavtal samt schablonkostnad för byte av
                komponenter som tex givare.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Energikostnad:</div>
              <div className="col-md-9">
                Kostnaden för energianvändning utifrån gällande energipris för
                fjärrvärme.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Fast/Effektkostnad:</div>
              <div className="col-md-9">
                Fast- alternativt effektkostnad för fjärrvärme utifrån gällande
                priser för fjärrvärme.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Annat:</div>
              <div className="col-md-9">
                Det kan finnas andra kostnader kopplade till någon specifik
                priskomponent i fjärrvärmen, som kostnad för flöde eller
                liknande och i så fall visas dessa på en egen rad.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Total värmekostnad:</div>
              <div className="col-md-9">
                Summan av ovan kostnader dividerat med fastighetens värmebehov
                och uttryckt som öre per kWh.
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3>Bergvärme- och Luft/Vattenvärmepump</h3>
      <div className="well card card-body">
        <div className="indented-block">
          <p>
            Värmepump är en teknik som kan användas för att utvinna energi från
            en källa med låg temperatur och leverera energi med hög temperatur.
            För att denna process ska fungera måste energi tillföras till
            värmepumpen.
          </p>
          <h4>Bergvärmepump</h4>
          <div className="img-container float-right">
            <img
              src={manual_bergvarmepump}
              alt="Schematisk bild för en bergvärmepump i en villa. Källa: SKVP"
              className="float-center"
            />
            <i>Schematisk bild för en bergvärmepump i en villa. Källa: SKVP</i>
          </div>
          <p>
            I fallet med bergvärme används berget som energikällan med låg
            temperatur. I praktiken borrar man djupa borrhål ('&gt;150m) i
            närheten av fastigheten där så kallade kollektorslangar (eller rör)
            placeras som används för att cirkulera en vätska som då hämtar
            energi ur berget. Denna energi med låg temperatur tillförs
            värmepumpen som genom en kompressor (som kräver el) höjer
            temperaturen på energin så att den kan användas i husets värme- och
            varmvattensystem.
          </p>
          <p>
            Prestanda för värmepumpar mäts genom den så kallade värmefaktorn (på
            engelska PF = Performance Factor eller COP = Coefficient of
            Performance) som speglar mängden värme som levererats från
            värmepumpen i förhållande till mängden el som använts av
            värmepumpen. Värmefaktorn varierar beroende på olika förhållanden
            och variationen kan vara betydande över året. Därför brukar man ofta
            prata om årsvärmefaktorn (SPF eller SCOP) som speglar värmepumpens
            prestanda under ett helt år.
          </p>
          <p>
            Generellt kan man säga att prestandan förbättras ju lägre
            temperaturskillnad det är mellan källan som värmepumpen hämtar
            energi från och temperaturbehovet i fastigheten. Ett typiskt
            vinterfall för en bergvärmepump kan vara att temperaturen från
            berget är kring 0°C. Om bergvärmepumpen då behöver leverera en
            temperatur till radiatorer som är 50°C kommer den ha en sämre
            årsverkningsgrad jämfört om den behövde leverera temperatur till
            golvvärme som kanske är 30°C.
          </p>
          <p>
            Det är inte alltid självklart hur en bergvärmepump ska dimensioneras
            varför en grundlig analys av husets och bergets förutsättningar är
            nödvändig. Exempelvis kan elnätsabonnemanget behöva uppgraderas för
            att kunna leverera den eleffekt som är nödvändig för värmepumpen.
            Det kan också finnas möjlighet att utnyttja det svala vattnet från
            bergen till kyla under varma sommarmånader vilket kräver anpassning
            av installationen.
          </p>
          <h4>Luft/Vattenvärmepump</h4>
          <p>
            Luft/vattenvärmepump har många liknade egenskaper som beskrivs för
            bergvärmepump. Den mest uppenbara skillnaden är att energin hämtas
            från omgivande utomhusluft snarare än från berget. Av den
            anledningen är installationen av en luft/vattenvärmepump enklare
            eftersom det inte kräver någon borrning av borrhål.
          </p>
          <p>
            En viktig skillnad är dock att prestandan för en
            luft/vattenvärmepump är helt beroende av temperaturen på
            utomhusluften som varierar över året och mellan olika orter. Vid
            riktigt låga temperaturer (under -20°C) kan inte de flesta
            värmepumpar längre fungera varpå man måste förlita sig på antigen en
            elpatron/elpanna eller någon annan värmekälla. Därför kan
            luft/vattenvärmepumpar vara mindre lämpliga för kallare klimat
            jämfört med bergvärme.
          </p>
        </div>
        <p>
          Det finns två flikar som har samma indataparametrar fast med olika
          värden. Den ena fliken gäller bergvärme och den andra
          luft-vattenvärmepump.
        </p>
        <img
          src={manual_bergvarme_och_luftvattenvarmepump_indata}
          alt="Fält för inmatning av parametrar kopplade till bergvärme- respektive luft/vattenvärmepump"
          className="float-right"
          style={{ width: "700px" }}
        />
        <div className="pb-3">
          <p className="d-inline">
            Fjärrkontrollen beräknar ett effektbehov av värme (inklusive
            varmvatten) för fastigheten baserat på angivet värmebehov och
            aktuell orts klimatdata. Effektbehovet som används för
            dimensionering av värmepumpens storlek utgår från ortens
            dimensionerande vinterutetemperatur (DVUT). För villa utgår
            beräkningen från 1-dygns DVUT medan för flerbostadshus utgår
            beräkningen från 3-dygns DVUT. Exakt vilka temperaturer som gäller
            på olika orter går att hitta på Boverkets hemsida. Effektbehovet kan
            justeras manuellt.
          </p>
          <p
            className="expandLink d-inline"
            onClick={() => onExpand("manual_effektberakning_graf_2")}
          >
            {" "}
            Läs mer om effektdefinitioner.
          </p>
        </div>
        <p>
          När det kommer till dimensionering av värmepump utgår Fjärrkontrollen
          från vad som kan anses vara en typisk nivå. Komponenten effekttäckning
          bestämmer hur mycket av effektbehovet som ska täckas av värmepumpen
          och hur mycket som ska täckas av elspets (elpatron eller elpanna). Det
          är vanligare att man i villor dimensionerar för 100% effekttäckning
          medan variationen är större för flerbostadshus.
        </p>
        <p>
          En hög täckningsgrad innebär att man måste investera i en större
          värmepump vilket påverkar investeringskostnaden. Beroende på hur
          fastighetens effektbehov ser ut över året kan skillnaden mellan 80%
          effekttäckning och 100% effekttäckning handla om enstaka timmar. Det
          är då oftast mer motiverat att låta de timmarna täckas av ren elvärme
          än att investera i en större (och dyrare) värmepump. I Fjärrkontrollen
          kan man själv justera dimensioneringsfaktorerna effekttäckning och
          energitäckning för att anpassa kalkylen.
        </p>
        {expandableModule("manual_effektberakning_graf_2")}
        <p>
          En viktig parameter för värmepumparna är årsvärmefaktorn vilken
          påverkar elanvändningen. En högre värmefaktor innebär att värmepumpen
          har en högre prestanda och kräver mindre el jämfört med en värmepump
          med lägre värmefaktor. Fjärrkontrollen beräknar årsvärmefaktorer för
          värmepumparna baserat på klimatdata för orten. Beräkningen förutsätter
          en värmepump med relativt hög prestanda men inte den absolut bästa på
          marknaden. Det är möjligt att justera värmefaktorn för att exempelvis
          spegla olika val av värmepumpars prestanda.
        </p>
        <p>
          Det är också möjligt att justera värmeeffektbehovet för huset för att
          spegla en annan dimensioneringsförutsättning för värmepumparna. Notera
          dock att denna komponent inte är kopplad till de andra
          uppvärmningsalternativen utan påverkar endast det alternativet där
          komponenten finns. Om man vill spegla ett annat effektbehov för
          samtliga alternativ måste motsvarande parameter ändras i samtliga
          flikar.
        </p>
        <div className="row full-width">
          <div className="col-md-5">
            <img
              src={manual_bergvarme_och_luftvarmepump_resultat}
              alt="Resultatparametrar för bergvärme- och luft/vattenvärmepump"
            />
          </div>
          <div className="col-md-7">
            <p>Resultaten för värmepumparna beskrivs närmare nedan.</p>
            <div className="row">
              <div className="col-md-3 bold">Kapitalkostnad:</div>
              <div className="col-md-9">
                Årlig avbetalning för värmepump, kringkostnader samt eventuellt
                borrhål (för bergvärmealternativet) beräknad utifrån antagen
                livslängd och ränta. Investeringskostnaden avser en helt färdig
                anläggning, installerad och driftsatt.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Drift och underhåll:</div>
              <div className="col-md-9">
                Tillsyn/serviceavtal samt schablonkostnad för byte av
                komponenter.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Energikostnad:</div>
              <div className="col-md-9">
                Kostnaden för el som används av värmepump samt eventuell
                elpatron/elpanna.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Ökad elnätskostnad:</div>
              <div className="col-md-9">
                Denna post redovisar den ökade elnätskostnaden som uppstår till
                följd av en värmepumpinstallation. Här ingår ökade rörliga och
                fasta kostnader för elnät inklusive elskatt och i förekommande
                fall ökade effektkostnader.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Total värmekostnad:</div>
              <div className="col-md-9">
                Summan av ovan kostnader dividerat med fastighetens värmebehov
                och uttryckt som öre per kWh.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">
                Beräknade värden för nytt elabonnemang:
              </div>
              <div className="col-md-9">
                Här redovisas fastighetens elparametrar efter installationen av
                värmepump, dvs den högsta eleffekten samt vilken ny säkringsnivå
                fastigheten behöver. Notera att Fjärrkontrollen beräknar
                säkringsstorlek i detalj medan det i verkligheten finns fasta
                steg (25A, 80A osv) så att ett säkringsbehov på 77,8 A innebär
                att fastigheten måste ha en 80 Ampere säkring.
              </div>
            </div>
          </div>
        </div>
      </div>
      <h3>Pellets</h3>
      <div className="well card card-body">
        <div className="indented-block">
          <p>
            Pelletspanna eller pelletskamin är en teknik där fast bränsle
            (pellets) förbränns och energin används för att värma upp vatten
            till värme- och varmvattensystem. På Pelletsförbundets beskriver man
            pellets som:
            <br />
            <i>
              Pellets är ett träbaserat bränsle med högt energinnehåll som
              används för uppvärmningsändamål.
            </i>
          </p>
          <p>
            Tekniken är inte längre särskild vanlig i villor och flerbostadshus
            men förekommer. Tekniken kräver transport och lagring samt matning
            av pellets till pelletspanna.
          </p>
        </div>
        <p>Här kan man justera parametrar kopplade till pelletspanna.</p>
        <img
          src={manual_pellets_indata}
          alt="Fält för inmatning av parametrar kopplade till pelletspanna"
          className="float-right"
          style={{ width: "350px" }}
        />
        <div className="pb-3">
          <p className="d-inline">
            Fjärrkontrollen beräknar ett effektbehov av värme (inklusive
            varmvatten) för fastigheten baserat på angivet värmebehov och
            aktuell orts klimatdata. Effektbehovet som används för
            dimensionering av pelletspanna utgår från ortens dimensionerande
            vinterutetemperatur (DVUT). För villa utgår beräkningen från 1-dygns
            DVUT medan för flerbostadshus utgår beräkningen från 3-dygns DVUT.
            Exakt vilka temperaturer som gäller på olika orter går att hitta på
            Boverkets hemsida. Effektbehovet kan justeras manuellt.
          </p>
          <p
            className="expandLink d-inline"
            onClick={() => onExpand("manual_effektberakning_graf_3")}
          >
            {" "}
            Läs mer om effektdefinitioner.
          </p>
        </div>
        <p>
          Fjärrkontrollen utgår också från en rimlig verkningsgrad för en
          pelletspanna och denna kan justeras för att simulera andra
          förutsättningar.
        </p>
        <p>
          På samma sätt som för andra alternativ är det möjligt att justera
          investeringskostnad och livslängd för pelletspanna. Fjärrkontrollen
          utgår från schabloner för investeringskostnader baserat på aktuellt
          marknadsläge för pelletspannor.
        </p>
        <p>
          Slutligen är det möjligt att justera priset för pellets.
          Fjärrkontrollen utgår dels från priser som Pelletsförbundet redovisar
          dels från Profus egna utredningar om bränslepriser.
        </p>
        {expandableModule("manual_effektberakning_graf_3")}
        <div className="row full-width">
          <div className="col-md-5">
            <img
              src={manual_pellets_resultat}
              alt="Resultatparametrar för pelletspanna"
            />
          </div>
          <div className="col-md-7">
            <p>Resultaten för pelletspanna beskrivs närmare nedan.</p>
            <div className="row">
              <div className="col-md-3 bold">Kapitalkostnad:</div>
              <div className="col-md-9">
                Årlig avbetalning för pelletspanna beräknad utifrån antagen
                livslängd och ränta. Investeringskostnaden avser en helt färdig
                anläggning, installerad och driftsatt. Notera att
                Fjärrkontrollen inte räknar med eventuella byggkostnader som
                exempelvis byggnad av skorsten.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Drift och underhåll:</div>
              <div className="col-md-9">
                Tillsyn/serviceavtal, sotning samt schablonkostnad för byte av
                komponenter.
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Energikostnad:</div>
              <div className="col-md-9">Kostnaden för inköp av pellets.</div>
            </div>
            <div className="row">
              <div className="col-md-3 bold">Total värmekostnad:</div>
              <div className="col-md-9">
                Summan av ovan kostnader dividerat med fastighetens värmebehov
                och uttryckt som öre per kWh.
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Livscykelmodul (om Livscykelmodulen är tillvald)</h2>
      <div className="well card card-body">
        <p>
          Livscykelmodulen som finns tillgänglig för Fjärrkontrollen liknar
          Grundmodulen med den viktiga skillnaden att kostnaderna för
          uppvärmning (inklusive varmvatten) redovisas som summan av alla
          kostnader under en angiven tidsperiod, snarare än som årliga
          kostnader. Notera att alla framtida kostnader i Livscykelmodulen
          redovisas som nuvärdeskostnader, dvs framtida kostnader har
          diskonterats till dagens nivå med hjälp av angiven ränta.
        </p>
        <p>
          För att kunna beräkna livscykelkostnaden använder sig Livscykelmodulen
          av ytterligare parametrar än de som kan anges i Grundmodulen av
          Fjärrkontrollen. Dessa är dels livslängden för beräkningsperioden,
          dels procentsatser för kostnadsförändringar av olika priskomponenter
          för beräkningsperioden. Livscykelperioden är normalt satt till 20 år
          som standard.
        </p>
        <p>
          Livscykelmodulen utgår från historiska prisförändringar för olika
          priskomponenter men dessa kan justeras för att spegla andra scenarier.
        </p>
        <img
          src={manual_livscykelmodul_indata1}
          alt="Fält för inmatning av årligt värmebehov, livslängd och förväntade energiprisförändringar"
          className="float-center"
        />
        <p>
          Under respektive flik för de olika uppvärmningsalternativen kan man
          även ange en Reinvesteringskostnad. Eftersom olika alternativ har en
          begränsad teknisk livslängd krävs det någon form av reinvestering i
          anläggningarna efter att den tekniska livslängden passerat. För en
          fjärrvärmecentral kan det innebära byte av växlare; för värmepumpar
          kan det innebära byte av kompressor medan för en pelletspanna kan det
          handla om byte av brännare och andra komponenter.
        </p>
        <p>
          Reinvesteringskostnaden speglar den framtida investering som behöver
          ske när den tekniska livslängden nåtts och livslängden specificerar
          när (räknat från ursprungliga investeringen) en sådan reinvestering
          behöver ske. Livscykelmodulen antar en rimlig reinvesteringskostnad
          samt perioden efter denna behöver tas för olika alternativ men detta
          kan justeras för att spegla andra förutsättningar.
        </p>
        <img
          src={manual_livscykelmodul_indata2}
          alt="Fält för inmatning av kostnad för reinvestering"
          className="float-center"
        />
        <h3>Resultat Livscykelmodul</h3>
        <p>
          Resultaten som presenteras i livscykelmodulen visar summan av alla
          kostnader för uppvärmning(inklusive varmvatten) för den angivna
          beräkningsperioden där framtida kostnader diskonterats till dagens
          nivå (även kallat nuvärde).
        </p>
        <p>
          Notera att Livscykelmodulen redovisar alla priskomponenter i
          resultaten, inklusive olika priskomponenter för elnät. I vissa fall
          kan installation av värmepump innebära att den fasta komponenten för
          elnät minskar om man går mellan en prislista med relativt hög fast
          kostnad till en (effekt)prislista där den fasta kostnaden är lägre men
          en effektkomponent tilkommer. I dessa fall kommer man i resultaten se
          en negativ kostnadsförändring för just den fasta elnätskomponenten.
        </p>
        <img
          src={manual_livscykelmodul_resultat}
          alt="Grafisk presentation av resultaten från livscykelmodulen"
          className="float-center"
        />
      </div>
    </div>
  );
}
