import Box from '@mui/material/Box';
import Sidebar from '../sidebar/sidebar';
import { Outlet, useParams } from 'react-router-dom';
import Main from './main';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import APIErrorNotification from '../error/snackbar';
import ErrorPage from '../../pages/error/error';
import { getLabelByCustomerId } from '../../api/label-api';

const supportedEconomicProfiles: (string | undefined)[] = ['foretag', 'privat'];

export default function CustomerArea() {
  let { customer, economicProfile } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [customerName, setCustomerName] = useState<string>();

  useEffect(() => {
    getLabelByCustomerId('CustomerName', customer, economicProfile)
      .then(response => {
        setCustomerName(response?.description);
      })
      .finally(() => setIsLoading(false));
  }, [customer, economicProfile]);

  if (isLoading) return null;

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Helmet>
        <title>
          {'Fjärrkontrollen' + (customerName ? ` - ${customerName}` : '')}
        </title>
      </Helmet>
      {customerName && supportedEconomicProfiles.includes(economicProfile) ? (
        <>
          <Sidebar customer={customer} economicProfile={economicProfile} />
          <Main customer={customer} economicProfile={economicProfile}>
            <Outlet />
            <APIErrorNotification />
          </Main>
        </>
      ) : (
        <Main>
          <ErrorPage error={'Sidan hittades inte'} />
        </Main>
      )}
    </Box>
  );
}
