import React from 'react';
import { makeStyles } from '@mui/styles';
import theme from './components/theme/theme';
import AppRoutes from './routes/approutes';
import { Provider } from 'react-redux';
import store from './common/store/store';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
  App: {
    width: '100vw',
  },
}));

export function App() {
  const classes = useStyles(theme);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>{'Fjärrkontrollen'}</title>
        </Helmet>
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
