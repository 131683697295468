import {
  Alternatives,
  Parameter,
  ParameterType,
  SelectionAlternative,
} from "../../common/types/parameters";
import { getType } from "../data-presentation/util";
import Dropdown from "./dropdown";

export default function MultiParameterInput(params: {
  parameters: Parameter[];
  disabled?: boolean;
  onValidChange?: (id: string, value: string) => void;
}) {
  return canHaveCommonSelectionInput(params.parameters) ? (
    <Dropdown
      inputId={params.parameters
        .map((parameter) => parameter.id)
        .toString()
        .replaceAll(",", "-")}
      defaultValue={params.parameters[0].value}
      alternatives={params.parameters[0].constraints as Alternatives}
      hideAlternativeValues={true}
      title={params.parameters[0].displayName}
      description={params.parameters[0].description}
      disabled={params.parameters.some((parameter) => !parameter.editable)}
      onValidChange={(id, value) => {
        if (params.onValidChange !== undefined) {
          let optionSuffix = JSON.parse(value).id.substring(
            params.parameters[0].id.length
          );
          params.parameters.forEach((parameter) => {
            params.onValidChange!(
              parameter.id,
              JSON.stringify(
                (parameter.constraints as Alternatives).find((alternative) =>
                  alternative.id.endsWith(optionSuffix)
                )
              )
            );
          });
        }
      }}
      includeVat={false}
    />
  ) : null;
}

/**
 * Determines whether the list of parameters can be represented by
 * a single selection input.
 * @param parameters
 * @returns true if there are > 1 selection parameters with
 * matching non-editable alternatives, otherwise false
 */
export function canHaveCommonSelectionInput(parameters: Parameter[]) {
  if (
    parameters.length <= 1 ||
    !parameters.every(
      (parameter) => getType(parameter) === ParameterType.Selection
    )
  ) {
    return false;
  }

  let alternatives = parameters.map(
    (parameter) => parameter.constraints as Alternatives
  );

  // No alternatives, of any parameter in the group, can have
  // editable values
  if (
    alternatives
      .flatMap((alternativeList) => alternativeList)
      .some((alternative) => alternative.editable)
  ) {
    return false;
  }

  // All involved parameters need to have the exact same alternative IDs
  // to select from. Thus, their lists of alternatives should be of the same
  // length and have corresponding suffixes
  if (
    alternatives.some(
      (alternativeList) => alternativeList.length !== alternatives[0].length
    )
  ) {
    return false;
  }
  let alternativeSuffixes = alternatives.map((alternativeList, index) =>
    alternativeList.map((alternative) =>
      getAlternativeSuffix(parameters[index].id, alternative)
    )
  );
  if (
    alternativeSuffixes[0].some((alternativeSuffix) =>
      alternativeSuffixes.some(
        (suffixList) =>
          suffixList.find((suffix) => suffix === alternativeSuffix) ===
          undefined
      )
    )
  ) {
    return false;
  }

  return true;
}

/**
 * Returns the alternative ID, not including the parameter ID.
 * @param parameterId
 * @param alternative The ID of the alternative is expected to be initiated with the @parameterId
 * @returns
 */
function getAlternativeSuffix(
  parameterId: string,
  alternative: SelectionAlternative
): string {
  return alternative.id.substring(parameterId.length);
}
