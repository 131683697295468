import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles, createStyles } from "@mui/styles";
import {
  Button,
  CircularProgress,
  debounce,
  Paper,
  SxProps,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import theme from "../../../components/theme/theme";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../common/store/store";
import {
  getStepStates,
  setUserInput as setWizardUserInput,
} from "../../../common/store/wizard-slice";
import { getWizardSteps } from "../../../api/wizard-api";
import {
  allStepsAreCompleted,
  getUserInputForStepId,
  parseJsonToObject,
} from "../../../components/wizard/util";
import {
  Alternatives,
  NumericParameterStyle,
  Parameter,
  ParameterType,
  ParameterValue,
} from "../../../common/types/parameters";
import { calculateOutput, getModules } from "../../../api/module-api";
import { WizardStep } from "../../../common/types/wizard";
import {
  getParameterStates,
  setUserInput as setModuleUserInput,
  resetUserInputsWithId as resetModuleUserInputsWithId,
} from "../../../common/store/module-parameters-slice";
import {
  format,
  getDisplayValue,
  getType,
} from "../../../components/data-presentation/util";
import useAPIError from "../../../common/hooks/useAPIError";
import { getLabel } from "../../../api/label-api";
import ParameterInput from "../../../components/parameters/parameter-input";
import CostPresentation, { Representation } from "./cost-presentation";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: "15px",
    },
  })
);

const calcModuleId = "detaljer";

export default function OversiktligaResultat(params: {
  forceParameterReload?: boolean;
  onReload?: () => void;
  sx?: SxProps;
}) {
  let { customer } = useParams();
  let { economicProfile } = useParams();
  const { addError } = useAPIError();

  const [detailsModuleIsAvailable, setDetailsModuleIsAvailble] =
    useState(false);

  useEffect(() => {
    if (customer !== undefined && economicProfile !== undefined) {
      getModules(customer, economicProfile).then((availableModules) => {
        setDetailsModuleIsAvailble(
          availableModules.findIndex((module) => module.id === calcModuleId) >=
            0
        );
      });
    }
  }, [customer, economicProfile]);

  let wizardStepInputs = useAppSelector((state) =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  let moduleParameterInputs = useAppSelector((state) =>
    getParameterStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  const [outputParameters, setOutputParameters] = useState<Parameter[]>([]);
  const includeVat: boolean = JSON.parse(
    outputParameters.find((parameter) => parameter.id === "VATSelection")
      ?.value ?? "false"
  );

  const [districtHeatingLabels, setDistrictHeatingLabels] =
    useState<Parameter>();
  const [groundSourceHeatPumpLabels, setGroundSourceHeatPumpLabels] =
    useState<Parameter>();
  const [airSourceHeatPumpLabels, setAirSourceHeatPumpLabels] =
    useState<Parameter>();
  const [pelletLabels, setPelletLabels] = useState<Parameter>();

  const heatingTechnologies = [
    {
      id: "DistrictHeating",
      title: districtHeatingLabels?.displayName || "Fjärrvärme",
    },
    {
      id: "GroundSource",
      title:
        groundSourceHeatPumpLabels?.displayName || "Bergvärme (med elspets)",
    },
    {
      id: "AirSource",
      title:
        airSourceHeatPumpLabels?.displayName ||
        "Luft/vatten-värmepump (med elspets)",
    },
    {
      id: "Pellet",
      title: pelletLabels?.displayName || "Pellets",
    },
  ];

  const dispatch = useAppDispatch();

  function onValidParameterChange(id: string, value: string) {
    if (wizardStepInputs.some((parameter) => parameter.id === id)) {
      let parameter = outputParameters.find((parameter) => parameter.id === id);

      if (parameter !== null && parameter !== undefined) {
        saveWizardParameter(
          id,
          value,
          getDisplayValue(
            value,
            includeVat,
            getType(parameter),
            parameter?.stylingConfig,
            true
          ) ?? value
        );
      }
    } else {
      saveModuleParameter(id, value);
    }
  }

  function saveWizardParameter(
    id: string,
    value: string,
    formattedValue: string
  ) {
    dispatch(
      setWizardUserInput({
        id: id,
        value: value,
        displayValue: formattedValue,
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
  }

  function saveModuleParameter(id: string, value: string) {
    dispatch(
      setModuleUserInput({
        id: id,
        value: value,
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
  }

  const getWizardStepsDelayed = useCallback(
    debounce((callback: (steps: WizardStep[]) => void) => {
      getWizardSteps(customer, economicProfile).then((steps) => {
        callback(steps);
      });
    }, 600),
    [customer, economicProfile]
  );

  useEffect(() => {
    getWizardStepsDelayed((wizardSteps) => {
      if (
        allStepsAreCompleted(wizardStepInputs, wizardSteps) &&
        !params.forceParameterReload
      ) {
        // If no parameter reload is necessary and all inputs are available, use the existing input to calculate the result
        let inputs = wizardStepInputs
          .map(
            (step) => ({ id: step.id, value: step.value!.id } as ParameterValue)
          )
          .concat(moduleParameterInputs);
        calculateOutput(calcModuleId, inputs, customer, economicProfile)
          .then((parameters) => {
            setOutputParameters(parameters);
          })
          .catch(() => addError("Error"));
      }
    });
  }, [
    addError,
    customer,
    getWizardStepsDelayed,
    moduleParameterInputs,
    params.forceParameterReload,
    wizardStepInputs,
  ]);

  useEffect(() => {
    if (params.forceParameterReload) {
      // Check if parameters should be reloaded
      let paramsToReset = outputParameters
        .filter((parameter) =>
          isSelectionParameterWithUnmodifiableAlternatives(parameter)
        )
        .map((parameter) => parameter.id);

      if (params.onReload !== undefined && params.onReload !== null) {
        params.onReload();
      }

      dispatch(
        resetModuleUserInputsWithId({
          ids: paramsToReset,
          profile: { customer: customer, economicProfile: economicProfile },
        })
      );
    }
  }, [customer, dispatch, economicProfile, outputParameters, params]);

  useEffect(() => {
    let districtValue = getUserInputForStepId(
      "DistrictSelected",
      wizardStepInputs
    )?.id;
    let buildingValue = getUserInputForStepId(
      "BuildingProfile",
      wizardStepInputs
    )?.id;
    const district = parseJsonToObject(districtValue)?.value;
    const building = parseJsonToObject(buildingValue)?.value;
    if (district !== undefined && building !== undefined) {
      getLabel(
        "DistrictHeatingTab",
        customer,
        district,
        building,
        economicProfile
      ).then((response) => {
        setDistrictHeatingLabels(response);
      });
      getLabel(
        "GroundSourceHeatPumpTab",
        customer,
        district,
        building,
        economicProfile
      ).then((response) => {
        setGroundSourceHeatPumpLabels(response);
      });
      getLabel(
        "AirSourceHeatPumpTab",
        customer,
        district,
        building,
        economicProfile
      ).then((response) => {
        setAirSourceHeatPumpLabels(response);
      });
      getLabel("PelletTab", customer, district, building, economicProfile).then(
        (response) => {
          setPelletLabels(response);
        }
      );
    }
  }, [customer, wizardStepInputs]);

  const classes = useStyles(theme);

  let interestRateParam = outputParameters.find(
    (param) => param.id === "InterestRate"
  );
  return outputParameters.length > 0 ? (
    <Box key="oversikt-module-container" sx={params.sx}>
      <Typography variant="h1">Översikt</Typography>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        <Box gridColumn="span 12">
          <Paper className={classes.card} elevation={3}>
            <Typography paragraph>
              Nedan ges en översiktlig sammanställning av värmekostnader utifrån
              det du angivit i guiden. Värmebehovet du valt kan justeras nedan.
              Om du vill ändra andra val i guiden kan du gå tillbaka via
              genvägarna till vänster. Om du vill se mer detaljerade resultat
              och justera ekonomiska och tekniska parametrar kan detta göras i
              ”Detaljerad kostnadskalkyl” som du når via knappen nedan eller via
              genvägarna till vänster.
            </Typography>
            <Typography paragraph>
              {`Samtliga kostnader är ${
                includeVat ? "inklusive" : "exklusive"
              } moms. Kapitalkostnaden baseras på ${format(
                interestRateParam!.value,
                includeVat,
                interestRateParam!.stylingConfig
              )}${
                (interestRateParam!.stylingConfig as NumericParameterStyle)
                  ?.unit
              } ränta.`}
            </Typography>
            <ParameterInput
              parameter={
                outputParameters.find(
                  (param) => param.id === "AnnualHeatingDemand"
                )!
              }
              disabled={false}
              onValidChange={onValidParameterChange}
              includeVat={includeVat}
            />
          </Paper>
        </Box>
        <Box gridColumn="span 7">
          <CostPresentation
            heatingTechnologies={heatingTechnologies}
            parameters={outputParameters}
            includeVat={includeVat}
            type={Representation.Table}
          />
        </Box>
        <Box gridColumn="span 5">
          <Paper elevation={3}>
            <CostPresentation
              heatingTechnologies={heatingTechnologies}
              parameters={outputParameters}
              includeVat={includeVat}
              type={Representation.Chart}
            />
          </Paper>
        </Box>
        {detailsModuleIsAvailable && (
          <Box gridColumn="span 12" sx={{ textAlign: "center" }}>
            <Button
              component={Link}
              to={`/${customer}/${economicProfile}/detaljer`}
              color="button"
              variant="contained"
              sx={{ marginTop: 3, marginBottom: 12 }}
            >
              Se detaljerad kostnadskalkyl
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box sx={{ textAlign: "center", ...params.sx }}>
      <CircularProgress />
    </Box>
  );
}

function isSelectionParameterWithUnmodifiableAlternatives(
  parameter: Parameter
): boolean {
  return (
    getType(parameter) === ParameterType.Selection &&
    (parameter.constraints as Alternatives) &&
    (parameter.constraints as Alternatives)
      .flatMap((alternativeList) => alternativeList)
      .some((alternative) => !alternative.editable)
  );
}
