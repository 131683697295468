import Highcharts, {
  PointLabelObject,
  PointerAxisCoordinateObject,
  StackItemObject,
  Tooltip,
  TooltipFormatterContextObject,
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  NumericParameterStyle,
  Parameter,
  ParameterGroup,
} from "../../common/types/parameters";
import {
  DataTable,
  format,
  getMostDetailedStyling,
  prepareData,
  scaleToUnit,
} from "./util";

export enum Orientation {
  horizontal,
  vertical,
}

export default function BarChart(params: {
  parameterGroups: ParameterGroup[];
  title?: string;
  description?: string;
  unit?: string;
  orientation?: Orientation;
  displayLegends?: boolean;
  includeVat: boolean;
  toPrint: boolean;
}) {
  let table = prepareData(params.parameterGroups);
  fillWithZeroes(table.data, table.rowTitles.length, table.columnTitles.length);
  let styling = getStyling(params.parameterGroups);

  let colors = // Workaround to get correct cooloring of overview and details graph
    params.parameterGroups[0].parameters.length === 2
      ? [
          "#434348", // RunningCostsSum
          "#7cb5ec", // CapitalCost
        ]
      : params.parameterGroups[0].parameters.length === 9
      ? [
          "#081d58", // LCC: Ökad elnätskostnad, effekt
          "#cab2d6", // LCC: Ökad elnätskostnad, fast
          "#ff7f00", // LCC: Ökad elnätskostnad, rörlig
          "#e31a1c", // FlowCost, (avkylningskostnad)
          "#fb9a99", // LoadCost
          "#33a02c", // EnergyCost
          "#b2df8a", // MaintenanceCost
          "#a6cee3", // CapitalCost
          "#fdbf6f", // LCC: reinvestering
        ]
      : [
          "#fdbf6f", // IncreasedGridCost
          "#e31a1c", // FlowCost, (avkylningskostnad)
          "#fb9a99", // LoadCost
          "#33a02c", // EnergyCost
          "#b2df8a", // MaintenanceCost
          "#a6cee3", // CapitalCost
        ];

  let cleanedData = removeEmptySeries(table, colors);

  const options = {
    legend: {
      enabled: params.displayLegends,
    },
    chart: {
      type: params.orientation === Orientation.horizontal ? "bar" : "column",
    },
    title: {
      text: params.title,
    },
    subtitle: {
      text: params.description,
    },
    xAxis: {
      categories: cleanedData.table.columnTitles,
    },
    colors: cleanedData.colors.reverse(),
    yAxis: {
      title: {
        text: params.unit,
      },
      stackLabels: {
        enabled: true,

        formatter: function (this: StackItemObject) {
          let heatingTechnologyIndex = this.x;
          let costTypeData = this.axis.series;
          let relevantCosts = costTypeData
            .filter((costData) => costData.visible)
            .map(
              (costData) => (costData as any).yData[heatingTechnologyIndex] ?? 0
            );
          let total = relevantCosts.reduce(
            (sum, currentCost) => sum + currentCost,
            0
          );
          return (this.total as number) > 0
            ? format(total as number, false, styling)
            : "";
        },
      },
    },
    series: cleanedData.table.data
      .map((row, index) => {
        return {
          name: cleanedData.table.rowTitles[index],
          data: row.map((parameter) =>
            scaleToUnit(
              Number(parameter.value),
              (parameter.stylingConfig as NumericParameterStyle)?.unit,
              params.includeVat
            )
          ),
        };
      })
      .reverse(),
    tooltip: {
      formatter: function (
        this: TooltipFormatterContextObject,
        tooltip: Tooltip
      ) {
        return `
          <tspan style="font-size: 10px;">${this.x}</tspan>
          <tspan class="highcharts-br">&nbsp;</tspan>
          <span style="color: ${this.color}; fill: ${this.color};">
            ●
          </span>
          ${this.series.name}: <tspan style="font-weight: bold;">
            ${format(
              this.y as number,
              false /* VAT already considered in series */,
              styling
            )}
          </tspan>
          `;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        animation: params.toPrint ? false : true,
      },
      bar: {
        stacking: "normal",
        animation: params.toPrint ? false : true,
      },
    },
  };

  return (
    <HighchartsReact
      containerProps={params.toPrint ? { style: { width: "130mm" } } : {}}
      highcharts={Highcharts}
      options={options}
    />
  );
}

function fillWithZeroes(table: Parameter[][], rows: number, columns: number) {
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < columns; c++) {
      if (table[r] && table[r][c] === undefined) {
        table[r][c] = {
          id: "",
          value: "0",
        } as Parameter;
      }
    }
  }
}

function getStyling(parameterGroups: ParameterGroup[]): NumericParameterStyle {
  let detailedStyling = getMostDetailedStyling(
    parameterGroups.flatMap((group) => group.parameters)
  );
  return {
    numDecimals: detailedStyling.numDecimals,
    decimalSeparator: detailedStyling.decimalSeparator,
    stepSize: Math.max(100, detailedStyling.stepSize),
    thousandsSeparator: detailedStyling.thousandsSeparator,
  } as NumericParameterStyle; /* ignore unit since scaling has already been done in series */
}

function removeEmptySeries(
  table: DataTable,
  colors: string[]
): { table: DataTable; colors: string[] } {
  let seriesToRemove = table.rowTitles
    .map((series, index) =>
      series === undefined || series === null ? index : -1
    )
    .filter((index) => index !== -1);

  return {
    table: {
      columnTitles: table.columnTitles,
      rowTitles: table.rowTitles.filter(
        (title, index) => !seriesToRemove.includes(index)
      ),
      data: table.data.filter(
        (seriesData, index) => !seriesToRemove.includes(index)
      ),
    } as DataTable,
    colors: colors.filter(
      (seriesColor, index) => !seriesToRemove.includes(index)
    ),
  };
}
