import axios from 'axios';
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from '../authConfig';
import { baseApiUrl, redirectURI } from '../base';

export const getToken = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    return '';
  }
  const request = {
    scopes: [
      'https://fjarrkontrollen.onmicrosoft.com/32fc3015-ff0c-48e1-adc4-630c97761816/userclaims.read',
    ],
    account: activeAccount || accounts[0],
    forceRefresh: true,
  };

  const authResult = await msalInstance
    .acquireTokenSilent(request)
    .catch(async error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        console.log(error);

        const [, customer, economicProfile] =
          window.location.pathname.split('/');
        return msalInstance.acquireTokenRedirect({
          ...request,
          redirectUri: `${redirectURI}/${customer}/${economicProfile}`,
        });
      }
      // handle other errors
    });

  return authResult?.accessToken || '';
};

export const httpClient = axios.create({
  baseURL: baseApiUrl,
});

export default httpClient;

let tokenPromise: null | Promise<string | undefined> = null;

httpClient.interceptors.request.use(
  async config => {
    if (tokenPromise === null) {
      tokenPromise = getToken().finally(() => (tokenPromise = null));
    }

    const token = await tokenPromise;

    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

httpClient.interceptors.response.use(response => response, handleHttpError);

function handleHttpError(error: any) {
  //TODO handling

  //"Unauthorized"
  if (error.response?.status === 401) {
    const storage = sessionStorage.getItem('wizardStepsState') as string;
    if (storage) {
      //redirects to error page 401
      window.location.href = `${window.location.origin}/${
        JSON.parse(storage).meta.customer
      }/${JSON.parse(storage).meta.economicProfile}/401`;
    } else {
      window.location.href = `${window.location.href}/error`;
    }
  }

  return Promise.reject(error);
}
