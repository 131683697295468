import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Grid, Link } from "@mui/material";
import Logo from "../../assets/img/FK_logo_small.png";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useCollapse from "react-collapsed";

export default function WelcomePage() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <>
      <Typography variant="h1" key="welcome-page-title">
        Välkommen till Fjärrkontrollen!
      </Typography>
      <Grid container direction="row-reverse" spacing={3}>
        <Grid item xs={12} md={4}>
          <Box key="welcome-logo-box" component="img" width="100%" src={Logo} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            paragraph
            key={"welcome-page-content-summary"}
            variant="body1"
          >
            Med Fjärrkontrollen kan man jämföra kostnaden för uppvärmning för
            ett hus på uppvärmningsformerna fjärrvärme, värmepumpar och
            pelletspanna.
            <Button variant="text" {...getToggleProps()}>
              {isExpanded ? "Dölj information" : "Läs mer"}
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </Typography>
          <Box {...getCollapseProps()}>
            <Typography
              paragraph
              key={"welcome-page-accordion-content"}
              variant="body1"
            >
              I Fjärrkontrollen kan man specificera husets förutsättningar vad
              gäller t.ex. energi- och effektbehov för att kunna få en så
              rättvisande kostnadsbild som möjligt. Kostnadskalkylen för
              fjärrvärme är framtagen i samarbete det lokala energibolaget för
              att bli korrekt. För övriga uppvärmningsalternativ är
              osäkerheterna större, då exempelvis investeringskostnaden och
              värmefaktorn för en bergvärmepump varierar från hus till hus
              beroende på förutsättningarna. Därför finns det möjlighet att
              justera parametrarna för de alternativa uppvärmningsformerna så
              att man exempelvis kan lägga in värdena från en offert.
              Standardvärdena för alternativen representerar ett
              genomsnittsfall.
            </Typography>
          </Box>
          <Button
            component={Link}
            target="_blank"
            href="http://www.profu.se/fjkoll.htm"
            color="button"
            variant="contained"
          >
            Nyfiken? Läs mer på Profus hemsida
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
