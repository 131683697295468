import { Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../common/store/store";
import { getStepStates } from "../../../common/store/wizard-slice";
import {
  getRawUserInputForStepId,
  parseJsonToObject,
} from "../../../components/wizard/util";
import theme from "../../../components/theme/theme";
import { makeStyles, createStyles } from "@mui/styles";

import "../../../assets/angular-components/klimatmodulen";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: "15px",
    },
  })
);

export default function DistrictHeatingClimateInfoAngular() {
  const classes = useStyles(theme);

  let { customer, economicProfile } = useParams();

  let stepStates = useAppSelector((state) =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  let district = parseJsonToObject(
    getRawUserInputForStepId("DistrictSelected", stepStates)
  )?.value;
  let building = parseJsonToObject(
    getRawUserInputForStepId("BuildingProfile", stepStates)
  )?.value;

  return (
    <div>
      <Typography variant="h1">Fjärrvärmesystemet</Typography>
      <Paper className={classes.card} elevation={3} sx={{ mb: 3 }}>
        <Typography paragraph>
          Fjärrvärmen produceras i flera olika anläggningar med olika bränslen.
          Första diagrammet nedan visar hur fjärrvärme produceras på aktuell
          ort.
        </Typography>
        <Typography paragraph>
          Stora baslastanläggningar används först och under större delen av
          året. Mindre spetslastanläggningar används bara när behovet är som
          störst. Om fastigheten ska anslutas till fjärrvärme innebär det att
          vissa anläggningar kommer att producera mer värme jämfört med idag.
          Dessa anläggningar är oftast inte baslastanläggningar som redan idag
          används i hög utsträckning. Istället är det anläggningar som har
          outnyttjad kapacitet. Exakt vilka bränslen som skulle behöva användas
          om fastigheten ansluts till fjärrvärme framgår av andra diagrammet
          nedan.
        </Typography>
      </Paper>
      <district-heating-system-component
        customer={customer}
        district={district}
        building={building}
        economic={economicProfile}
      />
    </div>
  );
}
