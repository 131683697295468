import { Alert, Snackbar } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import useAPIError from "../../common/hooks/useAPIError";

const useStyles = makeStyles(() =>
  createStyles({
    errorAlert: {
      backgroundColor: "red",
      color: "black",
    },
  })
);

const errorMsg = (
  <>
    Något gick fel, om felet består kontakta oss{" "}
    <a href="mailto:fjarrkontrollen@profu.se">här</a>
  </>
);

export default function APIErrorNotification() {
  const { error } = useAPIError();
  const classes = useStyles();
  return (
    <Snackbar
      open={error !== undefined}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
    >
      <Alert
        severity="error"
        sx={{ width: "100%" }}
        className={classes.errorAlert}
      >
        {errorMsg}
      </Alert>
    </Snackbar>
  );
}
