import Box from "@mui/material/Box";
import Background from "../../assets/img/Bakgrund_FK1.png";
import { makeStyles } from "@mui/styles";
import theme from "../theme/theme";

const useStyles = makeStyles({
  BackgroundImage: {
    backgroundImage: `url(${Background})`,
    backgroundColor: theme.palette.secondary.main,
    overflowY: "auto",
    overflowX: "hidden",
    padding: "100px 0px 0px 0px",
    backgroundPositionY: "center",
    backgroundPositionX: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
  },
});

export default function Main({ children }: any) {
  const classes = useStyles(theme);

  return (
    <div className={classes.BackgroundImage}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: "1100px",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "transparent",
          height: "100vh",
        }}
      >
        {children}
      </Box>
    </div>
  );
}
