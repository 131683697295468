import { SxProps } from "@mui/material";
import { Parameter } from "../../../common/types/parameters";
import { Column } from "../types";
import ParameterSection from "../parameter-section";

export default function AirSourceHeatPump(params: {
  description?: string;
  printTitle: string;
  parameters: Parameter[];
  includeVat: boolean;
  toPrint: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
}) {
  return (
    <ParameterSection
      printTitle={params.printTitle}
      description={params.description}
      parameters={params.parameters}
      columns={columns}
      toPrint={params.toPrint}
      includeVat={params.includeVat}
      onValidParameterChange={params.onValidParameterChange}
      sx={params.sx}
    />
  );
}

const columns: Column[] = [
  {
    sections: [
      {
        title: "Kostnader",
        parameters: [
          "AirSourceInvestmentLCCost",
          "AirSourceHeatPumpMaintenanceLCCost",
          "AirSourceEnergyLCCost",
          "AirSourceGridLCCostFixed",
          "AirSourceGridLCCostPower",
          "AirSourceGridLCCostVariable",
          "AirSourceReinvestmentLCCost",
        ],
      },
    ],
    readOnly: true,
  },
  {
    sections: [
      {
        title: "",
        parameters: [
          "GridFusePriceFixed",
          "GridFusePriceVariableLow",
          "GridFusePriceVariableHigh",
        ],
      },
      {
        title: "",
        parameters: [
          "GridPowerPriceFixed",
          "GridPowerPriceLoadLow",
          "GridPowerPriceLoadHigh",
          "GridPowerPriceVariableHigh",
          "GridPowerPriceVariableLow",
        ],
      },
      {
        title: "Detaljerade indata",
        parameters: [
          "AirSourceHeatingDesignLoad",
          "AirSourceHeatPumpLoadCoverage",
          "AirSourceHeatPumpEnergyCoverage",
          "AirSourceHeatPumpDeliveredHeatingLoad",
          "AirSourceRemainingHeatingLoad",
          "AirSourceHeatPumpSCOPRadiator",
          "AirSourceHeatPumpSCOPFloor",
        ],
      },
      {
        title: "Investeringskostnad",
        parameters: [
          "AirSourceEnergyWellCost",
          "AirSourceHeatPumpCost",
          "AirSourceMiscellaneousCost",
          "AirSourceInvestmentLCCost",
        ],
      },
      {
        title: "Reinvesteringskostnad",
        parameters: [
          "AirSourceReinvestmentCost",
          "AirSourceReinvestmentPeriod",
        ],
      },
      {
        title: "Drift- och underhållskostnader",
        parameters: ["AirSourceHeatPumpMaintenanceCost"],
      },
    ],
  },
];
