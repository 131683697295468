import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CustomerArea from '../components/layout/customer-area';
import Main from '../components/layout/main';
import IntroPage from '../pages/intro/intro';
import WelcomePage from '../pages/intro/welcome';
import OversiktligaResultatModule from '../pages/modules/oversikt/oversikt';
import DetaljeradeResultatModule from '../pages/modules/detaljer/detaljer';
import LifecycleModule from '../pages/modules/lifecycle/lifecycle';
import { ControlledAccordions } from '../pages/wizard/wizard';
import { APIErrorContextProvider } from '../common/providers/APIErrorProvider';
import ErrorPage from '../pages/error/error';
import HjalpModule from '../pages/modules/hjalp/hjalp';
import KlimatModule from '../pages/modules/klimat/klimat';
import GridClimateInfoAngular from '../pages/modules/klimat/grid-info-angular';
import DistrictHeatingClimateInfoAngular from '../pages/modules/klimat/district-heating-info-angular';
import ClimateModuleInfo from '../pages/modules/klimat/module-info';
import PriceAdjustments from '../pages/modules/price-adjustments/priceAdjustments';

const AppRoutes = () => {
  return (
    <APIErrorContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/:customer/:economicProfile" element={<CustomerArea />}>
            <Route
              path="401"
              element={
                <ErrorPage error="Du har inte behörighet till denna sidan" />
              }
            />
            <Route
              path="*"
              element={<ErrorPage error="Sidan hittades inte" />}
            />
            <Route path="oversikt" element={<OversiktligaResultatModule />} />
            <Route path="detaljer" element={<DetaljeradeResultatModule />} />
            <Route path="klimat" element={<KlimatModule />} />
            <Route
              path="klimat/fjarrvarmesystemet"
              element={<DistrictHeatingClimateInfoAngular />}
            />
            <Route
              path="klimat/elsystemet"
              element={<GridClimateInfoAngular />}
            />
            <Route path="klimat/information" element={<ClimateModuleInfo />} />
            <Route path="manual" element={<HjalpModule />} />
            <Route path="lcc" element={<LifecycleModule />} />
            <Route path="intro" element={<IntroPage />} />
            <Route path="pa" element={<PriceAdjustments />} />
            <Route path="" element={<ControlledAccordions />} />
          </Route>
          <Route
            path="/"
            element={
              <Main>
                <WelcomePage />
              </Main>
            }
          />
        </Routes>
      </BrowserRouter>
    </APIErrorContextProvider>
  );
};

export default AppRoutes;
