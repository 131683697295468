import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles, createStyles } from '@mui/styles';
import {
  Alert,
  AlertTitle,
  Button,
  Collapse,
  debounce,
  Grid,
  Paper,
  SxProps,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import theme from '../../../components/theme/theme';
import CommonSection from './common-section';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../common/store/store';
import {
  getStepStates,
  resetUserInputs as resetWizardUserInput,
  setUserInput as setWizardUserInput,
  deviatesFromDefaults as wizardInputDeviatesFromDefaults,
} from '../../../common/store/wizard-slice';
import { getWizardSteps } from '../../../api/wizard-api';
import {
  allStepsAreCompleted,
  getUserInputForStepId,
  parseJsonToObject,
} from '../../../components/wizard/util';
import { Parameter, ParameterValue } from '../../../common/types/parameters';
import { calculateOutput } from '../../../api/module-api';
import { WizardStep } from '../../../common/types/wizard';
import Pellet from './pellet-section';
import GroundSourceHeatPump from './ground-source-section';
import AirSourceHeatPump from './air-source-section';
import {
  getParameterStates,
  setUserInput as setModuleUserInput,
  resetUserInputs as resetModuleUserInput,
  deviatesFromDefaults as moduleInputDeviatesFromDefaults,
} from '../../../common/store/module-parameters-slice';
import {
  getDisplayValue,
  getType,
} from '../../../components/data-presentation/util';
import '../module.css';
import useAPIError from '../../../common/hooks/useAPIError';
import { getLabel } from '../../../api/label-api';
import { useReactToPrint } from 'react-to-print';
import LifecycleChart from './graph';
import DistrictHeating from './district-heating-section';
import BuildingElectricity from './building-electricity-section';
import PrintIcon from '@mui/icons-material/Print';
import { ModuleInfo } from '../../../common/types/modules';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '15px',
    },
  })
);

const moduleId = 'lcc';
const paperElevation = 3;

export default function LivscykelResultat(params: { sx?: SxProps }) {
  let { customer } = useParams();
  let { economicProfile } = useParams();
  const { addError } = useAPIError();

  //printing
  const [toPrint, setToPrint] = useState<boolean>(false);
  const [labelTitle, setLabelTitle] = useState<string>('');
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const moduleInfo: Omit<ModuleInfo, 'accessibleToUser'> = {
    id: moduleId,
    displayName: 'Livscykelkostnad',
  };

  let wizardStepInputs = useAppSelector(state =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  let moduleParameterInputs = useAppSelector(state =>
    getParameterStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );

  const [outputParameters, setOutputParameters] = useState<Parameter[]>([]);
  const includeVat: boolean = JSON.parse(
    outputParameters.find(parameter => parameter.id === 'VATSelection')
      ?.value ?? 'false'
  );

  let deviatesFromDefaults = useAppSelector(
    state =>
      wizardInputDeviatesFromDefaults(state, {
        customer: customer,
        economicProfile: economicProfile,
      }) ||
      moduleInputDeviatesFromDefaults(state, {
        customer: customer,
        economicProfile: economicProfile,
      })
  );

  const [districtHeatingLabels, setDistrictHeatingLabels] =
    useState<Parameter>();
  const [groundSourceHeatPumpLabels, setGroundSourceHeatPumpLabels] =
    useState<Parameter>();
  const [airSourceHeatPumpLabels, setAirSourceHeatPumpLabels] =
    useState<Parameter>();
  const [pelletLabels, setPelletLabels] = useState<Parameter>();
  const [buildingLabels, setBuildingLabels] = useState<Parameter>();

  const heatingTechnologies = (toPrint: boolean) => [
    {
      id: 'DistrictHeating',
      title: districtHeatingLabels?.displayName || 'Fjärrvärme',
      component: (
        <DistrictHeating
          description={districtHeatingLabels?.description}
          parameters={outputParameters}
          includeVat={includeVat}
          onValidParameterChange={onValidParameterChange}
          printTitle={districtHeatingLabels?.displayName || 'Fjärrvärme'}
          toPrint={toPrint}
        />
      ),
    },
    {
      id: 'GroundSource',
      title:
        groundSourceHeatPumpLabels?.displayName || 'Bergvärme (med elspets)',
      component: (
        <GroundSourceHeatPump
          description={groundSourceHeatPumpLabels?.description}
          parameters={outputParameters}
          includeVat={includeVat}
          onValidParameterChange={onValidParameterChange}
          printTitle={
            groundSourceHeatPumpLabels?.displayName || 'Bergvärme (med elspets)'
          }
          toPrint={toPrint}
        />
      ),
    },
    {
      id: 'AirSource',
      title:
        airSourceHeatPumpLabels?.displayName ||
        'Luft/vatten-värmepump (med elspets)',
      component: (
        <AirSourceHeatPump
          toPrint={toPrint}
          description={airSourceHeatPumpLabels?.description}
          parameters={outputParameters}
          includeVat={includeVat}
          printTitle={
            airSourceHeatPumpLabels?.displayName ||
            'Luft/vatten-värmepump (med elspets)'
          }
          onValidParameterChange={onValidParameterChange}
        />
      ),
    },
    {
      id: 'Pellet',
      title: pelletLabels?.displayName || 'Pellets',
      component: (
        <Pellet
          toPrint={toPrint}
          description={pelletLabels?.description}
          parameters={outputParameters}
          includeVat={includeVat}
          printTitle={pelletLabels?.displayName || 'Pellets'}
          onValidParameterChange={onValidParameterChange}
        />
      ),
    },
  ];

  const sections = (toPrint: boolean) => [
    {
      id: 'Common',
      title: 'Övergripande',
      component: (
        <CommonSection
          toPrint={toPrint}
          parameters={outputParameters}
          includeVat={includeVat}
          printTitle={'Övergripande'}
          onValidParameterChange={onValidParameterChange}
        />
      ),
    },
    {
      id: 'Electricity',
      title: buildingLabels?.displayName || 'Fastighetsel',
      component: (
        <BuildingElectricity
          toPrint={toPrint}
          description={buildingLabels?.description}
          parameters={outputParameters}
          includeVat={includeVat}
          printTitle={buildingLabels?.displayName || 'Fastighetsel'}
          onValidParameterChange={onValidParameterChange}
        />
      ),
    },
    ...heatingTechnologies(toPrint),
  ];

  const [activeTab, setActiveTab] = useState('Common');
  const handleTabSelection = (
    event: React.SyntheticEvent,
    selectedTab: string
  ) => {
    setActiveTab(selectedTab);
  };

  const dispatch = useAppDispatch();

  function onValidParameterChange(id: string, value: string) {
    if (wizardStepInputs.some(parameter => parameter.id === id)) {
      let parameter = outputParameters.find(parameter => parameter.id === id);

      if (parameter !== null && parameter !== undefined) {
        saveWizardParameter(
          id,
          value,
          getDisplayValue(
            value,
            includeVat,
            getType(parameter),
            parameter?.stylingConfig,
            true
          ) ?? value
        );
      }
    } else {
      saveModuleParameter(id, value);
    }
  }

  function saveWizardParameter(
    id: string,
    value: string,
    formattedValue: string
  ) {
    dispatch(
      setWizardUserInput({
        id: id,
        value: value,
        displayValue: formattedValue,
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
  }

  function saveModuleParameter(id: string, value: string) {
    dispatch(
      setModuleUserInput({
        id: id,
        value: value,
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
  }

  const getWizardStepsDelayed = useCallback(
    debounce((callback: (steps: WizardStep[]) => void) => {
      getWizardSteps(customer, economicProfile).then(steps => {
        callback(steps);
      });
    }, 600),
    [customer, economicProfile]
  );

  useEffect(() => {
    getWizardStepsDelayed(wizardSteps => {
      if (allStepsAreCompleted(wizardStepInputs, wizardSteps)) {
        let inputs = wizardStepInputs
          .map(
            step => ({ id: step.id, value: step.value!.id } as ParameterValue)
          )
          .concat(moduleParameterInputs);
        calculateOutput(moduleId, inputs, customer, economicProfile)
          .then(parameters => {
            setOutputParameters(parameters);
          })
          .catch(() => addError('Error'));
      }
    });
  }, [
    wizardStepInputs,
    moduleParameterInputs,
    customer,
    economicProfile,
    addError,
    getWizardStepsDelayed,
  ]);

  useEffect(() => {
    let districtValue = getUserInputForStepId(
      'DistrictSelected',
      wizardStepInputs
    )?.id;
    let buildingValue = getUserInputForStepId(
      'BuildingProfile',
      wizardStepInputs
    )?.id;
    const district = parseJsonToObject(districtValue)?.value;
    const building = parseJsonToObject(buildingValue)?.value;
    if (district !== undefined && building !== undefined) {
      getLabel('DistrictHeatingLccTab', customer, district, building, economicProfile).then(
        response => {
          setDistrictHeatingLabels(response);
        }
      );
      getLabel('GroundSourceHeatPumpLccTab', customer, district, building, economicProfile).then(
        response => {
          setGroundSourceHeatPumpLabels(response);
        }
      );
      getLabel('AirSourceHeatPumpLccTab', customer, district, building, economicProfile).then(
        response => {
          setAirSourceHeatPumpLabels(response);
        }
      );
      getLabel('PelletLccTab', customer, district, building, economicProfile).then(response => {
        setPelletLabels(response);
      });
      getLabel('BuildingElectricityLccTab', customer, district, building, economicProfile).then(
        response => {
          setBuildingLabels(response);
        }
      );
    }
  }, [customer, wizardStepInputs]);

  function resetInput() {
    dispatch(
      resetWizardUserInput({
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
    dispatch(
      resetModuleUserInput({
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
  }

  const classes = useStyles(theme);

  const [isSmallGraphVisible, setIsSmallGraphVisible] =
    useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const setScroll = () => {
    let bounds = scrollRef?.current?.getBoundingClientRect();

    setIsSmallGraphVisible(
      bounds?.y !== undefined &&
        bounds?.y !== null &&
        bounds?.height > 0 &&
        bounds?.y < -(bounds.height * 0.2) // show small graph when at least 20% of the big one is hidden
    );
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', setScroll, true);
    return () => {
      window.removeEventListener('scroll', setScroll);
    };
  }, [scrollRef]);

  const onPrint = () => {
    setToPrint(true);
    setTimeout(function () {
      handlePrint();
    }, 1000);
    setTimeout(function () {
      setToPrint(false);
    }, 1100);
  };

  return (
    <Box key={`${moduleId}-module-container`} sx={params.sx}>
      <Paper
        className={classes.card}
        elevation={paperElevation}
        sx={{
          marginBottom: '40px',
          paddingLeft: 4,
          paddingRight: 4,
          paddingBottom: 4,
          textAlign: 'center',
        }}>
        <TextField
          id="printed-title"
          label="Dokumentbeskrivning"
          sx={{
            width: '400px',
            maxWidth: '100%',
            paddingRight: 4,
          }}
          placeholder="Här kan du namnge din beräkning"
          onChange={e => setLabelTitle(e.target.value)}
          value={labelTitle}
          variant="standard"
          type="text"
        />
        <Button
          variant="contained"
          startIcon={<PrintIcon />}
          onClick={() => onPrint()}
          sx={{ verticalAlign: 'bottom', marginTop: 1 }}>
          Skriv ut
        </Button>
      </Paper>

      <Typography variant="h1">{moduleInfo?.displayName}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.card} elevation={paperElevation}>
            <Typography paragraph>
              Nedan presenteras ett diagram över livscykelkostnaderna för
              uppvärmning. Under detta diagram finns det möjlighet att göra mer
              detaljerade inställningar. Har du till exempel offertuppgifter för
              en värmepump kan dessa användas. Om man ändrar något värde
              markeras det med symbolen och klickar man på denna symbol så
              återfår man urspungsvärdet. Information om olika parametrar fås
              genom att klicka på för aktuell parameter.
            </Typography>
          </Paper>
        </Grid>
        <Grid ref={scrollRef} item xs={12}>
          <Typography variant="h2">
            Livscykelkostnad (LCC) för uppvärmningsalternativen
          </Typography>
          <Paper className={classes.card} elevation={paperElevation}>
            <LifecycleChart
              toPrint={false}
              heatingTechnologies={heatingTechnologies(false)}
              parameters={outputParameters}
              displayLegends
              includeVat={includeVat}
            />
            <Typography paragraph>
              I diagrammet ovan framgår uppvärmningskostnaden för de olika
              alternativen uppdelat på ett antal olika poster. Du kan släcka och
              tända olika kostnadsposter genom att klicka på förklaringstexterna
              för respektive kostnadspost under grafen.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={deviatesFromDefaults}>
            <Alert
              severity="info"
              action={
                <Button color="inherit" size="small" onClick={resetInput}>
                  Återställ till standardvärden
                </Button>
              }>
              <AlertTitle>
                Beräkningarna bygger på manuellt justerade värden.
              </AlertTitle>
            </Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            onChange={handleTabSelection}
            variant="scrollable"
            scrollButtons={true}>
            {sections(false).map(heatingTechnology => (
              <Tab
                key={`${moduleId}-module-${heatingTechnology.id}`}
                value={heatingTechnology.id}
                label={heatingTechnology.title}
                wrapped
              />
            ))}
          </Tabs>
          {
            sections(false).find(
              heatingTechnology => activeTab === heatingTechnology.id
            )?.component
          }
        </Grid>
      </Grid>
      <div
        className={isSmallGraphVisible ? 'smallGraph' : 'smallGraphInvisible'}>
        <LifecycleChart
          toPrint={false}
          heatingTechnologies={heatingTechnologies(false)}
          parameters={outputParameters}
          includeVat={includeVat}
          displayLegends={false}
        />
      </div>
      {toPrint && (
        <div ref={componentRef} id="toPrint">
          <h1>
            {labelTitle} {new Date().toLocaleString()}
          </h1>
          <Typography variant="h2">Livscykelkostnad uppvärmning</Typography>
          <LifecycleChart
            heatingTechnologies={heatingTechnologies(false)}
            parameters={outputParameters}
            displayLegends
            includeVat={includeVat}
            toPrint={true}
          />
          <Typography paragraph>
            I diagrammet ovan framgår uppvärmningskostnaden för de olika
            alternativen uppdelat på ett antal olika poster. Du kan släcka och
            tända olika kostnadsposter genom att klicka på förklaringstexterna
            för respektive kostnadspost under grafen.
          </Typography>
          {sections(true).map((section, i) => (
            <div className="section">{section.component}</div>
          ))}
          <div></div>
        </div>
      )}
    </Box>
  );
}
