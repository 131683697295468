import Box from "@mui/material/Box";
import {
  CircularProgress,
  debounce,
  Paper,
  SxProps,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../common/store/store";
import {
  getStepStates,
  setUserInput as setWizardUserInput,
} from "../../../common/store/wizard-slice";
import { getWizardSteps } from "../../../api/wizard-api";
import {
  allStepsAreCompleted,
  getRawUserInputForStepId,
  parseJsonToObject,
} from "../../../components/wizard/util";
import {
  Alternatives,
  Parameter,
  ParameterType,
  ParameterValue,
} from "../../../common/types/parameters";
import { calculateOutput } from "../../../api/module-api";
import { WizardStep } from "../../../common/types/wizard";
import {
  getParameterStates,
  setUserInput as setModuleUserInput,
  resetUserInputsWithId as resetModuleUserInputsWithId,
} from "../../../common/store/module-parameters-slice";
import {
  getDisplayValue,
  getType,
} from "../../../components/data-presentation/util";
import useAPIError from "../../../common/hooks/useAPIError";
import AngularKlimatModule from "./klimat-angular";
import ParameterSection from "../parameter-section";
import theme from "../../../components/theme/theme";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: "15px",
    },
  })
);

const calcModuleId = "klimat";

export default function OversiktligaResultat(params: {
  forceParameterReload?: boolean;
  onReload?: () => void;
  sx?: SxProps;
}) {
  const classes = useStyles(theme);

  const { customer, economicProfile } = useParams();
  const { addError } = useAPIError();

  let wizardStepInputs = useAppSelector((state) =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  let moduleParameterInputs = useAppSelector((state) =>
    getParameterStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  const [outputParameters, setOutputParameters] = useState<Parameter[]>([]);

  const dispatch = useAppDispatch();

  function onValidParameterChange(id: string, value: string) {
    if (wizardStepInputs.some((parameter) => parameter.id === id)) {
      let parameter = outputParameters.find((parameter) => parameter.id === id);

      if (parameter !== null && parameter !== undefined) {
        saveWizardParameter(
          id,
          value,
          getDisplayValue(
            value,
            false,
            getType(parameter),
            parameter?.stylingConfig,
            true
          ) ?? value
        );
      }
    } else {
      saveModuleParameter(id, value);
    }
  }

  function saveWizardParameter(
    id: string,
    value: string,
    formattedValue: string
  ) {
    dispatch(
      setWizardUserInput({
        id: id,
        value: value,
        displayValue: formattedValue,
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
  }

  function saveModuleParameter(id: string, value: string) {
    dispatch(
      setModuleUserInput({
        id: id,
        value: value,
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );
  }

  const getWizardStepsDelayed = useCallback(
    debounce((callback: (steps: WizardStep[]) => void) => {
      getWizardSteps(customer, economicProfile).then((steps) => {
        callback(steps);
      });
    }, 600),
    [customer, economicProfile]
  );

  useEffect(() => {
    getWizardStepsDelayed((wizardSteps) => {
      if (
        allStepsAreCompleted(wizardStepInputs, wizardSteps) &&
        !params.forceParameterReload
      ) {
        // If no parameter reload is necessary and all inputs are available, use the existing input to calculate the result
        let inputs = wizardStepInputs
          .map(
            (step) => ({ id: step.id, value: step.value!.id } as ParameterValue)
          )
          .concat(moduleParameterInputs);
        calculateOutput(calcModuleId, inputs, customer, economicProfile)
          .then((parameters) => {
            setOutputParameters(parameters);
          })
          .catch(() => addError("Error"));
      }
    });
  }, [
    addError,
    customer,
    getWizardStepsDelayed,
    moduleParameterInputs,
    params.forceParameterReload,
    wizardStepInputs,
  ]);

  useEffect(() => {
    if (params.forceParameterReload) {
      // Check if parameters should be reloaded
      let paramsToReset = outputParameters
        .filter((parameter) =>
          isSelectionParameterWithUnmodifiableAlternatives(parameter)
        )
        .map((parameter) => parameter.id);

      if (params.onReload !== undefined && params.onReload !== null) {
        params.onReload();
      }

      dispatch(
        resetModuleUserInputsWithId({
          ids: paramsToReset,
          profile: { customer: customer, economicProfile: economicProfile },
        })
      );
    }
  }, [customer, dispatch, economicProfile, outputParameters, params]);

  let district = parseJsonToObject(
    getRawUserInputForStepId("DistrictSelected", wizardStepInputs)
  )?.value;
  let building = parseJsonToObject(
    getRawUserInputForStepId("BuildingProfile", wizardStepInputs)
  )?.value;

  return outputParameters.length > 0 ? (
    <>
      <Typography variant="h1">Klimatmodulen</Typography>
      <Paper className={classes.card} elevation={3}>
        <Typography paragraph>
          Här beräknas utsläppen av klimatgaser för olika uppvärmningsalternativ
          för fastigheten. Värmebehovet kan justeras nedan.
        </Typography>
        <Typography paragraph>
          Beräkningen tar hänsyn till samtliga utsläppskonsekvenser som uppstår
          i energisystemet baserat på hur energisystemet ser ut under
          uppvärmningsalternativens livslängd, dvs de kommande 20 åren. Läs mer
          under "Om beräkningen".
        </Typography>
        <Typography paragraph>
          Klicka på staplarna för respektive uppvärmningsalternativ för att se
          mer detaljer kring hur utsläppen beräknas. I diagrammet längst ner går
          det också att klicka på staplarna för att läsa mer om olika bränslen
          som används i värmeproduktionen.
        </Typography>
      </Paper>
      <ParameterSection
        parameters={outputParameters}
        columns={[
          { sections: [{ title: "", parameters: ["AnnualHeatingDemand"] }] },
        ]}
        includeVat={false} // Not needed for non-currency parameters
        onValidParameterChange={onValidParameterChange}
        sx={{ minHeight: null, mt: 4, textAlign: "center" }}
      />
      <Box sx={{ pb: 7, ...params.sx }}>
        <AngularKlimatModule
          customer={customer}
          annualHeatingDemand={
            outputParameters.find((param) => param.id === "AnnualHeatingDemand")
              ?.value
          }
          pelletBoilerEfficiency={
            outputParameters.find(
              (param) => param.id === "PelletBoilerEfficiency"
            )?.value
          }
          airSourceHeatPumpEfficiency={
            outputParameters.find(
              (param) => param.id === "AirSourceHeatPumpEfficiency"
            )?.value
          }
          groundSourceHeatPumpEfficiency={
            outputParameters.find(
              (param) => param.id === "GroundSourceHeatPumpEfficiency"
            )?.value
          }
          district={district}
          building={building}
          economicProfile={economicProfile}
        />
      </Box>
    </>
  ) : (
    <Box sx={{ textAlign: "center", ...params.sx }}>
      <CircularProgress />
    </Box>
  );
}

function isSelectionParameterWithUnmodifiableAlternatives(
  parameter: Parameter
): boolean {
  return (
    getType(parameter) === ParameterType.Selection &&
    (parameter.constraints as Alternatives) &&
    (parameter.constraints as Alternatives)
      .flatMap((alternativeList) => alternativeList)
      .some((alternative) => !alternative.editable)
  );
}
