import { SxProps } from "@mui/material";
import { Parameter } from "../../../common/types/parameters";
import { Column } from "../types";
import ParameterSection from "../parameter-section";

export default function Pellet(params: {
  description?: string;
  printTitle: string;
  parameters: Parameter[];
  includeVat: boolean;
  toPrint: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
}) {
  return (
    <ParameterSection
      printTitle={params.printTitle}
      description={params.description}
      parameters={params.parameters}
      columns={columns}
      toPrint={params.toPrint}
      includeVat={params.includeVat}
      onValidParameterChange={params.onValidParameterChange}
      sx={params.sx}
    />
  );
}

const columns: Column[] = [
  {
    sections: [
      {
        title: "Årliga kostnader",
        parameters: [
          "PelletCapitalCost",
          "PelletMaintenanceCost",
          "PelletEnergyCost",
        ],
      },
      {
        title: "",
        parameters: ["PelletTotalSpecificCost"],
      },
    ],
    readOnly: true,
  },
  {
    sections: [
      {
        title: "Detaljerade indata",
        parameters: ["PelletDesignLoad", "PelletBoilerEfficiency"],
      },
      {
        title: "Investeringskostnad",
        parameters: ["PelletBoilerCost", "PelletBoilerLifeSpan"],
      },
      {
        title: "Energikostnad",
        parameters: ["PelletPricePerTon", "PelletEnergyPriceVariable"],
      },
      {
        title: "Drift- och underhållskostnader",
        parameters: ["PelletMaintenanceCost"],
      },
    ],
  },
];
