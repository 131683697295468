import { SxProps } from '@mui/material';
import { Parameter } from '../../../common/types/parameters';
import { Column } from '../types';
import ParameterSection from '../parameter-section';

export default function DistrictHeating(params: {
  description?: string;
  printTitle: string;
  parameters: Parameter[];
  includeVat: boolean;
  toPrint: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
}) {
  return (
    <ParameterSection
      printTitle={params.printTitle}
      description={params.description}
      parameters={params.parameters}
      columns={columns}
      includeVat={params.includeVat}
      toPrint={params.toPrint}
      onValidParameterChange={params.onValidParameterChange}
      sx={params.sx}
    />
  );
}

const columns: Column[] = [
  {
    sections: [
      {
        title: 'Årliga kostnader',
        parameters: [
          'DistrictHeatingCapitalCost',
          'DistrictHeatingMaintenanceCost',
          'DistrictHeatingEnergyCost',
          'DistrictHeatingLoadCost',
          'DistrictHeatingFlowCost',
        ],
      },
      {
        title: '',
        parameters: ['DistrictHeatingTotalSpecificCost'],
      },
    ],
    readOnly: true,
  },
  {
    sections: [
      {
        title: 'Detaljerade indata',
        parameters: [
          'DistrictHeatingLoadDemand',
          'DistrictHeatingBaseLoadFactor',
          'DistrictHeatingBaseLoadPercentage',
          'DistrictHeatingDesignLoad',
        ],
      },
      {
        title: 'Säsongsfördelning',
        parameters: [
          'DistrictHeatingEnergyDemandFractionJanuary',
          'DistrictHeatingEnergyDemandFractionFebruary',
          'DistrictHeatingEnergyDemandFractionMarch',
          'DistrictHeatingEnergyDemandFractionApril',
          'DistrictHeatingEnergyDemandFractionMay',
          'DistrictHeatingEnergyDemandFractionJune',
          'DistrictHeatingEnergyDemandFractionJuly',
          'DistrictHeatingEnergyDemandFractionAugust',
          'DistrictHeatingEnergyDemandFractionSeptember',
          'DistrictHeatingEnergyDemandFractionOctober',
          'DistrictHeatingEnergyDemandFractionNovember',
          'DistrictHeatingEnergyDemandFractionDecember',
          'DistrictHeatingMaxEnergyDemandFractionJanuary',
          'DistrictHeatingMaxEnergyDemandFractionFebruary',
          'DistrictHeatingMaxEnergyDemandFractionMarch',
          'DistrictHeatingMaxEnergyDemandFractionApril',
          'DistrictHeatingMaxEnergyDemandFractionMay',
          'DistrictHeatingMaxEnergyDemandFractionJune',
          'DistrictHeatingMaxEnergyDemandFractionJuly',
          'DistrictHeatingMaxEnergyDemandFractionAugust',
          'DistrictHeatingMaxEnergyDemandFractionSeptember',
          'DistrictHeatingMaxEnergyDemandFractionOctober',
          'DistrictHeatingMaxEnergyDemandFractionNovember',
          'DistrictHeatingMaxEnergyDemandFractionDecember',
        ],
      },
      {
        title: 'Flöde',
        parameters: [
          'DistrictHeatingFlowVolume',
          'DistrictHeatingDeltaT',
          'DistrictHeatingReturnTemperature',
        ],
      },
      {
        title: 'Investeringskostnad',
        parameters: [
          'DistrictHeatingCentralCost',
          'DistrictHeatingCentralLifeSpan',
          'DistrictHeatingConnectionCost',
          'DistrictHeatingConnectionLifeSpan',
        ],
        conditions: [{ parameter: 'DistrictHeatingInstalled', value: false }],
      },
      {
        title: 'Drift- och underhållskostnader',
        parameters: ['DistrictHeatingMaintenanceCost'],
      },
    ],
  },
];
