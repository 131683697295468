import { scaleToUnit } from "../../../components/data-presentation/util";

export default function KlimatAngular(params) {
  return (
    <div>
      <facility-component
        customer={params.customer}
        demand={scaleToUnit(params.annualHeatingDemand, "MWh", false)}
        air={params.airSourceHeatPumpEfficiency}
        pellets={params.pelletBoilerEfficiency}
        gh={params.groundSourceHeatPumpEfficiency}
        district={params.district}
        building={params.building}
        economic={params.economicProfile}
      />
    </div>
  );
}
