import { SxProps } from "@mui/material";
import { Parameter } from "../../../common/types/parameters";
import { Column } from "../types";
import ParameterSection from "../parameter-section";

export default function GroundSourceHeatPump(params: {
  description?: string;
  printTitle: string;
  parameters: Parameter[];
  includeVat: boolean;
  toPrint: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
}) {
  return (
    <ParameterSection
      printTitle={params.printTitle}
      toPrint={params.toPrint}
      description={params.description}
      parameters={params.parameters}
      columns={columns}
      includeVat={params.includeVat}
      onValidParameterChange={params.onValidParameterChange}
      sx={params.sx}
    />
  );
}

const columns: Column[] = [
  {
    sections: [
      {
        title: "Årliga kostnader",
        parameters: [
          "GroundSourceCapitalCost",
          "GroundSourceHeatPumpMaintenanceCost",
          "GroundSourceEnergyCost",
          "GroundSourceIncreasedGridCost",
        ],
      },
      {
        title: "",
        parameters: ["GroundSourceTotalSpecificCost"],
      },
      {
        title: "Beräknade värden för nytt elabonnemang",
        parameters: [
          "GroundSourceAndBuildingElectricityLoad",
          "GroundSourceFuseDemand",
        ],
      },
    ],
    readOnly: true,
  },
  {
    sections: [
      {
        title: "",
        parameters: [
          "GridFusePriceFixed",
          "GridFusePriceVariableLow",
          "GridFusePriceVariableHigh",
        ],
      },
      {
        title: "",
        parameters: [
          "GridPowerPriceFixed",
          "GridPowerPriceLoadLow",
          "GridPowerPriceLoadHigh",
          "GridPowerPriceVariableHigh",
          "GridPowerPriceVariableLow",
        ],
      },
      {
        title: "Detaljerade indata",
        parameters: [
          "GroundSourceHeatingDesignLoad",
          "GroundSourceHeatPumpLoadCoverage",
          "GroundSourceHeatPumpEnergyCoverage",
          "GroundSourceHeatPumpDeliveredHeatingLoad",
          "GroundSourceRemainingHeatingLoad",
          "GroundSourceHeatPumpSCOPRadiator",
          "GroundSourceHeatPumpSCOPFloor",
        ],
      },
      {
        title: "Investeringskostnad",
        parameters: [
          "GroundSourceEnergyWellCost",
          "GroundSourceEnergyWellLifeSpan",
          "GroundSourceHeatPumpCost",
          "GroundSourceHeatPumpLifeSpan",
          "GroundSourceMiscellaneousCost",
          "GroundSourceMiscellaneousLifeSpan",
        ],
      },
      {
        title: "Drift- och underhållskostnader",
        parameters: ["GroundSourceHeatPumpMaintenanceCost"],
      },
    ],
  },
];
