import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface WizardState {
  meta: Profile;
  currentStep?: string;
  steps: WizardStepState[];
}

export interface Profile {
  customer?: string;
  economicProfile?: string;
}

export interface WizardStepValue {
  id: string;
  display: string;
}

export interface WizardStepState {
  id: string;
  value?: WizardStepValue;
  defaultValue?: WizardStepValue;
  completed: boolean;
}

function loadStoredState(): WizardState {
  let storedValue = sessionStorage.getItem("wizardStepsState");
  if (storedValue !== null) {
    return JSON.parse(
      sessionStorage.getItem("wizardStepsState") as string
    ) as WizardState;
  } else {
    return { meta: {}, steps: [] };
  }
}

function getOrCreateStepState(state: WizardState, id: string): WizardStepState {
  if (!state.steps.some((step) => step.id === id)) {
    let stepState = {
      id: id,
      value: undefined,
      completed: false,
    };
    state.steps.push(stepState);
    return stepState;
  } else {
    return getStepState(state, id) as WizardStepState;
  }
}

function getStepState(
  state: WizardState,
  id: string
): WizardStepState | undefined {
  return state.steps.find((stepState) => stepState.id === id);
}

function prepareStateForProfile(state: WizardState, meta: Profile) {
  if (
    state.meta?.customer !== meta.customer ||
    state.meta?.economicProfile !== meta.economicProfile
  ) {
    state.currentStep = undefined;
    state.steps = [];
    state.meta = meta;
  }
  return state;
}

function getStateForProfile(state: WizardState, meta: Profile): WizardState {
  if (
    state.meta?.customer !== meta.customer ||
    state.meta?.economicProfile !== meta.economicProfile
  ) {
    return { meta: meta, steps: [] };
  }
  return state;
}

const initialState: WizardState = loadStoredState();

const wizardSlice = createSlice({
  name: "wizard",
  initialState: initialState,
  reducers: {
    addStep: (
      state: WizardState,
      action: PayloadAction<{
        id: string;
        defaultValue?: string;
        defaultDisplayValue?: string;
        profile: Profile;
      }>
    ) => {
      let wizardState = prepareStateForProfile(state, action.payload.profile);
      let storedStep = getOrCreateStepState(wizardState, action.payload.id);

      if (
        // Only update store if a default value hasn't been added earlier,
        // or if the step has not been completed,
        // or if the user has used the default value
        hasValue(action.payload.defaultValue) &&
        storedStep?.defaultValue?.id !== action.payload.defaultValue &&
        (storedStep.defaultValue === undefined ||
          !hasValue(storedStep.value?.id) ||
          storedStep.value?.id === storedStep.defaultValue.id)
      ) {
        const newDefaultValue = {
          id: action.payload.defaultValue,
          display:
            action.payload.defaultDisplayValue ?? action.payload.defaultValue,
        } as WizardStepValue;

        // If the user has used the default value, update it as well
        if (
          hasValue(storedStep.value?.id) &&
          storedStep.value?.id === storedStep.defaultValue?.id
        ) {
          storedStep.value = { ...newDefaultValue };
        }

        storedStep.defaultValue = newDefaultValue;
      }

      sessionStorage.setItem("wizardStepsState", JSON.stringify(wizardState));
    },
    setCurrentStep: (
      state: WizardState,
      action: PayloadAction<{ step: string; profile: Profile }>
    ) => {
      let wizardState = prepareStateForProfile(state, action.payload.profile);
      wizardState.currentStep = action.payload.step;
    },
    setUserInput: (
      state: WizardState,
      action: PayloadAction<{
        id: string;
        value: string;
        displayValue?: string;
        profile: Profile;
      }>
    ) => {
      let wizardState = prepareStateForProfile(state, action.payload.profile);
      let stepState = getOrCreateStepState(wizardState, action.payload.id);
      stepState.value = {
        id: action.payload.value,
        display: action.payload.displayValue ? action.payload.displayValue : "",
      };
      stepState.completed = true;
      sessionStorage.setItem("wizardStepsState", JSON.stringify(wizardState));
    },
    resetUserInputs: (
      state: WizardState,
      action: PayloadAction<{
        profile: Profile;
      }>
    ) => {
      let wizardState = prepareStateForProfile(state, action.payload.profile);
      wizardState.steps.forEach((step) => {
        if (step.completed && step.defaultValue !== undefined) {
          step.value = {
            id: step.defaultValue.id,
            display: step.defaultValue.display,
          };
        }
      });
      sessionStorage.setItem("wizardStepsState", JSON.stringify(wizardState));
    },
  },
});

function hasValue(value: string | undefined) {
  return value !== undefined && value !== null;
}

export const getCurrentStep = (state: RootState, profile: Profile) => {
  let wizardState = getStateForProfile(state.wizard, profile);
  return wizardState.currentStep;
};
export const isCompleted = (state: RootState, id: string, profile: Profile) => {
  let wizardState = getStateForProfile(state.wizard, profile);
  return getStepState(wizardState, id)?.completed;
};
export const isWizardCompleted = (
  state: RootState,
  numWizardSteps: number,
  profile: Profile
) => {
  let wizardStepStates = getStateForProfile(state.wizard, profile).steps;
  return (
    wizardStepStates.length > 0 &&
    wizardStepStates.length === numWizardSteps &&
    wizardStepStates.every((step) => step.completed)
  );
};
export const getUserInput = (
  state: RootState,
  id: string,
  profile: Profile
) => {
  let wizardState = getStateForProfile(state.wizard, profile);
  return getStepState(wizardState, id)?.value;
};
export const getStepStates = (state: RootState, profile: Profile) => {
  let wizardState = getStateForProfile(state.wizard, profile);
  return wizardState.steps;
};
export const deviatesFromDefaults = (state: RootState, profile: Profile) => {
  return getStateForProfile(state.wizard, profile).steps.some(
    (stepState) =>
      stepState.defaultValue !== undefined &&
      stepState.value?.id !== undefined &&
      stepState.defaultValue.id !== stepState.value.id
  );
};

export const { setCurrentStep, setUserInput, addStep, resetUserInputs } =
  wizardSlice.actions;

export default wizardSlice.reducer;
