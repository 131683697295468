import Typography from "@mui/material/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import theme from "../../../components/theme/theme";
import useCollapse from "react-collapsed";

const useStyles = makeStyles({
  Quote: {
    paddingLeft: "3em",
    fontStyle: "italic",
  },
  Question: {
    fontWeight: "bold!important",
  },
});

export default function ClimateModuleInfo() {
  const classes = useStyles(theme);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <>
      <Typography variant="h1">Så här görs beräkningen</Typography>
      <Typography paragraph>
        De klimatberäkningar som genomförs i fjärrkontrollen utgår från den så
        kallade ”Konsekvensprincipen”. Den fråga som besvaras är: Vad blir den
        resulterande klimatpåverkan från mitt val av uppvärmningsteknik för min
        fastighet. Med andra ord studeras <b>konsekvensen</b> på nettoutsläppen
        av växthusgaser från ett beslut som jag fattar.
      </Typography>
      <Typography paragraph>
        Grunderna för konsekvensprincipen är att, så långt som det är möjligt
        och rimligt, studera ett besluts totala klimatpåverkan i samhället genom
        att följa alla konsekvenser som beslutet ger upphov till. När du som
        fastighetsägare väljer fjärrvärme eller värmepump som uppvärmningsform
        sker inga utsläpp i din fastighet. Ändå påverkas utsläppen av
        växthusgaser av det val du gör. Det sker genom att produktionen av
        fjärrvärme eller el i sig ger upphov till utsläpp. För att göra en
        korrekt bedömning av hur ditt val påverkar utsläppen behöver dessa
        utsläpp inkluderas i beräkningen.
        <Button variant="text" {...getToggleProps()}>
          {isExpanded ? "Dölj information" : "Läs mer"}
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </Typography>
      <Box {...getCollapseProps()}>
        <Typography paragraph>
          Ett val kring uppvärmningsteknik i din fastighet ses här som ett
          långsiktigt beslut. Det innebär att beslutet kommer att ge en påverkan
          under en längre period, här har denna period satts till 20 år.
          Beräkningen grundar sig därför på hur utsläppen av klimatgaser
          påverkas under hela denna period.
        </Typography>
        <Typography paragraph>
          I resultaten presenteras direkta och indirekta utsläpp. De direkta
          utsläppen motsvaras av de som sker i din fastighet (för fjärrvärme
          redovisas fjärrvärmeföretagets direkta utsläpp). De indirekta
          utsläppen sker utanför din eller fjärrvärmeföretagets verksamhet.
          Dessa kommer typiskt från el som producerats någon annanstans, eller
          från framställning och transport av bränslen som används för
          värmeproduktionen. De indirekta utsläppen kan också vara negativa, dvs
          att konsekvensen av ditt beslut kan ge ett minskat utsläpp av
          klimatgaser. Detta gäller exempelvis om din användning av fjärrvärme
          leder till att mer förnybar el produceras. Många fjärrvärmesystem har
          så kallade kraftvärmeanläggningar, anläggningar som producerar både el
          och värme. En fjärrvärmekund i ett sådant fjärrvärmesystem bidrar tack
          vare fjärrvärmen till att även el produceras vilket i sin tur leder
          till att annan, ofta sämre, elproduktion kan ersättas i kraftsystemet.
          Motsvarande indirekta klimatnytta får man om din fjärrvärme medför att
          fjärrvärmeföretaget använder en något större mängd restavfall i sin
          energiåtervinningsanläggning och om detta medför att mindre avfall
          hamnar på deponier. Att deponera avfall ger tydliga utsläpp av
          metangas och därmed ett tydligt bidrag till klimatpåverkan.
        </Typography>
        <Typography paragraph>
          Hur man ska räkna på klimatpåverkan vid ett beslutstillfälle har
          diskuterats flitigt inom forskarvärlden. Det finns många frågor kring
          klimatpåverkan som forskarna inte är helt eniga om men kring just
          denna fråga då man står inför ett beslut är man enig om att man ska
          tillämpa konsekvensprincipen (ibland även kallad systemperspektivet).
          Det är i grunden samma princip som återfinns i
          miljökonsekvens­beredningar (MKB) som företag måste redovisa när de
          ansöker om miljötillstånd för större förändringar. Inom
          energibranschen finns det dock flera andra mer förenklade
          miljöbetraktelser som ofta används. En vanlig sådan metod benämns
          ”bokföringsperspektivet”. Denna princip kan inte användas som grund
          inför ett beslut även om detta ofta tyvärr sker. Vill man läsa mer om
          olika miljövärderingsprinciper så kan man söka vidare på
          ”konsekvensprincipen” eller ”Consequential LCA”. Tre olika ingångar
          till dessa metoddiskussioner ges i referenserna:
        </Typography>
        <Typography paragraph className={`${classes.Quote}`}>
          The Greenhouse Gas Protocol - The GHG Protocol for Project Accounting,
          World Business Council for Sustainable Development, World Resources
          Institute, 2003.
        </Typography>
        <Typography paragraph className={`${classes.Quote}`}>
          Robust LCA: Typologi över LCA-metodik – två kompletterande
          systemsyner, rapport B2122, IVL Svenska Miljöinstitutet, januari 2014.
        </Typography>
        <Typography paragraph className={`${classes.Quote}`}>
          Klimatbokslut – Fördjupning. En fördjupad beskrivning av metoden,
          avgränsningar och antaganden för ”Klimatbokslut för Energiföretag”,
          version 2.4, Profu, maj 2020.
        </Typography>
      </Box>

      <Typography variant="h1">Frågor och svar</Typography>
      <Box sx={{ mt: 2, pb: 7 }}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography className={`${classes.Question}`}>
              Hur är det möjligt att utsläppen blir negativa?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              För uppvärmningsalternativet fjärrvärme så kan klimatvärdet ibland
              vara negativt. Detta innebär att ju mer fjärrvärme som produceras
              desto lägre blir de totala utsläppen. Man behöver med andra ord
              inte ens använda fjärrvärmen för att de totala utsläppen ska
              minska. Detta kan vid en första anblick upplevas som konstigt
              eftersom vi vet att all energiproduktion ger upphov till utsläpp.
            </Typography>
            <Typography paragraph>
              Om klimatvärdet för fjärrvärmen är negativt innebär detta att det
              finns andra indirekta klimatnyttor som fjärrvärmeproduktionen ger
              upphov till och att dessa finns där tack vare fjärrvärmekunderna.
              Vid negativa klimatvärden är dessa nyttor större än de tillförda
              utsläpp som uppstår från fjärrvärmeproduktionen. Det finns olika
              indirekta nyttor men den nytta som tydligast påverkar klimatvärdet
              är samtidig produktion av el och värme i kraftvärmeanläggningar.
              En fjärrvärmekund som använder fjärrvärme från exempelvis ett
              biobränsleeldat kraftvärmeverk bidrar även till förnyelsebar
              elproduktion. Ibland (dock mindre vanligt) är elproduktionen inte
              helt kopplad till värmeproduktionen. I dessa fall får fjärrvärmen
              ingen indirekt klimatnytta från elproduktionen även om det är en
              kraftvärmeanläggning som levererar värmen.
            </Typography>
            <Typography paragraph>
              Exempel på andra indirekta klimatnyttor som kan förekomma är (1)
              minskade utsläpp från alternativ avfallsbehandling då avfall
              energiåtervinns, (2) samtidig produktion av ånga och fjärrvärme
              där ångan ersätter annan energitillförsel i en industri eller (3)
              kombinatanläggningar där man tack vare fjärrvärmeproduktionen även
              kan producera andra produkter som t.ex. etanol vilket leder till
              att annan energitillförsel för etanolproduktionen kan undvikas.
            </Typography>
            <Typography paragraph>
              För att man enligt konsekvensprincipen ska kunna kreditera
              fjärrvärmen för dessa indirekta nyttor så krävs det en tydlig
              koppling till att det är fjärrvärmekunderna som ser till att dessa
              nyttor finns. Om man ökar eller minskar fjärrvärmeproduktionen så
              ska också de indirekta nyttorna öka eller minska.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography className={`${classes.Question}`}>
              Hur blir utsläppen om jag köper grön el?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              När man som konsument väljer grön el (sk certifierad
              ursprungsmärkt el) istället för vanligt el så händer ingenting i
              kraftsystemet och det blir ingen förändring i klimatpåverkan!
              Detta trots att man aktivt har gjort detta val i tron att man som
              konsument kan vara med och påverka den totala klimatpåverkan från
              elproduktionen. Grön el är inte ”konsumentstyrd el” vilket är ett
              annat begrepp som man ibland pratar om.
            </Typography>
            <Typography paragraph>
              Vi har idag ett ”överskott” på grön el. Överskott i den bemärkelse
              att det finns betydligt mer grön el än vad som efterfrågas av de
              konsumenter som aktivt väljer grön el. Detta innebär att valet att
              köpa grön el kommer inte att påverka kraftproducenterna att
              producera mer grön el. Den förnyelsebara gröna elen som redan idag
              produceras kommer att konsumeras och göra god klimatnytta helt
              oberoende av om konsumenten väljer grön el eller inte. Den dagen
              som det inte finns mer grön el att köpa då kommer grön el att bli
              även konsumentstyrd el. Kraftproducenterna kommer då att behöva
              investera i ny kapacitet och valet av grön el kommer att bidra
              till att minska klimatpåverkan. Dock är vi långt ifrån denna
              situation idag. Det är möjligt att vi inte heller kommer att komma
              dit eftersom det framförallt är andra styrmedel/mekanismer som
              ligger bakom den kraftfulla omställningen av Sveriges och Europas
              kraftsystem.
            </Typography>
            <Typography paragraph>
              Om man exempelvis väljer en värmepump eller en elbil och förser
              den med grön el så kommer det valet i praktiken att innebära att
              elkonsumtionen totalt stiger och att annan elproduktion går in för
              att täcka upp för den ökade konsumtionen, en elproduktion som helt
              klart inte kan kallas för grön.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography className={`${classes.Question}`}>
              Hur blir utsläppen om jag har både solceller/vindkraft och
              värmepump?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              Om man köper en värmepump och samtidigt installerar solceller
              (eller köper vindkraftsandelar) så kan man för hela investeringen
              visa att man får en nästan klimatneutral uppvärmning av
              fastigheten. Kombinationen ger nästan inga klimatpåverkande
              utsläpp.
            </Typography>
            <Typography paragraph>
              Denna typ av kombinationslösningar är i dagsläget inte möjliga att
              räkna på i Fjärrkontrollen. Huvudorsaken till att detta inte är
              möjligt beror på att investeringen i solkraft/vindkraft är ett
              beslut som är oberoende av beslutet kring vilken uppvärmning som
              väljs. Att installera exempelvis solceller ger precis samma
              klimatnytta oberoende av vilket uppvärmningsalternativ som väljs.
              Vill man ändå studera alternativet med solceller+värmepump så ska
              man i så fall jämföra denna kombination med jämförbara
              kombinationer, t.ex. solceller+fjärrvärme eller
              solceller+pelletspanna.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
