import { WizardStep } from "../../common/types/wizard";
import {
  WizardStepState,
  WizardStepValue,
} from "../../common/store/wizard-slice";
import {
  Alternatives,
  ParameterConstraint,
} from "../../common/types/parameters";

// Id <> index conversion

export function getIndexFromId(id: string, wizardSteps: WizardStep[]): number {
  return wizardSteps.findIndex((step) => step.id === id);
}

// Step state

export function getStateForStep(
  step: WizardStep,
  wizardState: WizardStepState[]
): WizardStepState | undefined {
  return getStateForStepWithId(step.id, wizardState);
}

export function getStateForStepWithId(
  id: string,
  wizardState: WizardStepState[]
): WizardStepState | undefined {
  return wizardState.find((stepState) => stepState.id === id);
}

export function getStateForStepWithIndex(
  index: number,
  wizardSteps: WizardStep[],
  wizardState: WizardStepState[]
): WizardStepState | undefined {
  return getStateForStep(wizardSteps[index], wizardState);
}

// User inputs

export function getUserInputForStepIndex(
  index: number,
  wizardSteps: WizardStep[],
  wizardState: WizardStepState[]
): WizardStepValue | undefined {
  return getUserInputForStepId(wizardSteps[index]?.id, wizardState);
}

export function getUserInputForStepId(
  id: string,
  wizardState: WizardStepState[]
): WizardStepValue | undefined {
  return getStateForStepWithId(id, wizardState)?.value;
}

export function getRawUserInputForStepId(
  id: string,
  wizardState: WizardStepState[]
): string | undefined {
  return getUserInputForStepId(id, wizardState)?.id;
}

export function getUserInputForWizardStep(
  step: WizardStep,
  wizardState: WizardStepState[]
): WizardStepValue | undefined {
  return getUserInputForStepId(step.id, wizardState);
}

// Completion status

export function isStepWithIndexCompleted(
  index: number,
  wizardSteps: WizardStep[],
  wizardState: WizardStepState[]
): boolean {
  if (wizardSteps[index] !== undefined) {
    return isStepCompleted(wizardSteps[index] as WizardStep, wizardState);
  } else {
    return false;
  }
}

export function isStepWithIdCompleted(
  id: string,
  wizardState: WizardStepState[]
): boolean {
  return !!getStateForStepWithId(id, wizardState)?.completed;
}

export function isStepCompleted(
  step: WizardStep,
  wizardState: WizardStepState[]
): boolean {
  return isStepWithIdCompleted(step.id, wizardState);
}

export function allStepsAreCompleted(
  wizardState: WizardStepState[],
  wizardSteps: WizardStep[] | undefined
): boolean {
  return (
    wizardSteps !== undefined &&
    wizardSteps.length > 0 &&
    wizardSteps.length === wizardState.length &&
    wizardState.every((step) => step.completed)
  );
}

export function parseJsonToObject(input: string | undefined): any {
  let inputObject;
  if (input !== undefined) {
    inputObject = JSON.parse(input);
  } else {
    inputObject = undefined;
  }
  return inputObject;
}

export const customerDistrictsArePricelists = (
  customer: string | undefined,
  economicProfile: string | undefined
) => customer === "boras" && economicProfile === "privat";

export const replaceChar = (value: string) => {
  return value
    .replace(/å/g, "a")
    .replace(/Å/g, "A")
    .replace(/ä/g, "a")
    .replace(/Ä/g, "A")
    .replace(/ö/g, "o")
    .replace(/Ö/g, "O");
};

export const addExtraStep = (steps: WizardStep[], setValue: string = "") => {
  let newConstraints: ParameterConstraint[] = [];

  if (steps.find((step) => step.id === "PriceListSelected")) {
    return steps;
  }

  const indexOfDistrictSelected = steps.findIndex(
    (step) => step.id === "DistrictSelected"
  );
  const stepOfDistrictSelected = steps[indexOfDistrictSelected];

  if (stepOfDistrictSelected.inputParameter) {
    const districts = new Set(
      (stepOfDistrictSelected.inputParameter.constraints as Alternatives).map(
        (district) => {
          return district.displayName.split(" ")[0];
        }
      )
    );

    newConstraints = Array.from(districts).map((district) => {
      return {
        displayName: district,
        id: replaceChar(district).split("/")[0],
        value: replaceChar(district).split("/")[0],
        description: `Välj detta område för att räkna på uppvärmningskostnader som gäller för ${district} där Borås Energi och Miljös fjärrvärmepris`,
      };
    });
  }

  steps.splice(indexOfDistrictSelected, 0, {
    id: "PriceListSelected",
    shortDisplayName: "Område",
    displayName: "Var bor du",
    description: "",
    inputParameter: {
      ...steps[1].inputParameter,
      id: "PriceListSelected",
      displayName: "Var bor du",
      constraints: newConstraints,
      value: setValue,
    },
    dependencies: ["BuildingProfile"],
  });

  steps[indexOfDistrictSelected + 1] = {
    ...steps[2],
    shortDisplayName: "Prislista",
    displayName: "Välj prislista",
    inputParameter: {
      ...steps[indexOfDistrictSelected + 1].inputParameter,
      displayName: "Välj prislista",
    },
    dependencies: [
      ...steps[indexOfDistrictSelected + 1].dependencies,
      "PriceListSelected",
    ],
  };
  return steps;
};
