import { useState, createContext, ReactNode, FC } from "react";
import { AxiosError } from "axios";

export type APIErrorContextType = {
  error: string | undefined;
  addError: (error: string) => void;
  deleteError: () => void;
};

export const APIErrorContext = createContext<APIErrorContextType | null>(null);

interface APIErrorProviderProps {
  children?: ReactNode;
}

export const APIErrorContextProvider: FC<APIErrorProviderProps> = ({
  children,
}) => {
  const [error, setError] = useState<string | undefined>();
  const addError = (error: string) => {
    setError(error);
  };
  const deleteError = () => {
    setError(undefined);
  };

  const contextValue = {
    error,
    addError,
    deleteError,
  };
  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
};
