import {
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  SxProps,
  Theme,
  Tooltip,
  IconButton,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ParameterGroup, ParameterType } from '../../common/types/parameters';
import theme from '../theme/theme';
import {
  format,
  getSummaryPerParameterGroup,
  getType,
  prepareData,
} from './util';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  Row: {
    '&:last-child td, &:last-child th': { border: 0 },
  },
  Summary: {
    '& .MuiTableCell-body': {
      fontWeight: 'bold',
      borderTop: 5,
    },
  },
  Header: {
    '& .MuiTableCell-head': {
      textAlign: 'right',
      fontWeight: 'bold',
    },
  },
});

export default function Table(params: {
  parameterGroups: ParameterGroup[];
  summaryRow?: string;
  title?: string;
  description?: string;
  sx?: SxProps<Theme>;
  includeVat: boolean;
}) {
  const classes = useStyles(theme);

  return (
    <TableContainer
      component={Paper}
      elevation={3}
      key={`table-${params.title}-container`}
      sx={{ ...params.sx }}>
      <MuiTable
        aria-label={params.title}
        key={`table-${params.title}`}
        sx={{ tableLayout: 'fixed', width: '100%' }}>
        {params.description && <caption>{params.description}</caption>}
        <TableHead>
          <TableRow className={classes.Header}>
            <TableCell
              key={`table-${params.title}-header-column-0`}></TableCell>
            {params.parameterGroups.map((group, index) => (
              <TableCell
                key={`table-${params.title}-header-column-${index + 1}`}>
                {group.displayName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {prepareData(params.parameterGroups).data.map((row, rowIndex) => (
            <TableRow
              key={`table-${params.title}-row-${rowIndex}`}
              className={classes.Row}>
              <TableCell key={`table-${params.title}-row-${rowIndex}-column-0`}>
                {prepareData(params.parameterGroups).rowTitles[rowIndex]}
              </TableCell>
              {row.map((parameter, dataIndex) => {
                return (
                  <TableCell
                    align={
                      getType(parameter) === ParameterType.Numeric
                        ? 'right'
                        : 'left'
                    }
                    key={`table-${params.title}-row-${rowIndex}-column-${
                      dataIndex + 1
                    }`}
                    sx={{
                      whiteSpace: 'nowrap',
                    }}>
                    {format(
                      +parameter.value,
                      params.includeVat,
                      parameter.stylingConfig
                    )}
                    {parameter.description && (
                      <Tooltip
                        arrow
                        placement="bottom"
                        title={parameter.description}
                        sx={{
                          margin: 0,
                          paddingRight: 0,
                        }}>
                        <IconButton size="small" color="primary">
                          <HelpOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {params.summaryRow !== undefined && (
            <TableRow
              className={`${classes.Summary} ${classes.Row}`}
              key={`table-${params.title}-summary-row`}>
              <TableCell
                align="left"
                key={`table-${params.title}-summary-row-column-0`}>
                {params.summaryRow}
              </TableCell>
              {getSummaryPerParameterGroup(params.parameterGroups).map(
                (sum, index) => (
                  <TableCell
                    align="right"
                    key={`table-${params.title}-summary-row-column-${
                      index + 1
                    }`}>
                    {format(sum.value, params.includeVat, sum.style)}
                  </TableCell>
                )
              )}
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}
