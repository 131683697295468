import { SxProps } from "@mui/material";
import { ParameterGroup, Parameter } from "../../../common/types/parameters";
import Table from "../../../components/data-presentation/table";
import BarChart, {
  Orientation,
} from "../../../components/data-presentation/barchart";

const costIds = ["RunningCostsSum", "CapitalCost"];

export enum Representation {
  Table,
  Chart,
}

function convertToParameterGroups(
  parameters: Parameter[],
  heatingTechnologies: { id: string; title: string }[]
): ParameterGroup[] {
  let parameterGroups = heatingTechnologies.map(
    (heatingTechnology) =>
      ({
        id: heatingTechnology.id,
        displayName: heatingTechnology.title,
        description: "",
        parameters: costIds.map((id) =>
          parameters.find(
            (parameter) => parameter.id === `${heatingTechnology.id}${id}`
          )
        ),
      } as ParameterGroup)
  );

  return parameterGroups;
}

export default function CostPresentation(params: {
  heatingTechnologies: { id: string; title: string }[];
  parameters: Parameter[];
  sx?: SxProps;
  includeVat: boolean;
  type: Representation;
}) {
  if (params.type === Representation.Chart) {
    return (
      <BarChart
        toPrint={false}
        description="Kostnader per år"
        parameterGroups={convertToParameterGroups(
          params.parameters,
          params.heatingTechnologies
        )}
        unit="Kronor per år"
        orientation={Orientation.vertical}
        displayLegends
        includeVat={params.includeVat}
      />
    );
  } else if (params.type === Representation.Table) {
    return (
      <Table
        title="Översiktliga resultat"
        description="Kostnader kr per år"
        parameterGroups={convertToParameterGroups(
          params.parameters,
          params.heatingTechnologies
        )}
        summaryRow="Summa"
        includeVat={params.includeVat}
      />
    );
  } else {
    return <></>;
  }
}
