import React, { useState } from "react";
import {
  InputAdornment,
  SxProps,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import {
  NumericParameterStyle,
  NumericRange,
  Parameter,
} from "../../../common/types/parameters";
import { Column } from "../types";
import ParameterSection from "../parameter-section";
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowsProp,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from "@mui/x-data-grid";
import {
  format,
  scaleFromUnit,
  scaleToUnit,
  unFormat,
} from "../../../components/data-presentation/util";
import { InfoOutlined } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import theme from "../../../components/theme/theme";

type Field = "percAdjustment" | "adjustment" | undefined;

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  return <NumericFormat {...props} getInputRef={ref} />;
});

export default function DistrictHeatingAdjusted(params: {
  description?: string;
  printTitle: string;
  parameters: Parameter[];
  includeVat: boolean;
  toPrint: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
}) {
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});
  const [selectedField, setSelectedField] = useState<Field>();
  const [hasError, setHasError] = useState<string[]>([]);

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const getStylingConfig = (parameter: Parameter) =>
    parameter.stylingConfig as NumericParameterStyle;
  const getConstraintsNumeric = (parameter: Parameter) =>
    parameter.constraints as NumericRange;

  const getParameter = (parameterId: string): Parameter | undefined =>
    params.parameters.find(
      (x) => x.id.toLocaleLowerCase() === parameterId.toLocaleLowerCase()
    );

  const isInvalidInput = (
    value: number,
    constraints: NumericRange
  ): boolean => {
    return value > constraints?.max || value < constraints?.min;
  };

  const isModified = (
    newValue: number | string,
    oldValue: number | string
  ): boolean => {
    return newValue.toString() !== oldValue.toString();
  };

  const saveValue = (editCellparams: GridRenderEditCellParams) => {
    if (params?.onValidParameterChange && selectedField) {
      const parameterId = `${editCellparams.id}${editCellparams.field}`;
      const parameter = getParameter(parameterId);

      if (!parameter || hasError.length > 0) {
        return;
      }

      const newValue =
        editCellparams.field === "percAdjustment"
          ? `${scaleFromUnit(editCellparams.value, "%", params.includeVat)}`
          : `${editCellparams.value}`;

      if (!isModified(newValue, parameter.value)) {
        return;
      }

      // To nullifi the adjustment field when changes is made in the procent field
      if (editCellparams.field === "percAdjustment") {
        params.onValidParameterChange(`${editCellparams.id}Adjustment`, "");
      } else {
        params.onValidParameterChange(`${editCellparams.id}PercAdjustment`, "");
      }

      params.onValidParameterChange(parameter.id, newValue);
    }
  };

  const onChangeCell = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    editCellparams: GridRenderEditCellParams,
    parameterId: string,
    constraints: NumericRange,
    styling: NumericParameterStyle
  ) => {
    const newValue = unFormat(event.target.value);

    const scaledNewValue = scaleFromUnit(
      newValue,
      styling.unit,
      params.includeVat
    );

    if (
      isInvalidInput(scaledNewValue, constraints) &&
      !hasError.includes(parameterId)
    ) {
      editCellparams.error = true;
      setHasError((oldValue) => [...oldValue, parameterId]);
    } else if (
      !isInvalidInput(scaledNewValue, constraints) &&
      hasError.includes(parameterId)
    ) {
      editCellparams.error = false;
      setHasError((oldValue) => {
        return oldValue.filter((x) => !parameterId);
      });
    }

    if (!newValue) {
      editCellparams.api.setEditCellValue({
        field: editCellparams.field,
        id: editCellparams.id,
        value: 0,
      });
      return;
    } else if (newValue !== editCellparams.value) {
      editCellparams.api.setEditCellValue({
        field: editCellparams.field,
        id: editCellparams.id,
        value: newValue,
      });
    }
  };

  const renderCell = (
    renderCellParms: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => {
    const parameterId = `${renderCellParms.id}${renderCellParms.field}`;
    const parameter = getParameter(parameterId);

    if (!parameter) {
      return renderCellParms.value;
    }

    const getConstraints = getConstraintsNumeric(parameter);
    const getStyling = getStylingConfig(parameter);
    const numDecimals = getStyling.numDecimals || 0;

    const regex: RegExp =
      numDecimals === 0
        ? new RegExp(/(-?\d+)\.?/)
        : new RegExp(`(-?\\d+.?\\d{0,${numDecimals}})\\d*$`);

    let formatedValue = (
      (regex.exec(renderCellParms.value)?.[1] as string) ?? ""
    ).replaceAll(".", getStyling.decimalSeparator);

    if (getStyling.unit !== "%") {
      formatedValue = format(
        formatedValue,
        params.includeVat,
        getStyling.thousandsSeparator,
        false
      );
    }

    if (
      !(parameter.constraints as NumericRange)?.max &&
      !(parameter.constraints as NumericRange)?.min
    ) {
      return `${formatedValue} ${
        (parameter?.stylingConfig as NumericParameterStyle).unit
      }`;
    }

    const tooltipMsg = `Värdet måste vara mellan min ${scaleToUnit(
      getConstraints.min,
      getStyling.unit,
      params.includeVat
    )}${getStyling.unit} och max ${scaleToUnit(
      getConstraints.max,
      getStyling.unit,
      params.includeVat
    )}${getStyling.unit} `;

    return (
      <>
        {`${formatedValue}${getStyling.unit}`}
        <CustomWidthTooltip title={tooltipMsg} sx={{ with: "50px" }}>
          <InfoOutlined
            fontSize="small"
            sx={{ marginLeft: "10px", color: theme.palette.secondary.dark }}
          />
        </CustomWidthTooltip>
      </>
    );
  };

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setSelectedField(params.field as Field);

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    []
  );

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  const validateSubmitValue = (preEditparams: GridPreProcessEditCellProps) => {
    const parameterId = `${preEditparams.id}${selectedField}`;
    const parameter = getParameter(parameterId);

    const getConstraints = getConstraintsNumeric(parameter as Parameter);
    const getStyling = getStylingConfig(parameter as Parameter);

    const scaledNewValue = scaleFromUnit(
      preEditparams.props.value,
      getStyling.unit,
      params.includeVat
    );

    // Fix so the user can't leave de editMode with a invalid input
    return {
      ...preEditparams,
      error: isInvalidInput(scaledNewValue, getConstraints),
    };
  };

  const renderEditCell = (editCellparams: GridRenderEditCellParams) => {
    const parameterId = `${editCellparams.id}${editCellparams.field}`;
    const parameter = getParameter(parameterId);

    const getConstraints = getConstraintsNumeric(parameter as Parameter);
    const getStyling = getStylingConfig(parameter as Parameter);

    return (
      <TextField
        value={editCellparams.value}
        variant="outlined"
        defaultValue={0}
        type="string"
        InputProps={{
          inputProps: {
            min: getConstraints?.min,
            max: getConstraints?.max,
            thousandSeparator: getStyling.thousandsSeparator,
            thousandsGroupStyle: "thousand",
            decimalSeparator: getStyling?.decimalSeparator,
            decimalScale: getStyling?.numDecimals,
            allowNegative: true,
            allowedDecimalSeparators: [getStyling?.decimalSeparator],
          },
          inputComponent: NumberFormatCustom as any,
          endAdornment: (
            <InputAdornment position="end">{getStyling.unit}</InputAdornment>
          ),
        }}
        inputRef={(input) => {
          input?.focus();
        }}
        onChange={(event) => {
          onChangeCell(
            event,
            editCellparams,
            parameterId,
            getConstraints,
            getStyling
          );
        }}
        onBlur={() => {
          saveValue(editCellparams);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            saveValue(editCellparams);
          }
        }}
        error={hasError.includes(parameterId)}
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "14px",
          },

          "& .MuiOutlinedInput-root": {
            borderRadius: "0px",
          },
          "& .Mui-error.MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
          },
        }}
      />
    );
  };

  const rows: GridRowsProp = parameterColumns[0].sections
    .map((section) => {
      const parameters = section.parameters.map((id) =>
        params.parameters.find((x) => x.id === id)
      );

      if (parameters.includes(undefined)) {
        return {};
      }

      return {
        id: section.title,
        parameter: parameters[0]?.displayName,
        adjustedCost: format(
          parameters[0]?.value,
          params.includeVat,
          parameters[0]?.stylingConfig,
          false
        ),
        perAnnualDemandCost: format(
          parameters[1]?.value,
          params.includeVat,
          parameters[1]?.stylingConfig,
          true
        ),
        percAdjustment: scaleToUnit(
          parameters[2]?.value,
          (parameters[2]?.stylingConfig as NumericParameterStyle).unit,
          false
        ),
        adjustment: scaleToUnit(
          parameters[3]?.value,
          (parameters[3]?.stylingConfig as NumericParameterStyle).unit,
          params.includeVat
        ),
      };
    })
    .filter((row) => row.parameter);

  const dataColumns: GridColDef[] = [
    { field: "parameter", headerName: "", flex: 1 },
    {
      field: "adjustedCost",
      headerName: "Justerad kr/år",
      width: 200,
      type: "number",
    },
    {
      field: "perAnnualDemandCost",
      headerName: "Specifika kostnader",
      width: 210,
      type: "number",
      cellClassName: "cellFontColor",
    },
    {
      field: "percAdjustment",
      headerName: "Justering %",
      headerClassName: "editableCell",
      cellClassName: "editableCell",
      width: 200,
      type: "text",
      editable: true,
      renderCell: renderCell,
      preProcessEditCellProps: validateSubmitValue,
      renderEditCell: renderEditCell,
    },
    {
      field: "adjustment",
      headerName: "Justering kr",
      headerClassName: "editableCell",
      cellClassName: "editableCell",
      width: 200,
      type: "text",
      editable: true,
      renderCell: renderCell,
      preProcessEditCellProps: validateSubmitValue,
      renderEditCell: renderEditCell,
    },
  ];

  return (
    <>
      <ParameterSection
        printTitle={params.printTitle}
        description={params.description}
        parameters={params.parameters}
        columns={columns}
        includeVat={params.includeVat}
        toPrint={params.toPrint}
        onValidParameterChange={params.onValidParameterChange}
        sx={params.sx}
      >
        <DataGrid
          sx={{
            "& .cellFontColor": {
              color: theme.palette.secondary.dark,
            },
            "& .editableCell .MuiDataGrid-columnHeaderTitle:before": {
              content: "'*'",
            },

            "& :not(.Mui-selected, .MuiDataGrid-row:hover) > .editableCell.MuiDataGrid-cell":
              {
                backgroundColor: "#f7fcfe",
              },
          }}
          rows={rows}
          columns={dataColumns}
          onProcessRowUpdateError={(error) => {
            console.error(error);
          }}
          cellModesModel={cellModesModel}
          onCellClick={hasError.length > 0 ? undefined : handleCellClick}
          onCellModesModelChange={
            hasError.length > 0 ? undefined : handleCellModesModelChange
          }
          hideFooter={true}
        />
        <Typography variant="caption" sx={{ fontSize: "0.8rem" }}>
          * Fält som går att justera.
        </Typography>
      </ParameterSection>
    </>
  );
}

const parameterColumns: Column[] = [
  {
    sections: [
      {
        title: "DistrictHeatingCapitalCost",
        parameters: [
          "DistrictHeatingAdjustedCapitalCost",
          "DistrictHeatingAdjustedCapitalCostPerAnnualDemand",
          "DistrictHeatingCapitalCostPercAdjustment",
          "DistrictHeatingCapitalCostAdjustment",
        ],
      },
      {
        title: "DistrictHeatingMaintenanceCost",
        parameters: [
          "DistrictHeatingAdjustedMaintenanceCost",
          "DistrictHeatingAdjustedMaintenanceCostPerAnnualDemand",
          "DistrictHeatingMaintenanceCostPercAdjustment",
          "DistrictHeatingMaintenanceCostAdjustment",
        ],
      },
      {
        title: "DistrictHeatingEnergyCost",
        parameters: [
          "DistrictHeatingAdjustedEnergyCost",
          "DistrictHeatingAdjustedEnergyCostPerAnnualDemand",
          "DistrictHeatingEnergyCostPercAdjustment",
          "DistrictHeatingEnergyCostAdjustment",
        ],
      },
      {
        title: "DistrictHeatingLoadCost",
        parameters: [
          "DistrictHeatingAdjustedLoadCost",
          "DistrictHeatingAdjustedLoadCostPerAnnualDemand",
          "DistrictHeatingLoadCostPercAdjustment",
          "DistrictHeatingLoadCostAdjustment",
        ],
      },

      {
        title: "DistrictHeatingFlowCost",
        parameters: [
          "DistrictHeatingAdjustedFlowCost",
          "DistrictHeatingAdjustedFlowCostPerAnnualDemand",
          "DistrictHeatingFlowCostPercAdjustment",
          "DistrictHeatingFlowCostAdjustment",
        ],
      },
    ],
    readOnly: true,
  },
];

const columns: Column[] = [
  {
    sections: [
      {
        title: "",
        parameters: ["VATSelection"],
      },
    ],
    readOnly: false,
  },
];
