export const redirectURI =
  process.env.REACT_APP_API === 'local'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_API === 'staging'
    ? 'https://fjarrkontrollen2-test.azurewebsites.net'
    : 'https://www.fjarrkontrollen.se'; /* Prod and default */

export const baseApiUrl =
  process.env.REACT_APP_API === 'local'
    ? 'https://localhost:7143/api'
    : process.env.REACT_APP_API === 'staging'
    ? 'https://fjarrkontrollen-test.azurewebsites.net/api/'
    : 'https://fjarrkontrollen2-be-prod.azurewebsites.net/api/'; /* Prod and default */
