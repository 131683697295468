import { useState } from "react";
import {
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React from "react";
import { SelectionAlternative } from "../../common/types/parameters";
import NumericInputField from "./numeric-field";

export default function Dropdown(params: {
  inputId: string;
  defaultValue?: SelectionAlternative | string;
  alternatives: SelectionAlternative[];
  title: string;
  description?: string;
  disabled?: boolean;
  onValidChange?: (id: string, value: string) => void;
  numDecimals?: number;
  thousandsSeparator?: string;
  decimalSeparator?: string;
  unit?: string;
  includeVat: boolean;
  hideAlternativeValues?: boolean;
}) {
  function getAsAlternative(
    value: SelectionAlternative | string | undefined
  ): SelectionAlternative | undefined {
    if (value === undefined || value === null) {
      return undefined;
    } else if (value.constructor === Object) {
      return value as SelectionAlternative;
    } else if (typeof value === "string") {
      return JSON.parse(value as string);
    } else {
      return undefined;
    }
  }

  function getAlternativeWithId(id: string): SelectionAlternative | undefined {
    return params.alternatives.find((alternative) => alternative.id === id);
  }

  const [displayTooltip, setTooltipDisplayed] = useState(false);

  let selectedAlternative = getAsAlternative(params.defaultValue);

  function displayValueOfSelectedAlternative(): boolean {
    return (
      selectedAlternative !== undefined &&
      selectedAlternative !== null &&
      !params.hideAlternativeValues &&
      (selectedAlternative.editable ||
        (selectedAlternative.value !== undefined &&
          !isNaN(+selectedAlternative.value)))
    );
  }

  function notifyChange(alternative: SelectionAlternative | undefined) {
    if (params.onValidChange !== undefined) {
      params.onValidChange(params.inputId, JSON.stringify(alternative));
    }
  }

  return (
    <div key={`dropdown-${params.inputId}-container`}>
      <Box
        key={`dropdown-${params.inputId}-inner-container`}
        sx={{
          width: displayValueOfSelectedAlternative() ? "100%" : "300px",
          maxWidth: "calc(100% - 60px)",
          display: "inline-flex",
          verticalAlign: "top",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={displayValueOfSelectedAlternative() ? 8 : 12}>
            <FormControl
              variant="standard"
              sx={{
                width: "300px",
                maxWidth: "100%",
              }}
            >
              <InputLabel id={`dropdown-${params.inputId}-label`}>
                {params.title}
              </InputLabel>
              <Select
                labelId={`dropdown-${params.inputId}-selector`}
                id={params.inputId}
                value={selectedAlternative?.id}
                label={params.title}
                onChange={(event: SelectChangeEvent) => {
                  let alternative = getAlternativeWithId(event.target.value);
                  if (selectedAlternative !== alternative?.id) {
                    notifyChange(alternative);
                  }
                }}
                onOpen={() => setTooltipDisplayed(true)}
                onClose={() => setTooltipDisplayed(false)}
                disabled={params.disabled}
              >
                {params.alternatives.map((alternative) => (
                  <MenuItem value={alternative.id}>
                    {alternative.description && displayTooltip ? (
                      <Tooltip
                        arrow
                        placement="right"
                        title={alternative.description}
                      >
                        <Typography style={{ width: "100%" }}>
                          {alternative.displayName}
                        </Typography>
                      </Tooltip>
                    ) : (
                      alternative.displayName
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {displayValueOfSelectedAlternative() && (
            <Grid item xs={4}>
              <NumericInputField
                inputId={selectedAlternative!.id}
                defaultValue={selectedAlternative!.value}
                title={params.unit ?? (!!params.title ? " " : "")}
                disabled={!selectedAlternative!.editable}
                onValidChange={(id, value) => {
                  if (selectedAlternative !== undefined) {
                    selectedAlternative.value = value;
                    notifyChange(selectedAlternative);
                  }
                }}
                numDecimals={params.numDecimals}
                thousandsSeparator={params.thousandsSeparator}
                decimalSeparator={params.decimalSeparator}
                unit={params.unit}
                includeVat={params.includeVat}
                sx={{ maxWidth: "100%", display: "inline-flex" }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {params.description && (
        <Tooltip arrow placement="bottom" title={params.description}>
          <IconButton size="small" color="primary" sx={{ margin: "16px 10px" }}>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

function getTitle(displayName: string, unit: string | undefined): string {
  if (unit !== undefined && unit !== null) {
    return `${displayName} (${unit})`;
  } else {
    return displayName;
  }
}
