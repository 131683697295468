import {
  Card,
  CardContent,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { makeStyles } from "@mui/styles";
import theme from "../../components/theme/theme";

const useStyles = makeStyles(() => ({
  selectedCard: {
    background:
      "linear-gradient(0deg, rgba(22, 102, 192, 0.2), rgba(22, 102, 192, 0.2)), #FFFFFF",
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px",
    "& img": {
      opacity: "0.7",
    },
  },
  imagelessTitle: {
    textAlign: "center",
    paddingTop: 40,
  },
  title: {
    paddingTop: 10,
  },
}));

export default function SelectionCard({
  value,
  title,
  description,
  image,
  selected,
  onClick,
}: any) {
  const classes = useStyles(theme);

  return (
    <Card
      elevation={3}
      variant="elevation"
      sx={{
        width: 250,
        maxWidth: 250,
        maxHeight: 400,
        boxShadow: "0px 6px 12px -2px rgba(0,0,0,0.15);",
        margin: "14px",
      }}
      className={selected ? classes.selectedCard : ""}
    >
      <CardActionArea onClick={() => onClick(value)}>
        {image && (
          <CardMedia
            style={{ height: "200px" }}
            component="img"
            image={image}
            loading="lazy"
          />
        )}
        <CardContent>
          <Typography
            variant="h5"
            className={image ? classes.title : classes.imagelessTitle}
            key={`selection-card-${value}-title`}
          >
            {title}
          </Typography>
        </CardContent>
        <CardActions>
          {description && (
            <Tooltip
              arrow
              title={
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              }
            >
              <IconButton size="small" color="primary">
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
