import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ModuleInfo } from '../types/modules';
import { getModules } from '../../api/module-api';
import { Profile } from './module-parameters-slice'

interface ModulesState {
  modules: ModuleInfo[];
}

const modulesSlice = createSlice({
  name: 'modules',
  initialState: { modules: [] as ModuleInfo[] },
  reducers: {
    setModules: (state: ModulesState, action: PayloadAction<ModuleInfo[]>) => {
      state.modules = action.payload;
    },
  },
});

export const fetchModules = createAsyncThunk<
  ModuleInfo[],
  Profile,
  { state: RootState }
>('modules/fetchModules', async ({customer, economicProfile}, thunkAPI) => {
  const response = await getModules(customer, economicProfile);
  thunkAPI.dispatch(setModules(response));
  return response;
});

export const { setModules } = modulesSlice.actions;

//Selector

export const getModulesStates = (state: RootState): ModuleInfo[] => {
  const modules = state.modules.modules;
  return modules;
};

export const getIsAcceptingAnonymousUsers = (state: RootState): boolean => {
  return !state.modules.modules.every(
    module => module.accessibleToUser === false
  );
};

export const getHasAccessToAllModules = (state: RootState): boolean => {
  return state.modules.modules.every(
    module => module.accessibleToUser === true
  );
};

export const getHasAccessToModules = (state: RootState): boolean => {
  return state.modules.modules.some(module => module.accessibleToUser === true);
};

export default modulesSlice.reducer;
