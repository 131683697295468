import { httpClient } from "./http-client";
import { Parameter, ParameterValue } from "../common/types/parameters";
import { ModuleInfo } from "../common/types/modules";
import { customerDistrictsArePricelists } from "../components/wizard/util";

export const getModules = async (
  customer: string | undefined,
  economicProfile: string | undefined
): Promise<ModuleInfo[]> => {
  const response = await httpClient.get(
    `customers/${customer}/modules/economic-profile/${economicProfile}`
  );
  return response.data;
};

export const calculateOutput = async (
  module: string,
  input: ParameterValue[],
  customer: string | undefined,
  economicProfile: string | undefined
): Promise<Parameter[]> => {
  if (customerDistrictsArePricelists(customer, economicProfile)) {
    input = input.filter((step) => step.id !== "PriceListSelected");
  }

  const response = await httpClient.post(
    `customers/${customer}/modules/${module}/economic-profile/${economicProfile}/results`,
    input
  );
  return response.data;
};
