import { useState } from "react";
import { TextField, Tooltip, IconButton, SxProps, Box } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { makeStyles } from "@mui/styles";
import { NumericFormat } from "react-number-format";
import React from "react";
import { NumericParameterStyle } from "../../common/types/parameters";
import { format, scaleFromUnit, scaleToUnit } from "../data-presentation/util";

const useStyles = makeStyles({
  NumericField: {
    "& input": {
      textAlign: "right",
    },
  },
});

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatCustom = React.forwardRef<any, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }
);

export default function NumericInputField(params: {
  inputId: string;
  min?: number;
  max?: number;
  defaultValue?: number | string;
  title: string;
  description?: string;
  disabled?: boolean;
  onValidChange?: (id: string, value: string) => void;
  onErroneousChange?: (
    id: string,
    value: string,
    nearestValidVal: string
  ) => void;
  numDecimals?: number;
  thousandsSeparator?: string;
  decimalSeparator?: string;
  unit?: string;
  sx?: SxProps;
  includeVat: boolean;
}) {
  function formatValue(value: number | string | undefined): string | undefined {
    return value !== undefined && value !== null && !isNaN(+value)
      ? format(Number(value), params.includeVat, {
          decimalSeparator: params.decimalSeparator,
          numDecimals: params.numDecimals,
          thousandsSeparator: params.thousandsSeparator,
          unit: params.unit,
        } as NumericParameterStyle)
      : undefined;
  }

  const [error, setError] = useState("");
  const [oldValue, setOldValue] = useState(formatValue(params.defaultValue));

  const classes = useStyles();

  return (
    <Box
      key={`numeric-field-${params.inputId}-container`}
      sx={{ ...params.sx }}
      className="numeric-input-field">
      <TextField
        id={params.inputId}
        label={params.title}
        type="text"
        InputProps={{
          inputProps: {
            min: params.min,
            max: params.max,
            thousandSeparator: params.thousandsSeparator,
            decimalSeparator: params.decimalSeparator,
            decimalScale: params.numDecimals,
          },
          inputComponent: NumberFormatCustom as any,
        }}
        sx={{
          width: "300px",
          maxWidth: `calc(100% ${params.description ? "- 60px" : ""})`,
        }}
        value={
          params.defaultValue !== undefined && params.defaultValue !== null
            ? scaleToUnit(+params.defaultValue, params.unit, params.includeVat)
            : undefined
        }
        disabled={params.disabled}
        onChange={
          !params.disabled
            ? (e: any) => {
                if (!e.target.value) {
                  setError(" ");
                  return;
                }
                const value = scaleFromUnit(
                  +e.target.value,
                  params.unit,
                  params.includeVat
                );
                let formattedValue = formatValue(value);
                if (
                  params.max !== undefined &&
                  value > params.max &&
                  oldValue !== formattedValue
                ) {
                  setError(`Värdet måste vara ≤ ${formatValue(params.max)}`);
                  if (params.onErroneousChange !== undefined) {
                    params.onErroneousChange(
                      params.inputId,
                      value.toString(),
                      params.max.toString()
                    );
                  }
                } else if (
                  params.min !== undefined &&
                  value < params.min &&
                  oldValue !== formattedValue
                ) {
                  setError(`Värdet måste vara ≥ ${formatValue(params.min)}`);
                  if (params.onErroneousChange !== undefined) {
                    params.onErroneousChange(
                      params.inputId,
                      value.toString(),
                      params.min.toString()
                    );
                  }
                } else {
                  setError("");
                  if (
                    params.onValidChange !== undefined &&
                    oldValue !== formattedValue
                  ) {
                    params.onValidChange(params.inputId, value.toString());
                  }
                }
                setOldValue(formattedValue);
              }
            : undefined
        }
        error={Boolean(error)}
        helperText={error}
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
        className={classes.NumericField}
      />
      {params.description && (
        <Tooltip arrow placement="bottom" title={params.description}>
          <IconButton size="small" color="primary" sx={{ margin: "16px 10px" }}>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
