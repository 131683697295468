import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import Logo from '../../assets/img/FK_logo_small.png';
import useCollapse from 'react-collapsed';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { getLabelByCustomerId } from '../../api/label-api';
import LoginBtn from '../../components/authentication/LoginBtn';
import { useIsAuthenticated } from '@azure/msal-react';
import {
  fetchModules,
  getIsAcceptingAnonymousUsers,
  getHasAccessToModules,
} from '../../common/store/modules-slice';
import { useAppDispatch, useAppSelector } from '../../common/store/store';

export default function Intro() {
  let { customer } = useParams();
  let { economicProfile } = useParams();
  const [welcomeText, setWelcomeText] = useState<string[]>();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const isAcceptingAnonymousUsers = useAppSelector(state =>
    getIsAcceptingAnonymousUsers(state)
  );
  const hasAccessToModules = useAppSelector(state =>
    getHasAccessToModules(state)
  );

  useMemo(() => {
    getLabelByCustomerId('welcomeText', customer, economicProfile).then(response => {
      setWelcomeText(
        response?.description
          ?.split('<p>')
          .flatMap(p => p?.split('</p>'))
          .filter(paragraph => !!paragraph)
      );
    });
  }, [customer]);

  useEffect(() => {
    dispatch(fetchModules({customer, economicProfile}));
  }, [customer, dispatch, isAuthenticated]);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const showLoginBtn = (): boolean => {
    return !isAcceptingAnonymousUsers && !isAuthenticated;
  };

  const showBeginBtn = (): boolean => {
    return hasAccessToModules;
  };

  return (
    <>
      <Typography variant="h1" key="wizard-intro-page-title">
        Välkommen till Fjärrkontrollen
      </Typography>
      <Grid container direction="row-reverse" spacing={3}>
        <Grid item xs={12} md={4}>
          <Box key="intro-logo-box" component="img" width="100%" src={Logo} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            paragraph
            key={`wizard-intro-page-content-0`}
            variant="body1">
            {welcomeText?.at(0)}
            {welcomeText?.length !== undefined && welcomeText?.length > 1 && (
              <Button variant="text" {...getToggleProps()}>
                {isExpanded ? 'Dölj information' : 'Läs mer'}
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </Button>
            )}
          </Typography>
          <Box {...getCollapseProps()}>
            {welcomeText
              ?.filter((content, index) => index !== 0)
              .map((content, index) => (
                <Typography
                  paragraph
                  key={`wizard-intro-page-content-${index}`}
                  variant="body1">
                  {content}
                </Typography>
              ))}
          </Box>
          {showBeginBtn() && (
            <Button
              component={Link}
              to={`/${customer}/${economicProfile}#0`}
              color="button"
              variant="contained">
              Börja
            </Button>
          )}
          {showLoginBtn() && !showBeginBtn() && <LoginBtn isListItem={false} />}
        </Grid>
      </Grid>
    </>
  );
}
