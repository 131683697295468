import React, { useEffect } from "react";
import { Button, ListItemButton, ListItemText } from "@mui/material";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useParams } from "react-router-dom";
import {
  fetchModules,
  getHasAccessToAllModules,
  getModulesStates,
} from "../../common/store/modules-slice";
import { useAppDispatch, useAppSelector } from "../../common/store/store";
import { redirectURI } from "../../base";

export default function LoginBtn({ isListItem }: { isListItem: boolean }) {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  let { customer, economicProfile } = useParams();
  const dispatch = useAppDispatch();
  const modules = useAppSelector((state) => getModulesStates(state));
  const hasAccessToAllModules = useAppSelector((state) =>
    getHasAccessToAllModules(state)
  );

  useEffect(() => {
    if (modules.length === 0 || isAuthenticated) {
      dispatch(fetchModules({ customer, economicProfile }));
    }
  }, [customer, dispatch, modules.length, isAuthenticated, economicProfile]);

  const handleLogin = (loginType = "redirect") => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error(e);
      });
    } else if (loginType === "redirect") {
      instance
        .loginRedirect({
          ...loginRequest,
          redirectUri: `${redirectURI}/${customer}/${economicProfile}`,
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const handleLogout = (logoutType = "redirect") => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: `/${customer}/${economicProfile}`,
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: `/${customer}/${economicProfile}`,
      });
    }
  };

  const showBtn = () => {
    if (hasAccessToAllModules && !isAuthenticated) {
      return "hidden";
    }

    return "visible";
  };

  return (
    <>
      {isListItem && (
        <ListItemButton
          sx={{ visibility: showBtn() }}
          component="a"
          target="new"
          onClick={() => (isAuthenticated ? handleLogout() : handleLogin())}
        >
          <ListItemText
            sx={{ my: 0 }}
            primary={isAuthenticated ? "Logga ut" : "Logga in"}
            primaryTypographyProps={{
              fontSize: 16,
              fontWeight: "medium",
              letterSpacing: 0,
            }}
          />
        </ListItemButton>
      )}

      {!isListItem && (
        <Button
          sx={{
            visibility: showBtn(),
          }}
          color="button"
          variant="contained"
          onClick={() => (isAuthenticated ? handleLogout() : handleLogin())}
        >
          {isAuthenticated ? "Logga ut" : "Logga in"}
        </Button>
      )}
    </>
  );
}
