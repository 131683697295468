import { SxProps } from "@mui/material";
import { Parameter } from "../../../common/types/parameters";
import { Column } from "../types";
import ParameterSection from "../parameter-section";

export default function Common(params: {
  description?: string;
  printTitle: string;
  parameters: Parameter[];
  includeVat: boolean;
  toPrint: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
}) {
  return (
    <ParameterSection
      printTitle={params.printTitle}
      description={params.description}
      parameters={params.parameters}
      columns={columns}
      toPrint={params.toPrint}
      includeVat={params.includeVat}
      onValidParameterChange={params.onValidParameterChange}
      sx={params.sx}
    />
  );
}

const columns: Column[] = [
  { sections: [{ title: "Behov", parameters: ["AnnualHeatingDemand"] }] },
  {
    sections: [
      {
        title: "Ekonomiska förutsättningar",
        parameters: [
          "VATSelection",
          "InterestRate",
          "ElectricityPriceVariable",
        ],
      },
    ],
  },
];
