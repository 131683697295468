import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1666C0",
    },
    secondary: {
      main: "#EDECEC",
      dark: "#00000099",
    },
    button: {
      main: "#1666C0",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: '"RobotoDraft", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 38,
      fontWeight: 300,
      lineHeight: 1.5,
    },
    h2: {
      fontSize: 30,
      fontWeight: 300,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.5,
      marginTop: 20,
      marginBottom: 15,
    },
    caption: {
      color: "#666666",
    },
  },
  mixins: {
    footer: { minHeight: 70 },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.875rem",
        },
      },
    },
  },
});

/**
 * The interfaces below are used to style the buttons
 * on this website
 */
declare module "@mui/material/styles" {
  interface Palette {
    button: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    button?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    button: true;
  }
}
export default theme;
