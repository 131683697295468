import { Box, CircularProgress, SxProps } from '@mui/material';
import { ParameterGroup, Parameter } from '../../../common/types/parameters';
import BarChart, {
  Orientation,
} from '../../../components/data-presentation/barchart';

const costTypes: { id: string[]; name?: string }[] = [
  { id: ['IncreasedGridCost'] },
  { id: ['FlowCost'] },
  { id: ['LoadCost'] },
  { id: ['EnergyCost'] },
  { id: ['MaintenanceCost', 'HeatPumpMaintenanceCost'] },
  { id: ['CapitalCost'] },
];

function getExpectedParameterId(
  costTypeId: string,
  heatingTechnologyId: string
): string {
  return `${heatingTechnologyId}${costTypeId}`;
}

function getParametersOfCostType(
  costTypeIds: string[],
  parameters: Parameter[],
  heatingTechnology: { id: string; title: string }
): Parameter[] {
  return costTypeIds
    .map(id => getExpectedParameterId(id, heatingTechnology.id))
    .map(paramId => parameters.find(parameter => parameter.id === paramId))
    .filter(parameter => parameter !== undefined) as Parameter[];
}

function convertToParameterGroups(
  parameters: Parameter[],
  heatingTechnologies: { id: string; title: string }[]
): ParameterGroup[] {
  costTypes.forEach(costType => {
    costType.name = heatingTechnologies
      .flatMap(heatingTechnology =>
        getParametersOfCostType(costType.id, parameters, heatingTechnology)
      )
      .find(param => param.displayName)?.displayName;
  });
  let parameterGroups = heatingTechnologies.map(
    heatingTechnology =>
      ({
        id: heatingTechnology.id,
        displayName: heatingTechnology.title,
        description: '',
        parameters: costTypes.map(
          costType =>
            getParametersOfCostType(
              costType.id,
              parameters,
              heatingTechnology
            ).at(0) ??
            ({
              id: getExpectedParameterId(costType.id[0], heatingTechnology.id),
              value: '0',
              displayName: costType.name,
            } as Parameter)
        ),
      } as ParameterGroup)
  );

  return parameterGroups;
}

export default function DetailsChart(params: {
  heatingTechnologies: { id: string; title: string }[];
  parameters: Parameter[];
  sx?: SxProps;
  displayLegends?: boolean;
  includeVat: boolean;
  toPrint: boolean;
}) {
  return params.parameters.length > 0 ? (
    <BarChart
      description="Kostnader per år"
      parameterGroups={convertToParameterGroups(
        params.parameters,
        params.heatingTechnologies
      )}
      unit="Kronor per år"
      orientation={Orientation.horizontal}
      displayLegends={params.displayLegends}
      includeVat={params.includeVat}
      toPrint={params.toPrint}
    />
  ) : (
    <Box sx={{ textAlign: 'center', paddingTop: '50px' }}>
      <CircularProgress />
    </Box>
  );
}
