import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { WizardStep as WizardStepType } from "../../common/types/wizard";
import WizardSelectionAlternativesBlock from "./wizard-parameter";

const useStyles = makeStyles(() => ({
  container: {
    margin: "0 4em",
  },
}));

export default function WizardStep(params: {
  info: WizardStepType;
  preSelected?: string;
  onSelection?: (value: string) => void;
}) {
  const classes = useStyles();

  return params.info ? (
    <div className={classes.container}>
      <Typography
        style={{
          fontWeight: 300,
          textAlign: "center",
          fontSize: "30px",
        }}
      >
        {params.info.displayName}
      </Typography>
      <Typography paragraph>{params.info.description}</Typography>
      <WizardSelectionAlternativesBlock
        info={params.info}
        preSelected={params.preSelected}
        onSelection={(val: string) => {
          if (params.onSelection !== undefined) {
            params.onSelection(val);
          }
        }}
      ></WizardSelectionAlternativesBlock>
    </div>
  ) : (
    <div></div>
  );
}
