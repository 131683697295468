import {
  ParameterGroup,
  ParameterType,
  Parameter,
  ParameterStyle,
  NumericParameterStyle,
  SelectionAlternative,
} from "../../common/types/parameters";

const currentcyUnitFactors: { [key: string]: number } = {
  kr: 1,
  "kr/år": 1,
  "kr/ton": 1,
  "öre/kWh": 100,
  "öre/kW": 100,
  "kr/kWh": 1,
  "kr/MWh": 1000,
  "kr/kW": 1,
  "öre/m³": 100,
  "kr/m³": 1,
};
const unitConversionFactorsFromStandardUnit: { [key: string]: number } = {
  // 1 means that the unit is the standard unit, 10 that the value needs to be multiplied with 10 to go from standard unit->target unit
  "%": 100,
  "°C": 1,
  A: 1,
  kW: 1,
  MW: 1 / 1000,
  kWh: 1,
  MWh: 1 / 1000,
  "kWh/år": 1,
  "MWh/år": 1 / 1000,
  "m³/år": 1,
  år: 1,
  ...currentcyUnitFactors,
};

function getConversionFactor(
  unit: string | undefined,
  includeVat: boolean
): number {
  if (unit === undefined || unit === null) {
    return 1; // unitless
  }
  let conversionFactor = unitConversionFactorsFromStandardUnit[unit];
  if (conversionFactor !== undefined) {
    if (includeVat && currentcyUnitFactors[unit] !== undefined) {
      conversionFactor *= 1.25;
    }
    return conversionFactor;
  } else {
    console.warn(
      `The supplied unit ${unit} is not supported. Defaulting its conversion factor to 1.`
    );
    return 1;
  }
}

export function scaleToUnit(
  value: number,
  targetUnit: string | undefined,
  includeVat: boolean
): number {
  return value * getConversionFactor(targetUnit, includeVat);
}

export function scaleFromUnit(
  value: number,
  sourceUnit: string | undefined,
  includeVat: boolean
): number {
  return value / getConversionFactor(sourceUnit, includeVat);
}

export function getType(parameter: Parameter): ParameterType {
  if (
    parameter.constraints !== undefined &&
    parameter.constraints !== null &&
    Array.isArray(parameter.constraints)
  ) {
    var constraintArray = parameter.constraints as Array<any>;
    if (
      constraintArray.every((alternative) => alternative.constructor === Object)
    ) {
      return ParameterType.Selection;
    } else if (
      constraintArray.every((alternative) => typeof alternative === "boolean")
    ) {
      return ParameterType.YesNo;
    }
  }

  if (
    (parameter.constraints !== undefined &&
      parameter.constraints !== null &&
      parameter.constraints.constructor === Object) ||
    (parameter.value !== undefined &&
      parameter.value !== null &&
      !isNaN(+parameter.value))
  ) {
    return ParameterType.Numeric;
  }

  console.warn(
    `Could not decide the type of parameter ${parameter.id}, with value ${parameter.value}. Defaulting to interpreting it as a numeric parameter.`
  );
  return ParameterType.Numeric;
}

export interface DataTable {
  columnTitles: string[];
  rowTitles: string[];
  data: Parameter[][];
}

export function prepareData(parameterGroups: ParameterGroup[]): DataTable {
  let res = {
    columnTitles: parameterGroups.map((group) => group.displayName),
    rowTitles: new Array<string>(),
    data: new Array<Array<Parameter>>(),
  };

  parameterGroups.forEach((group, c) => {
    group.parameters.forEach((parameter) => {
      if (!res.rowTitles.includes(parameter.displayName)) {
        res.rowTitles.push(parameter.displayName);
      }
      var r = res.rowTitles.indexOf(parameter.displayName);
      if (res.data[r] === undefined) {
        res.data[r] = new Array<Parameter>();
      }
      res.data[r][c] = parameter;
    });
  });

  return res;
}

export function getDisplayValue(
  value: number | string,
  includeVat: boolean,
  parameterType: ParameterType,
  styling?: ParameterStyle,
  includeUnit: boolean = false
): string | undefined {
  if (value === undefined || value === null) {
    return undefined;
  } else if (parameterType === ParameterType.Selection) {
    return parseAsSelectionAlternative(value as string)?.displayName;
  } else if (parameterType === ParameterType.YesNo) {
    return value === JSON.stringify(true) ? "Ja" : "Nej";
  } else if (parameterType === ParameterType.Numeric) {
    return format(
      Number(value),
      includeVat,
      styling as NumericParameterStyle,
      includeUnit
    );
  } else if (typeof value === "string") {
    return value;
  } else {
    return JSON.stringify(value);
  }
}

export function getParameterDisplayValue(
  parameter: Parameter,
  includeVat: boolean,
  includeUnit: boolean = false
): string | undefined {
  return getDisplayValue(
    parameter.value,
    includeVat,
    getType(parameter),
    parameter.stylingConfig,
    includeUnit
  );
}

export function unFormat(value: string): number {
  return parseFloat(value.replace(",", ".").replace(/\s/g, ""));
}

export function format(
  value: number | string,
  includeVat: boolean,
  styling?: ParameterStyle,
  includeUnit: boolean = false
): string {
  if (styling !== undefined && styling !== null) {
    if (typeof value === "number" || !isNaN(+value)) {
      let numericParameterStyle = styling as NumericParameterStyle;

      let numericValue = typeof value === "number" ? value : Number(value);

      // Handle unit conversion
      numericValue = scaleToUnit(
        numericValue,
        numericParameterStyle.unit,
        includeVat
      );

      // Handle step size
      if (
        numericParameterStyle.stepSize !== undefined &&
        numericParameterStyle.stepSize !== null &&
        numericParameterStyle.stepSize !== 1 &&
        numericParameterStyle.stepSize !== 0
      ) {
        numericValue =
          Math.round(numericValue / numericParameterStyle.stepSize) *
          numericParameterStyle.stepSize;
      }

      // Handle separators
      let decimalSeparator = numericParameterStyle.decimalSeparator ?? ",";
      let thousandsSeparator = numericParameterStyle.thousandsSeparator ?? " ";
      let formattedValue = numericValue
        .toLocaleString("sv-SE")
        .replace(",", decimalSeparator)
        .replaceAll(" ", thousandsSeparator);

      // Handle number of decimals (note that rounding was already done when step size was handled)
      if (
        numericParameterStyle.numDecimals !== undefined &&
        numericParameterStyle.numDecimals !== null &&
        numericParameterStyle.numDecimals >= 0
      ) {
        let valueParts = formattedValue.split(decimalSeparator);
        if (valueParts.length < 2) {
          valueParts[1] = "";
        }
        while (valueParts[1].length < numericParameterStyle.numDecimals) {
          valueParts[1] += "0";
        }
        valueParts[1] = valueParts[1].substring(
          0,
          numericParameterStyle.numDecimals
        );
        formattedValue =
          numericParameterStyle.numDecimals > 0
            ? valueParts[0] + decimalSeparator + valueParts[1]
            : valueParts[0];
      }

      if (includeUnit && numericParameterStyle.unit) {
        formattedValue = formattedValue + " " + numericParameterStyle.unit;
      }
      return formattedValue;
    } else {
      return value;
    }
  } else {
    return value.toString();
  }
}

export function addSummaryPerParameterGroup(
  parameterGroups: ParameterGroup[],
  sumTitle: string
): ParameterGroup[] {
  return parameterGroups.map((group) => {
    if (
      group.parameters.every(
        (p) => getType(p) === ParameterType.Numeric && p.value !== undefined
      )
    ) {
      let sum = 0;
      group.parameters.forEach((parameter) => {
        sum += +(parameter.value ?? 0);
      });
      group.parameters = group.parameters.concat([
        {
          value: sum.toString(),
          id: "sum",
          displayName: sumTitle,
          description: sumTitle,
          stylingConfig: getMostDetailedStyling(group.parameters),
          editable: false,
          constraints: {},
        } as Parameter,
      ]);
    }
    return group;
  });
}

export interface StyledValue {
  value: number | string;
  style: ParameterStyle;
}

export function getSummaryPerParameterGroup(
  parameterGroups: ParameterGroup[]
): StyledValue[] {
  const defaultStyling = setDefaultStyling();

  let sums = Array<StyledValue>();
  parameterGroups.forEach((group, index) => {
    if (
      group.parameters.every(
        (p) => getType(p) === ParameterType.Numeric && p.value !== undefined
      )
    ) {
      const shouldRoundValues = group.parameters.every(
        (parameter) =>
          Number(parameter.value) === 0 ||
          scaleToUnit(
            Number(parameter.value),
            (parameter.stylingConfig as NumericParameterStyle).unit,
            false // Hardcoded as of now
          ) > defaultStyling.stepSize
      );
      sums[index] = {
        value: 0,
        style: shouldRoundValues
          ? setDefaultStyling(getMostDetailedStyling(group.parameters))
          : getMostDetailedStyling(group.parameters),
      };
      group.parameters.forEach((parameter) => {
        sums[index].value =
          Number(sums[index].value) + Number(parameter.value ?? 0);
        if (shouldRoundValues) {
          parameter.stylingConfig = setDefaultStyling(
            parameter.stylingConfig as NumericParameterStyle
          );
        }
      });
    }
  });
  return sums;
}

// TODO: Configure styling accordingly in BE, while we now hardcode to showing 100s
function setDefaultStyling(
  styling?: NumericParameterStyle
): NumericParameterStyle {
  if (styling === undefined || styling === null) {
    styling = {} as NumericParameterStyle;
  }
  styling.stepSize = 100;
  return styling;
}

export function getMostDetailedStyling(
  parameters: Parameter[]
): NumericParameterStyle {
  let getNumericStyle = (
    parameter: Parameter | undefined
  ): NumericParameterStyle | undefined => {
    return (parameter?.stylingConfig as NumericParameterStyle) ?? undefined;
  };

  return {
    stepSize: Math.min(
      ...parameters.map((p) => getNumericStyle(p)?.stepSize ?? 1)
    ),
    numDecimals: Math.max(
      ...parameters.map((p) => getNumericStyle(p)?.numDecimals ?? 0)
    ),
    thousandsSeparator:
      getNumericStyle(
        parameters.find(
          (p) => getNumericStyle(p)?.thousandsSeparator !== undefined
        )
      )?.thousandsSeparator ?? " ", // Use any defined style, all should have the same
    decimalSeparator:
      getNumericStyle(
        parameters.find(
          (p) => getNumericStyle(p)?.decimalSeparator !== undefined
        )
      )?.decimalSeparator ?? ",", // Use any defined style, all should have the same
    unit:
      getNumericStyle(
        parameters.find((p) => getNumericStyle(p)?.unit !== undefined)
      )?.unit ?? undefined, // Use any defined unit, all should have the same
  } as NumericParameterStyle;
}

export function getUnscaledValue(
  value: any | undefined,
  parameterType: ParameterType
): string | undefined {
  if (value === undefined || value === null) {
    return undefined;
  }
  if (parameterType === ParameterType.Numeric) {
    return value;
  }
  if (parameterType === ParameterType.Selection) {
    return parseAsSelectionAlternative(value)?.value;
  }
  return value;
}

export function getUnscaledParameterValue(
  parameter: Parameter
): string | undefined {
  return getUnscaledValue(parameter.value, getType(parameter));
}

function parseAsSelectionAlternative(
  stringifiedAlternative: string
): SelectionAlternative | undefined {
  return JSON.parse(stringifiedAlternative) as SelectionAlternative;
}
