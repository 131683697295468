import { httpClient } from "./http-client";
import { WizardStep } from "./../common/types/wizard";
import { ParameterValue } from "../common/types/parameters";
import {
  addExtraStep,
  customerDistrictsArePricelists,
} from "../components/wizard/util";

export const getWizardSteps = async (
  customer: string | undefined,
  economicProfile: string | undefined
): Promise<WizardStep[]> => {
  let response = await httpClient.get(
    `customers/${customer}/economic-profiles/${economicProfile}/Wizard/steps`
  );

  if (customerDistrictsArePricelists(customer, economicProfile)) {
    response.data = addExtraStep(response?.data);
  }

  return response?.data;
};

export const getWizardStepsByInput = async (
  customer: string | undefined,
  economicProfile: string | undefined,
  input: ParameterValue[],
  selectedPriceListDistrict: string = ""
): Promise<WizardStep[]> => {
  const response = await httpClient.post(
    `customers/${customer}/economic-profiles/${economicProfile}/Wizard/steps`,
    input
  );

  if (customerDistrictsArePricelists(customer, economicProfile)) {
    response.data = addExtraStep(response?.data, selectedPriceListDistrict);
  }

  return response?.data;
};
