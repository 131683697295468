// Parameter types

export interface Parameter {
  id: string;
  displayName: string;
  description: string;
  stylingConfig: ParameterStyle;
  constraints: ParameterConstraint;
  editable: boolean;
  value: any;
}

export enum ParameterType {
  Selection,
  Numeric,
  YesNo,
}

// Input types

export interface SelectionAlternative {
  id: string;
  displayName: string;
  description: string;
  editable: boolean;
  value: string;
}

// Return types

export interface ParameterValue {
  id: string;
  value: string;
}

// Collections

export interface ParameterGroup {
  id: string;
  displayName: string;
  description: string;
  parameters: Parameter[];
}

// Display styling

export interface ParameterStyle {}

export interface NumericParameterStyle {
  stepSize: number;
  numDecimals: number;
  thousandsSeparator: string;
  decimalSeparator: string;
  unit: string;
}

// Constraints
export interface ParameterConstraint {}

export interface NumericRange extends ParameterConstraint {
  min: number;
  max: number;
}

export interface Alternatives
  extends ParameterConstraint,
    Array<SelectionAlternative> {}

export interface BooleanRange extends ParameterConstraint, Array<boolean> {}
