import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Logo from '../../assets/img/FK_logo_small.png';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      padding: '15px',
    },
  })
);

export default function ErrorPage(params: { error: string }) {
  const classes = useStyles();

  return (
    <Grid container alignItems={'baseline'}>
      <Grid item xs={2} />
      <Grid item xs={5}>
        <Typography variant="h1" key="error-page-title">
          {params.error}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box key="welcome-logo-box" component="img" width="50%" src={Logo} />
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}
