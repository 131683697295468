import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { WizardStep } from "../../common/types/wizard";
import { useEffect, useState } from "react";
import { getWizardSteps } from "../../api/wizard-api";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { ListItemIcon, styled, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../common/store/store";
import {
  WizardStepState,
  getCurrentStep,
  getStepStates,
} from "../../common/store/wizard-slice";
import Logo from "../../assets/img/FK_logo_small.png";
import theme from "../theme/theme";
import LocationIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  isStepCompleted,
  getUserInputForWizardStep,
  getIndexFromId,
  getRawUserInputForStepId,
  parseJsonToObject,
} from "../wizard/util";
import Footer from "../footer/footer";
import { Parameter } from "../../common/types/parameters";
import { getLabel, getLabelByCustomerId } from "../../api/label-api";
import { ModuleInfo } from "../../common/types/modules";
import LoginBtn from "../authentication/LoginBtn";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  fetchModules,
  getIsAcceptingAnonymousUsers,
  getModulesStates,
} from "../../common/store/modules-slice";

const drawerWidth = 280;

const Offset = styled("div")(({ theme }) => theme.mixins.footer);

export default function Sidebar({ customer, economicProfile }: any) {
  const [wizardSteps, setWizardSteps] = useState<WizardStep[]>([]);
  const [modules, setModules] = useState<ModuleInfo[]>([]);
  const [customerName, setCustomerName] = useState<string>();
  const [priceInfoLabel, setPrinceInfoLabel] = useState<Parameter>();

  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const dispatch = useAppDispatch();
  const availableModules = useAppSelector((state) => getModulesStates(state));
  const isAcceptingAnonymousUsers = useAppSelector((state) =>
    getIsAcceptingAnonymousUsers(state)
  );

  useEffect(() => {
    getWizardSteps(customer, economicProfile).then((response) => {
      setWizardSteps(response);
    });

    if (availableModules.length === 0) {
      dispatch(fetchModules({ customer, economicProfile }));
    }

    setModules([
      {
        id: "oversikt",
        displayName: "Översiktliga resultat",
        accessibleToUser: isAcceptingAnonymousUsers,
      },
      ...availableModules,
    ]);

    getLabelByCustomerId("CustomerName", customer, economicProfile).then(
      (response) => {
        setCustomerName(response?.description);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableModules, customer, dispatch, economicProfile, isAuthenticated]);

  const stepStates = useAppSelector((state) =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );

  const currentStep = useAppSelector((state) =>
    getCurrentStep(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );

  const getActiveStep = (): number => {
    return !isAcceptingAnonymousUsers
      ? -1
      : currentStep
      ? getIndexFromId(currentStep, wizardSteps)
      : 0;
  };

  useEffect(() => {
    if (stepStates !== undefined) {
      let district = parseJsonToObject(
        getRawUserInputForStepId("DistrictSelected", stepStates)
      )?.value;
      let building = parseJsonToObject(
        getRawUserInputForStepId("BuildingProfile", stepStates)
      )?.value;
      if (district && building) {
        getLabel(
          "PriceDateInfo",
          customer,
          district,
          building,
          economicProfile
        ).then((response) => {
          setPrinceInfoLabel(response);
        });
      }
    }
  }, [stepStates, customer]);

  return (
    <Drawer
      key="sidebar-drawer"
      anchor="left"
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          overflowX: "hidden",
        },
      }}
    >
      <List>
        <ListItem
          key="sidebar-logo-container"
          sx={{
            marginTop: "10px",
            marginBottom: 0,
            padding: 3,
          }}
        >
          <ListItemIcon>
            <Box
              key="sidebar-logo-box"
              component="img"
              height="30px"
              src={Logo}
              sx={{ marginRight: 2 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
              >
                Fjärrkontrollen
              </Typography>
            }
            secondary={
              <>
                <Box
                  key="sidebar-customer-info-container"
                  sx={{
                    display: "flex",
                    gap: "0.2em",
                  }}
                >
                  <LocationIcon fontSize="small" />
                  {customerName}
                </Box>
                {isAuthenticated && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.2em",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleIcon fontSize="small" />
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{ fontSize: "14px" }}
                    >
                      {accounts[0]?.name}
                    </Typography>
                  </Box>
                )}
              </>
            }
          />
        </ListItem>
        {priceInfoLabel && (
          <>
            <Divider variant="middle" key="sidebar-logo-divider" />
            <ListItem
              key="sidebar-priceinfo-container"
              sx={{ marginTop: 0, marginBottom: 0 }}
            >
              <ListItemText
                secondary={
                  <Typography variant="caption">
                    <b>{priceInfoLabel.displayName}</b>{" "}
                    <>{priceInfoLabel.value}</>
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}
        <Divider variant="middle" key="sidebar-logo-divider" />
        <ListItem
          button
          component="a"
          href={`/${customer}/${economicProfile}`}
          key="sidebar-wizard-intro-button"
          disabled={!isAcceptingAnonymousUsers}
        >
          <ListItemText primary={"Inledande förutsättningar"} />
        </ListItem>
        <Stepper
          key="sidebar-wizard-stepper"
          nonLinear
          activeStep={getActiveStep()}
          orientation="vertical"
          sx={{ pl: 4, marginBottom: "10px" }}
        >
          {wizardSteps.map((step, index) => (
            <Step
              key={`sidebar-wizard-step-${step.id}`}
              completed={isStepCompleted(step, stepStates)}
            >
              <StepButton
                key={`sidebar-wizard-step-${step.id}-button`}
                component="a"
                href={`/${customer}/${economicProfile}#${index}`}
                color="inherit"
                disabled={!isAcceptingAnonymousUsers}
                optional={
                  <Typography variant="caption">
                    {getUserInputForWizardStep(step, stepStates)?.display}
                  </Typography>
                }
              >
                {step.shortDisplayName}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Divider variant="middle" key="sidebar-wizard-divider" />
        {modules.map((module) => (
          <>
            {module.accessibleToUser && (
              <div key={`sidebar-module-${module.id}-container`}>
                <ListItem
                  button
                  component="a"
                  href={`/${customer}/${economicProfile}/${module.id}`}
                  disabled={
                    !wizardIsCompleted(wizardSteps, stepStates) ||
                    !module.accessibleToUser
                  }
                  key={`sidebar-module-${module.id}`}
                >
                  <ListItemText primary={module.displayName} />
                </ListItem>
                {module.id === "klimat" && (
                  <List component="div" disablePadding>
                    <ListItem
                      button
                      component="a"
                      href={`/${customer}/${economicProfile}/${module.id}/fjarrvarmesystemet`}
                      disabled={!wizardIsCompleted(wizardSteps, stepStates)}
                      key={`sidebar-module-${module.id}-fjarrvarmesystemet`}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary="Fjärrvärmesystemet" />
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`/${customer}/${economicProfile}/${module.id}/elsystemet`}
                      disabled={!wizardIsCompleted(wizardSteps, stepStates)}
                      key={`sidebar-module-${module.id}-elsystemet`}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary="Elsystemet" />
                    </ListItem>
                    <ListItem
                      button
                      component="a"
                      href={`/${customer}/${economicProfile}/${module.id}/information`}
                      disabled={!wizardIsCompleted(wizardSteps, stepStates)}
                      key={`sidebar-module-${module.id}-information`}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary="Om beräkningen" />
                    </ListItem>
                  </List>
                )}
                <Divider
                  variant="middle"
                  key={`sidebar-module-${module.id}-divider`}
                />
              </div>
            )}
          </>
        ))}
        <ListItem
          button
          component="a"
          href={`/${customer}/${economicProfile}/manual`}
          key={`sidebar-module-manual`}
        >
          <ListItemText primary={"Hjälp & instruktioner"} />
        </ListItem>
        <Divider variant="middle" key={`sidebar-module-manual-divider`} />
        <LoginBtn isListItem />
      </List>
      <Offset key="offset" sx={{ margin: "10px 0" }} />
      <Footer sx={{ width: drawerWidth }} />
    </Drawer>
  );

  function wizardIsCompleted(
    steps: WizardStep[],
    states: WizardStepState[]
  ): boolean {
    return (
      wizardSteps.length > 0 &&
      wizardSteps.length === stepStates.length &&
      stepStates.every((s) => s.completed)
    );
  }
}
