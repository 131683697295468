import { useCallback, useMemo, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch, useAppSelector } from "../../common/store/store";
import {
  addStep,
  getCurrentStep,
  getStepStates,
  setCurrentStep,
  setUserInput,
  WizardStepState,
} from "../../common/store/wizard-slice";
import IntroPage from "../intro/intro";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WizardStep as WizardStepType } from "../../common/types/wizard";
import { getWizardStepsByInput } from "../../api/wizard-api";
import WizardStep from "../../components/wizard/wizard-step";
import {
  Alternatives,
  Parameter,
  ParameterType,
  ParameterValue,
} from "../../common/types/parameters";
import {
  getStateForStepWithId,
  getUserInputForWizardStep,
  customerDistrictsArePricelists,
  isStepCompleted,
} from "../../components/wizard/util";
import OversiktligaResultat from "../modules/oversikt/oversikt";
import {
  getDisplayValue,
  getType,
} from "../../components/data-presentation/util";
import { debounce } from "@mui/material";

function dependenciesAreResolved(
  step: WizardStepType,
  stepStates: WizardStepState[]
): boolean {
  let completedSteps = stepStates
    .filter((stepState) => stepState.completed)
    .map((stepState) => stepState.id);
  return (
    step.inputParameter !== undefined &&
    step.inputParameter !== null &&
    step.dependencies.every((dependency) => completedSteps.includes(dependency))
  );
}

function calculateInitalStep(
  sidebarSelection: string | undefined,
  wizardSteps: WizardStepType[],
  stepStates: WizardStepState[]
): number {
  let initialStep = 0;
  if (sidebarSelection) {
    initialStep = parseInt(sidebarSelection);
  } else if (
    stepStates.some((step) => !step?.completed) ||
    stepStates.length !== wizardSteps.length
  ) {
    initialStep = wizardSteps.findIndex(
      (step) => !isStepCompleted(step, stepStates)
    );
  }
  return initialStep;
}

function ControlledAccordions() {
  const { customer, economicProfile } = useParams();

  let { hash, pathname, search } = useLocation();
  const step = useMemo(() => hash.slice(1), [hash]);
  const navigate = useNavigate();

  const [wizardSteps, setWizardSteps] = useState<WizardStepType[]>([]);
  const [priceListDistrict, setPriceListDistrict] = useState<string>("");

  let stepStates = useAppSelector((state) =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );
  let currentStep = useAppSelector((state) =>
    getCurrentStep(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );

  const dispatch = useAppDispatch();

  const updateCurrentStep = useCallback(
    (stepNr: number) => {
      navigate(`${pathname}${search}#${stepNr}`, { replace: true });
    },
    [navigate, pathname, search]
  );

  const addStepsToStore = useCallback(
    (steps: WizardStepType[]) => {
      steps.forEach((step) => {
        let defaultValue = step.inputParameter?.value;
        if (getType(step.inputParameter) === ParameterType.Selection) {
          defaultValue = undefined; // We do not wan't to preselect a selection alternative even if there is a default value in the configuration, so that the user has free choice and "Beräkningarna bygger på manuellt justerade värden" will not appear if the user selects something else than the configured default value
        }
        if (defaultValue !== undefined && defaultValue !== null) {
          defaultValue =
            typeof defaultValue === "string"
              ? defaultValue
              : JSON.stringify(defaultValue);
        }
        dispatch(
          addStep({
            id: step.id,
            defaultValue: defaultValue,
            defaultDisplayValue: getWizardStepDisplayValue(
              defaultValue,
              step.inputParameter
            ),
            profile: { customer: customer, economicProfile: economicProfile },
          })
        );
      });
    },
    [customer, economicProfile, dispatch]
  );

  const getWizardStepsDelayed = useCallback(
    debounce(
      (
        inputs: ParameterValue[],
        callback: (steps: WizardStepType[]) => void
      ) => {
        getWizardStepsByInput(
          customer,
          economicProfile,
          inputs,
          priceListDistrict
        ).then((steps) => {
          callback(steps);
        });
      },
      600
    ),
    [customer, economicProfile]
  );

  // Initial load and update of page content and state
  useMemo(() => {
    let inputs = stepStates
      .filter((step) => step.value?.id !== undefined && step.value?.id !== null)
      .map(
        (step) => ({ id: step.id, value: step.value?.id } as ParameterValue)
      );
    getWizardStepsDelayed(inputs, (wizardSteps) => {
      setWizardSteps(wizardSteps);
      addStepsToStore(wizardSteps);
    });
  }, [getWizardStepsDelayed, stepStates, addStepsToStore]);

  useMemo(() => {
    // Decide which step to open (menu selection or the first uncompleted step)
    let initialStep = calculateInitalStep(step, wizardSteps, stepStates);
    // updateCurrentStep(initialStep);
    if (wizardSteps[initialStep]?.id !== currentStep) {
      dispatch(
        setCurrentStep({
          step: wizardSteps[initialStep]?.id,
          profile: {
            customer: customer,
            economicProfile: economicProfile,
          },
        })
      );
    }
  }, [
    step,
    wizardSteps,
    stepStates,
    currentStep,
    dispatch,
    customer,
    economicProfile,
  ]);

  const [parameterReloadNeeded, setParameterReloadNeeded] = useState(false);

  function saveInput(index: number, stringifiedValue: string) {
    if (
      customerDistrictsArePricelists(customer, economicProfile) &&
      wizardSteps[index].id === "PriceListSelected"
    ) {
      setPriceListDistrict(stringifiedValue);
    }

    let parameter = wizardSteps[index].inputParameter;

    let valueToDisplay = getWizardStepDisplayValue(stringifiedValue, parameter);

    setParameterReloadNeeded(
      (index === 0 || index === 1) && stepStates.every((step) => step.completed)
    ); // profile change, may affect limits of other parameters

    dispatch(
      setUserInput({
        id: wizardSteps[index].id,
        value: stringifiedValue,
        displayValue: valueToDisplay,
        profile: { customer: customer, economicProfile: economicProfile },
      })
    );

    updateCurrentStep(index + 1);
  }

  function getInput(step: WizardStepType): string | undefined {
    let userInput = getUserInputForWizardStep(step, stepStates)?.id;

    if (userInput !== null && userInput !== undefined) {
      return userInput;
    } else if (
      getType(step.inputParameter) === ParameterType.Selection &&
      (step.inputParameter.constraints as Alternatives)?.length === 1
    ) {
      return (step.inputParameter.constraints as Alternatives)[0].value;
    } else if (getType(step.inputParameter) === ParameterType.Numeric) {
      return getStateForStepWithId(step.id, stepStates)?.defaultValue?.id;
    } else {
      return undefined;
    }
  }

  return !step && !stepStates.some((step) => step?.completed) ? (
    <IntroPage />
  ) : (
    <div key="wizard-container">
      <Typography variant="h1">Inledande förutsättningar</Typography>
      {wizardSteps.map((step, index) => (
        <Accordion
          key={`wizard-step-${step.id}-accordion`}
          expanded={currentStep === step.id}
          onChange={(event, expanded) => {
            if (expanded) {
              updateCurrentStep(index);
            } else {
              updateCurrentStep(index + 1);
            }
          }}
          elevation={0}
        >
          <AccordionSummary
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.03)",
              margin: "0px 0 0 0",
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography
              sx={{ width: "33%", flexShrink: 0 }}
              key={`wizard-step-${step.id}-title`}
            >
              {step.shortDisplayName}
            </Typography>
            <>{getUserInputForWizardStep(step, stepStates)?.display}</>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "rgba(0, 0, 0, 0.0)" }}>
            {dependenciesAreResolved(step, stepStates) ? (
              <WizardStep
                info={step}
                preSelected={getInput(step)}
                onSelection={(val: string) => {
                  saveInput(index, val);
                }}
              />
            ) : (
              "Besvara tidigare steg innan detta steg kan besvaras"
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      {stepStates.length === wizardSteps.length &&
        stepStates.every((step) => step.completed) && (
          <OversiktligaResultat
            forceParameterReload={parameterReloadNeeded}
            onReload={() => {
              setParameterReloadNeeded(false);
            }}
            sx={{ marginTop: "40px" }}
          />
        )}
    </div>
  );
}

function getWizardStepDisplayValue(
  value: string,
  parameter: Parameter
): string | undefined {
  return getDisplayValue(
    value,
    false /* VAT is not relevant for Wh*/,
    getType(parameter),
    parameter.stylingConfig,
    true
  );
}

export { ControlledAccordions };
