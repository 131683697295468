import { Box, SxProps, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import ProfuIcon from "../../assets/img/Profu_logo_grey.png";
import theme from "../theme/theme";

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    position: "fixed",
    left: 0,
    bottom: 0,
    textAlign: "center",
    background: "#ffffff",
    padding: "10px 0",
    minHeight: 70,
  },
  icon: {
    height: "35px",
    verticalAlign: "middle",
    opacity: "0.5",
    margin: "0 20px",
  },
}));

export default function Footer(params: { sx?: SxProps }) {
  const classes = useStyles(theme);

  return (
    <Box key={"footer"} className={classes.footerWrapper} sx={params.sx}>
      <Box>
        <img alt="Profu" src={ProfuIcon} className={classes.icon} />
      </Box>
      <Typography variant="caption">
        © {new Date().getFullYear().toString()}{" "}
        <Link href="http://www.profu.se" target="_blank">
          Profu
        </Link>
      </Typography>
    </Box>
  );
}
