import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { WizardStep } from "../../common/types/wizard";
import SelectionCard from "../../components/parameters/selection-card";
import NumericInputField from "../../components/parameters/numeric-field";
import { Button } from "@mui/material";
import {
  Alternatives,
  BooleanRange,
  NumericParameterStyle,
  NumericRange,
  ParameterType,
} from "../../common/types/parameters";
import { getType } from "../data-presentation/util";
import { useAppSelector } from "../../common/store/store";
import { getStepStates } from "../../common/store/wizard-slice";
import { useParams } from "react-router-dom";
import { customerDistrictsArePricelists } from "./util";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
  },
}));

export default function WizardParameterBlock(params: {
  info: WizardStep;
  preSelected?: string;
  onSelection?: (value: string) => void;
}) {
  const classes = useStyles();

  const [userInput, setUserInput] = useState<string | undefined>();
  const { customer, economicProfile } = useParams();

  const stepStates = useAppSelector((state) =>
    getStepStates(state, {
      customer: customer,
      economicProfile: economicProfile,
    })
  );

  useEffect(() => {
    setUserInput(params.preSelected);
  }, [params.preSelected]);

  let parameter = params.info?.inputParameter;

  if (
    params.info.id === "DistrictSelected" &&
    customerDistrictsArePricelists(customer, economicProfile)
  ) {
    const getValue = JSON.parse(
      stepStates.find((x) => x.id === "PriceListSelected")?.value?.id as string
    ).value;

    parameter.constraints = (parameter.constraints as Alternatives).filter(
      (constraint) => {
        return constraint.id.includes(getValue);
      }
    );
  }

  function getSelectionImage(
    imgName: string,
    index: number
  ): NodeRequire | undefined {
    if (getType(parameter) === ParameterType.Selection) {
      if (
        params.info.id === "DistrictSelected" ||
        params.info.id === "PriceListSelected"
      ) {
        imgName = index === 0 ? "CityCentre" : `Suburb_${(index - 1) % 5}`;
      }
      try {
        return require(`../../assets/img/parameters/${imgName}.jpg`);
      } catch {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  return parameter ? (
    <div
      className={classes.container}
      key={`wizard-parameter-${params.info.id}-block`}
    >
      {(getType(parameter) === ParameterType.Selection &&
        (parameter.constraints as Alternatives).map((alternative, index) => (
          <SelectionCard
            value={alternative.id}
            title={alternative.displayName}
            description={alternative.description}
            image={getSelectionImage(alternative.id, index)}
            selected={userInput === JSON.stringify(alternative)}
            onClick={() => {
              setUserInput(JSON.stringify(alternative));
              if (params.onSelection) {
                params.onSelection(JSON.stringify(alternative));
              }
            }}
          />
        ))) ||
        (getType(parameter) === ParameterType.YesNo &&
          (parameter.constraints as BooleanRange).map((alternative, index) => (
            <SelectionCard
              value={alternative}
              title={alternative ? "Ja" : "Nej"}
              description={undefined}
              image={undefined}
              selected={userInput === JSON.stringify(alternative)}
              onClick={() => {
                setUserInput(JSON.stringify(alternative));
                if (params.onSelection) {
                  params.onSelection(JSON.stringify(alternative));
                }
              }}
            />
          ))) ||
        (getType(parameter) === ParameterType.Numeric && (
          <div
            className={classes.container}
            key={`wizard-parameter-${params.info.id}-container`}
          >
            <NumericInputField
              inputId={parameter.id}
              min={(parameter.constraints as NumericRange)?.min}
              max={(parameter.constraints as NumericRange)?.max}
              defaultValue={userInput}
              title={`
                ${parameter.displayName}
                ${
                  (parameter.stylingConfig as NumericParameterStyle)?.unit
                    ? `(${
                        (parameter.stylingConfig as NumericParameterStyle).unit
                      })`
                    : ""
                }`}
              description={parameter.description}
              onValidChange={(id: string, val: string) => {
                setUserInput(val);
              }}
              onErroneousChange={(
                id: string,
                val: string,
                nearestValidVal: string
              ) => {
                setUserInput(undefined);
              }}
              numDecimals={
                (parameter.stylingConfig as NumericParameterStyle)?.numDecimals
              }
              thousandsSeparator={
                (parameter.stylingConfig as NumericParameterStyle)
                  ?.thousandsSeparator
              }
              decimalSeparator={
                (parameter.stylingConfig as NumericParameterStyle)
                  ?.decimalSeparator
              }
              unit={(parameter.stylingConfig as NumericParameterStyle)?.unit}
              includeVat={false}
            />
            <Button
              disabled={userInput === undefined}
              size="small"
              variant="contained"
              onClick={() => {
                if (params.onSelection && userInput !== undefined) {
                  params.onSelection(userInput);
                }
              }}
            >
              Nästa
            </Button>
          </div>
        ))}
    </div>
  ) : null;
}
