import { useContext } from "react";
import {
  APIErrorContext,
  APIErrorContextType,
} from "../providers/APIErrorProvider";

function useAPIError() {
  const { error, addError, deleteError } = useContext(
    APIErrorContext
  ) as APIErrorContextType;
  return { error, addError, deleteError };
}

export default useAPIError;
