import { SxProps } from "@mui/material";
import { Parameter } from "../../../common/types/parameters";
import { Column } from "../types";
import ParameterSection from "../parameter-section";

export default function BuildingElectricity(params: {
  description?: string;
  printTitle: string;
  parameters: Parameter[];
  toPrint: boolean;
  includeVat: boolean;
  onValidParameterChange?: (id: string, value: string) => void;
  sx?: SxProps;
}) {
  return (
    <ParameterSection
      printTitle={params.printTitle}
      description={params.description}
      parameters={params.parameters}
      toPrint={params.toPrint}
      columns={columns}
      includeVat={params.includeVat}
      onValidParameterChange={params.onValidParameterChange}
      sx={params.sx}
    />
  );
}

const columns: Column[] = [
  {
    sections: [
      {
        title: "Årliga kostnader",
        parameters: [
          "BuildingGridCostFixed",
          "BuildingGridCostPower",
          "BuildingGridCostVariable",
        ],
      },
    ],
    readOnly: true,
  },
  {
    sections: [
      {
        title: "",
        parameters: [
          "GridFusePriceFixed",
          "GridFusePriceVariableLow",
          "GridFusePriceVariableHigh",
        ],
      },
      {
        title: "",
        parameters: [
          "GridPowerPriceFixed",
          "GridPowerPriceLoadLow",
          "GridPowerPriceLoadHigh",
          "GridPowerPriceVariableHigh",
          "GridPowerPriceVariableLow",
        ],
      },
      {
        title: "",
        parameters: [
          "BuildingAnnualElectricityDemand",
          "BuildingElectricityLoad",
          "BuildingFuseDemand",
        ],
      },
    ],
  },
];
